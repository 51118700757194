<template>
  <div class="mb-4">
    <div class="container bg-image py-3" style="max-width: 1300px !important">
      <v-card rounded="3" style="overflow: unset">
        <v-card
          rounded="0"
          style="padding: 10px 16px; overflow: revert; min-height: auto"
          v-if="modifySection"
        >
          <div class="row" align="center">
            <h6 class="mt-2" style="color: #faa41f">
              FIND CHEAP TICKETS-EARN MORE COMMISSION
            </h6>
          </div>
          <div
            class="hisOptions"
            @click="travelHistory = !travelHistory"
            v-if="this.RecentSearchData.length > 0"
          >
            <v-icon color="white" size="24">{{
              !travelHistory ? "mdi-history" : "mdi-undo"
            }}</v-icon>
          </div>

          <div v-if="!travelHistory">
            <div class="row mt-1">
              <div class="col-lg-4 col-md-6">
                <v-radio-group
                  inline
                  color="FFB80A"
                  v-model="bookingRadio"
                  style="position: relative; top: 0px"
                >
                  <v-radio
                    label="Round Trip"
                    value="roundone"
                    class="normal-radio me-1"
                    :class="{ radio: bookingRadio == 'roundone' }"
                  ></v-radio>
                  <v-radio
                    label="Oneway"
                    value="onewaytwo"
                    class="normal-radio me-1"
                    :class="{ radio: bookingRadio == 'onewaytwo' }"
                  ></v-radio>
                  <v-radio
                    label="Multi-city"
                    value="multithree"
                    class="normal-radio"
                    :class="{ radio: bookingRadio == 'multithree' }"
                  ></v-radio>
                </v-radio-group>
              </div>

              <div class="col-lg-5 col-md-6 mt-3 mt-sm-0">
                <div class="row justify-content-sm-start justify-center">
                  <div class="col-lg-10 col-12">
                    <div class="d-flex input-styles">
                      <span class="d-flex me-2 f-size-18" style="color: gray">
                        <v-icon size="20"> mdi-account-outline </v-icon>
                      </span>

                      <v-select
                        v-model="selectedCountry"
                        :items="countries"
                        ref="countrySelect"
                        variant="underlined"
                        class="select-1 selectType"
                      >
                        <!-- <template #item="{ item }"> -->
                        <template #item="{}">
                          <div class="dropdown_section" style="cursor: pointer">
                            <div
                              class="row d-flex align-center my-2 mx-1"
                              style="height: 37px"
                              @click.stop
                            >
                              <div class="col-3" style="width: 38%">
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >Adult</span
                                >
                              </div>
                              <div class="col-3 px-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-human-male-female
                                </v-icon>
                              </div>
                              <div
                                class="col-6 adult"
                                style="width: 50%; height: auto"
                              >
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg1()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls }}</div>
                                  </div>
                                  <div class="col-4" @click="add1()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div>{{ item.name }}</div> -->
                            </div>

                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div
                                class="col-4 d-flex flex-column pe-0"
                                style="width: 38%"
                              >
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >Children</span
                                >
                                <span style="font-size: 11px; color: #4e4d4d"
                                  >2-11(yrs)</span
                                >
                              </div>
                              <div class="col-2 px-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-human-child
                                </v-icon>
                              </div>
                              <div class="col-6 adult" style="width: 50%">
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg2()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls1 }}</div>
                                  </div>
                                  <div class="col-4" @click="add2()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div>{{ item.name }}</div> -->
                            </div>

                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div
                                class="col-5 d-flex flex-column pe-0"
                                style="width: 38%"
                              >
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >Lap Infant</span
                                >
                                <span style="font-size: 11px; color: #4e4d4d"
                                  >2(yrs)</span
                                >
                              </div>

                              <div class="col-1 ps-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-baby-buggy
                                </v-icon>
                              </div>
                              <div class="col-6 adult" style="width: 50%">
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg3()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls2 }}</div>
                                  </div>
                                  <div class="col-4" @click="add3()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div class="col-4">
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: grey;
                                  "
                                  >Cabin</span
                                >
                              </div>

                              <div class="col-2" align="center">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-seat-passenger
                                </v-icon>
                              </div>
                              <div class="col-6">
                                <div class="input-styles-1 select-2">
                                  <v-select
                                    v-model="classType"
                                    ref="mySelect"
                                    @click="selectFunction()"
                                    :items="classTypeitems"
                                    variant="underlined"
                                    item-title="text"
                                    style="color: gray"
                                  >
                                    <template
                                      v-slot:item="{ props, item, index }"
                                    >
                                      <v-list-item
                                        class="f-size-14 fw-500"
                                        v-bind="props"
                                        :subtitle="item.department"
                                        @click="selectClasstype(item, index)"
                                      ></v-list-item>
                                    </template>
                                  </v-select>
                                </div>
                              </div>
                            </div>

                            <div
                              align="end"
                              class="mt-4 pe-3 d-flex justify-end"
                              @click="
                                (travels = false), localdone(), closeDropdown()
                              "
                            >
                              <v-btn
                                rounded="3"
                                color="#faa41f"
                                width="100px"
                                height="30px"
                                style="color: white"
                              >
                                Done
                              </v-btn>
                            </div>
                          </div>
                        </template>

                        <template #selection="{}">
                          <div class="pax-fonts">
                            <span>{{ aduls }} Adt</span>
                            <span v-if="aduls1 > 0">, {{ aduls1 }} Ch</span>
                            <span v-if="aduls2 > 0">, {{ aduls2 }} Inf</span>

                            <span>, {{ classType }}</span>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div
                class="col-lg-3 col-md-6 mt-3 mt-sm-0 d-flex justify-content-end justify-content-sm-start fareTypeSection"
              >
                <div class="d-flex align-center" style="height: fit-content">
                  <v-checkbox v-model="fareType" value="PUB"></v-checkbox>
                  <span class="f-size-14 fw-400">PUB</span>
                </div>
                <div class="d-flex align-center" style="height: fit-content">
                  <v-checkbox v-model="fareType" value="NET"></v-checkbox>
                  <span class="f-size-14 fw-400">NET</span>
                </div>
              </div> -->
            </div>

            <div v-if="rount || Oneway">
              <div class="row mt-2 mt-sm-4 gx-0">
                <div class="col-lg-11 col-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 mb-3">
                      <label
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          padding-left: 5px;
                        "
                        >From</label
                      >
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon size="20">mdi-airplane-takeoff</v-icon>
                        </span>
                        <span class="p-float-label">
                          <!-- <AutoComplete v-model="oneway.from" optionLabel="name" dropdown
                            :suggestions="filteredCountries" @change="onChangeFrom(oneway.from)"
                            @click="fromClick(oneway)" @complete="search" style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            " ref="fromAutoComplete">
                            <template #option="slotProps">
                              <div class="flex align-items-center dropdown-box" @click="focus1()">
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete> -->

                          <div class="vas-autocomplete">
                            <div
                              class="d-flex align-center flex-row justify-content-between"
                            >
                              <div>
                                <input
                                  type="text"
                                  placeholder="From"
                                  v-model="inputCity"
                                  ref="fromAutoComplete"
                                  @keyup="typeCity(inputCity, 1)"
                                  class="inter-input"
                                  @click="
                                    (formres = true), (formres1 = false);
                                    city1 = [];
                                  "
                                />
                              </div>
                              <div v-if="selectedFrom" class="pe-2">
                                <v-icon
                                  @click="fromInputData()"
                                  size="17"
                                  color="grey"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>

                            <div v-if="typedrop">
                              <div
                                class="autocompete-menual-dropdown"
                                v-if="this.city.length > 0"
                              >
                                <div v-for="(data, index) in city" :key="index">
                                  <div
                                    @click="datachange(data)"
                                    :style="
                                      index == city.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex va-back"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(90deg)"
                                        color="gray"
                                        size="20"
                                      >
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}</v-icon
                                      >
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }}, {{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>
                                  <!-- -------group--- -->

                                  <div v-if="data.group_airports.length > 0">
                                    <div
                                      v-for="group in data.group_airports"
                                      :key="group"
                                    >
                                      <div
                                        v-if="
                                          data.group_airport_city != group.iata
                                        "
                                      >
                                        <div
                                          class="d-flex va-back"
                                          @click="datachange(group)"
                                        >
                                          <div
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                            class="ms-3"
                                          >
                                            <v-icon
                                              style="transform: rotate(90deg)"
                                              color="gray"
                                              size="20"
                                            >
                                              mdi-airplane
                                            </v-icon>
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ group.iata }},
                                            {{ group.city }},{{ group.name }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div v-if="fromloader">
                          <!-- <v-progress-circular
                            color="primary"
                            indeterminate size="18"
                          ></v-progress-circular> -->
                          <div
                            class="spinner-border spinner-border-sm"
                            style="
                              display: flex;
                              align-items: center;
                              color: #124d85;
                            "
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromErrorMessage"
                          >Departure is required</span
                        >
                        <br v-if="fromErrorMessage && maximumletter" />
                        <span class="p-error" v-if="maximumletter"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mb-3">
                      <label
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          padding-left: 5px;
                        "
                        >To</label
                      >
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon size="20">mdi-airplane-landing</v-icon>
                        </span>
                        <span class="p-float-label">
                          <!-- <AutoComplete v-model="oneway.to" optionLabel="name" dropdown :suggestions="filteredCountries"
                            @change="onChangeTo()" @click="toClick(oneway)" @complete="search" ref="secondAutoComplete"
                            style="width: 100%;height: 100%;position: relative;">
                            <template #option="slotProps">
                              <div class="dropdown-container">
                                <div class="flex align-items-center dropdown-box" @click="focus2()">
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </div>
                            </template>
                          </AutoComplete>
                           -->

                          <div class="vas-autocomplete">
                            <div
                              class="d-flex align-center flex-row justify-content-between"
                            >
                              <div>
                                <input
                                  type="text"
                                  ref="toAutoComplete"
                                  placeholder="To"
                                  v-model="inputCityto"
                                  @keyup="typeCity(inputCityto, 2)"
                                  class="inter-input"
                                  @click="
                                    (inputCityto = ''),
                                      (formres1 = true),
                                      (formres = false),
                                      (city = [])
                                  "
                                />
                              </div>
                              <div v-if="selectedTo" class="pe-2">
                                <v-icon
                                  @click="toInputData()"
                                  size="17"
                                  color="grey"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>

                            <div v-if="typedrop1">
                              <div
                                class="autocompete-menual-dropdown1"
                                v-if="this.city1.length > 0"
                              >
                                <div
                                  v-for="(data, index) in city1"
                                  :key="index"
                                >
                                  <div
                                    @click="datachangeTo(data)"
                                    :style="
                                      index == city1.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex va-back"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(90deg)"
                                        color="gray"
                                        size="20"
                                      >
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}
                                      </v-icon>
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }}, {{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>

                                  <div
                                    class="ms-3"
                                    v-if="data.group_airports.length > 0"
                                  >
                                    <div
                                      v-for="group in data.group_airports"
                                      :key="group"
                                    >
                                      <div
                                        v-if="
                                          data.group_airport_city != group.iata
                                        "
                                      >
                                        <div
                                          class="d-flex va-back"
                                          @click="datachangeTo(group)"
                                        >
                                          <div
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            <v-icon
                                              style="transform: rotate(90deg)"
                                              color="gray"
                                              size="20"
                                              >mdi-airplane</v-icon
                                            >
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ group.iata }},
                                            {{ group.city }},{{ group.name }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div v-if="toloader">
                          <!-- <v-progress-circular
                            color="primary"
                            indeterminate size="18"
                          ></v-progress-circular> -->
                          <div
                            class="spinner-border spinner-border-sm"
                            style="
                              display: flex;
                              align-items: center;
                              color: #124d85;
                            "
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toErrorMessage"
                          >Arrival is required</span
                        >
                        <br v-if="toErrorMessage && maximumletter1" />
                        <span class="p-error" v-if="maximumletter1"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mb-3">
                      <label
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          padding-left: 5px;
                        "
                        >Depature Date</label
                      >
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon size="20">mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <!-- <span :class="oneway.dedate === '' ? 'label-top' : 'datepicker-label'">Departure</span> -->
                          <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.dedate"
                            placeholder="Depart Date"
                            :min-date="
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                0,
                                0,
                                0
                              )
                            "
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate(),
                                23,
                                59,
                                59
                              )
                            "
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            :month-change-on-scroll="false"
                            class="px-0 datepicker"
                          />
                          <!-- {{ console.log(oneway.dedate,'onewaydedateeee')}} -->
                          <!-- <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.dedate"
                            placeholder="Depart Date"
                            :min-date="minDate"
                            :max-date="maxDate"
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            :month-change-on-scroll="true"
                            class="px-0 datepicker"
                          /> -->
                          <!-- {{ console.log(oneway.dedate,'onewaydedateeee..1')}} -->
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromDateError"
                          >Dep. Date is required</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-lg-3 col-sm-6"
                      :class="{ 'disable-return': Oneway }"
                    >
                      <label
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          padding-left: 5px;
                        "
                        >Return Date</label
                      >
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon size="20">mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <!-- <span :class="oneway.redate ? 'label-top' : 'datepicker-label2'">Return</span> -->
                          <VueDatePicker
                            :ref="rount ? 'dpRef2' : ''"
                            v-model="oneway.redate"
                            :format="dateFormat"
                            v-if="rount"
                            placeholder="Return Date"
                            :start-date="oneway.dedate"
                            :min-date="
                              oneway.dedate ? oneway.dedate : new Date()
                            "
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="toDateChanged"
                            :on-click-outside="ClickOutReturn"
                            class="datepicker"
                          />

                          <VueDatePicker
                            v-model="date"
                            placeholder=""
                            v-if="!rount"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toDateError"
                          >Return. Date is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-1 mt-3 mt-lg-1 d-flex align-center justify-content-lg-start justify-end magnify-btn"
                >
                  <div>
                    <v-btn
                      v-if="rount"
                      color="#faa41f"
                      height="30px"
                      width="75px"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                      @click="sended()"
                    >
                      <v-icon color="white" size="18">mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="Oneway"
                      color="#faa41f"
                      height="30px"
                      width="75px"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                      @click="sendedone()"
                    >
                      <v-icon color="white" size="18">mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="multicity">
              <div class="row mt-2 mt-sm-4 gx-0">
                <div class="col-lg-8 col-12">
                  <div
                    v-for="(datas, index) of multi_city.itinery"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 mb-3">
                        <label
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            padding-left: 5px;
                          "
                          >From</label
                        >
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-takeoff</v-icon>
                          </span>
                          <span class="p-float-label">
                            <!-- <AutoComplete v-model="datas.from" optionLabel="name" dropdown
                              :suggestions="filteredCountries" @change="onChangeMultiFrom(index)"
                              @click="multiFromClick(index)" @complete="search" style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              ">
                              <template #option="slotProps">
                                <div class="flex align-items-center dropdown-box" @click="Multifocus1(index)">
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">From</label> -->
                            <div class="vas-autocomplete">
                              <div
                                class="d-flex align-center flex-row justify-content-between"
                              >
                                <div>
                                  <input
                                    type="text"
                                    style="cursor: pointer; font-weight: 500"
                                    placeholder="From"
                                    v-model="datas.from"
                                    @click="multi_city.itinery[index].from = ''"
                                    @keyup="typeMultiCity(datas.from, index, 1)"
                                    class="inter-input text-truncate"
                                    ref="multiFromAutoComplete"
                                  />
                                </div>
                                <div v-if="datas.selectedFrom" class="pe-2">
                                  <v-icon
                                    @click="multiFrom(index)"
                                    size="17"
                                    color="grey"
                                    >mdi-close</v-icon
                                  >
                                </div>
                              </div>
                              <div
                                v-if="
                                  multi_city.itinery[index].autocomfrom
                                    ? true
                                    : false
                                "
                              >
                                <div
                                  class="autocompete-menual-dropdown"
                                  v-if="
                                    this.multidrop.length > 0 ? true : false
                                  "
                                >
                                  <div
                                    v-for="(data1, index1) in multidrop"
                                    :key="index1"
                                  >
                                    {{
                                      console.log(
                                        data1,
                                        multi_city,
                                        "data1data1"
                                      )
                                    }}
                                    <div
                                      @click="datachangeMuti(data1, index)"
                                      :style="
                                        index1 == multidrop.length - 1
                                          ? ''
                                          : 'border-bottom:1px solid lightgray'
                                      "
                                      class="d-flex p-2 va-back"
                                    >
                                      <div
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <v-icon
                                          style="transform: rotate(90deg)"
                                          color="gray"
                                          size="20"
                                        >
                                          {{
                                            data1.location === "airport"
                                              ? "mdi-airplane"
                                              : "mdi-radiobox-marked"
                                          }}
                                        </v-icon>
                                      </div>

                                      <div class="ms-1 va-content">
                                        {{ data1.iata }}, {{ data1.city }},{{
                                          data1.name
                                        }}
                                      </div>
                                    </div>

                                    <div v-if="data1.group_airports.length > 0">
                                      <div
                                        v-for="(
                                          group, index1
                                        ) in data1.group_airports"
                                        :key="index1"
                                      >
                                        <div
                                          v-if="
                                            data1.group_airport_city !=
                                            group.iata
                                          "
                                        >
                                          <div
                                            class="d-flex va-back"
                                            @click="
                                              datachangeMuti(group, index)
                                            "
                                          >
                                            <div
                                              style="
                                                display: flex;
                                                align-items: center;
                                              "
                                              class="ms-3"
                                            >
                                              <v-icon
                                                style="transform: rotate(90deg)"
                                                color="gray"
                                                size="20"
                                                >mdi-airplane
                                              </v-icon>
                                            </div>

                                            <div class="m-1 va-content">
                                              {{ group.iata }},
                                              {{ group.city }},{{ group.name }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                          <div v-if="datas.autocomfrom">
                            <div
                              class="spinner-border spinner-border-sm"
                              style="
                                display: flex;
                                align-items: center;
                                color: #124d85;
                              "
                              role="status"
                            ></div>
                          </div>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="fromErrorMessage"
                            >Departure is required</span
                          >
                          <br v-if="fromErrorMessage && maximumletter" />
                          <!-- <span class="p-error" v-if="maximumletter"
                            >Enter minimum 3 letters</span
                          > -->
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 mb-3">
                        <label
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            padding-left: 5px;
                          "
                          >To</label
                        >
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-landing</v-icon>
                          </span>

                          <span class="p-float-label">
                            <!-- <AutoComplete v-model="datas.to" ref="multiTo" optionLabel="name" dropdown
                              :suggestions="filteredCountries" @change="onChangeMultiTo(index)"
                              @click="multiToClick(index)" @complete="search" style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              ">
                              <template #option="slotProps">
                                <div class="dropdown-container">
                                  <div class="flex align-items-center dropdown-box" @click="Multifocus2(datas, index)">
                                    <div>
                                      {{ slotProps.option.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">To</label> -->
                            <div class="vas-autocomplete">
                              <div
                                class="d-flex align-center flex-row justify-content-between"
                              >
                                <div>
                                  <input
                                    style="cursor: pointer; font-weight: 500"
                                    type="text"
                                    placeholder="To"
                                    v-model="datas.to"
                                    @click="multi_city.itinery[index].to = ''"
                                    @keyup="typeMultiCity(datas.to, index, 2)"
                                    class="inter-input text-truncate"
                                    ref="multiToAutocomplete"
                                  />
                                </div>
                                <div v-if="datas.selectedTo" class="pe-2">
                                  <v-icon
                                    @click="multiTo(index)"
                                    size="17"
                                    color="grey"
                                    >mdi-close</v-icon
                                  >
                                </div>
                              </div>

                              <div v-if="datas.autocomTo">
                                <div
                                  class="autocompete-menual-dropdown1"
                                  v-if="multidrop1.length > 0 ? true : false"
                                >
                                  <div
                                    v-for="(data, index1) in multidrop1"
                                    :key="index1"
                                  >
                                    <div
                                      @click="datachangeMutiTo(data, index)"
                                      :style="
                                        index1 == multidrop1.length - 1
                                          ? ''
                                          : 'border-bottom:1px solid lightgray'
                                      "
                                      class="d-flex p-2 va-back"
                                    >
                                      <div
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <v-icon
                                          style="transform: rotate(90deg)"
                                          color="gray"
                                          size="20"
                                        >
                                          {{
                                            data.location === "airport"
                                              ? "mdi-airplane"
                                              : "mdi-radiobox-marked"
                                          }}
                                        </v-icon>
                                      </div>

                                      <div class="ms-1 va-content">
                                        {{ data.iata }}, {{ data.city }},{{
                                          data.name
                                        }}
                                      </div>
                                    </div>

                                    <div v-if="data.group_airports.length > 0">
                                      <div
                                        v-for="(
                                          group, index1
                                        ) in data.group_airports"
                                        :key="index1"
                                      >
                                        <div
                                          v-if="
                                            data.group_airport_city !=
                                            group.iata
                                          "
                                        >
                                          <div
                                            class="d-flex va-back"
                                            @click="
                                              datachangeMutiTo(group, index)
                                            "
                                          >
                                            <div
                                              style="
                                                display: flex;
                                                align-items: center;
                                              "
                                              class="ms-3"
                                            >
                                              <v-icon
                                                style="transform: rotate(90deg)"
                                                color="gray"
                                                size="20"
                                              >
                                                mdi-airplane
                                              </v-icon>
                                            </div>

                                            <div class="m-1 va-content">
                                              {{ group.iata }},
                                              {{ group.city }},{{ group.name }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                          <div v-if="datas.autocomTo">
                            <div
                              class="spinner-border spinner-border-sm"
                              style="
                                display: flex;
                                align-items: center;
                                color: #124d85;
                              "
                              role="status"
                            ></div>
                          </div>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="toErrorMessage"
                            >Arrival is required</span
                          >
                          <br v-if="toErrorMessage && maximumletter1" />
                          <!-- <span class="p-error" v-if="maximumletter1"
                            >Enter minimum 3 letters</span
                          > -->
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 mb-3">
                        <label
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            padding-left: 5px;
                          "
                          >Depature</label
                        >
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-calendar</v-icon>
                          </span>

                          <span class="p-float-label">
                            <!-- <span :class="datas.depdata ? 'label-top': 'datepicker-label'">
                              Departure
                            </span> -->

                            <VueDatePicker
                              ref="dpRef4"
                              v-model="datas.depdata"
                              :start-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :min-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :max-date="
                                new Date(
                                  new Date().getFullYear() + 1,
                                  new Date().getMonth(),
                                  new Date().getDate()
                                )
                              "
                              :format="dateFormat"
                              :hide-navigation="['time']"
                              auto-apply
                              @update:modelValue="
                                (newValue) => multiDateChanged(newValue, index)
                              "
                              :on-click-outside="multiClickOutside()"
                              :month-change-on-scroll="false"
                              placeholder="Depart Date"
                              class="px-0 datepicker"
                            />
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="fromDateError"
                            >Dep. Date is required</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 d-grid">
                  <div class="row justify-content-between">
                    <div
                      class="col-md-8 d-flex align-end justify-content-sm-start justify-content-end mb-3"
                    >
                      <span
                        class="d-flex align-center ms-1 mb-1"
                        style="cursor: pointer"
                        @click="deleteSegment(index)"
                        v-if="deleteSeg"
                      >
                        <v-icon color="red" size="18">mdi-close-octagon</v-icon>
                      </span>
                      <span
                        class="d-flex align-center add_style ms-2 order-sm-0 order-1"
                        @click="multiSend(index)"
                        v-if="addmore"
                      >
                        <v-icon color="green" size="18">mdi-plus-circle</v-icon>
                        <span class="ms-1 f-size-14 fw-500">Add More</span>
                      </span>
                      <span
                        class="d-flex align-center clear_style ms-3 order-sm-1 order-0"
                        @click="clearall1()"
                        v-if="clearall"
                      >
                        <v-icon color="red" size="20"
                          >mdi-close-box-multiple</v-icon
                        >
                        <span class="ms-1 f-size-14 fw-500">Clear All</span>
                      </span>

                      <!-- <span class="d-flex align-center add_style" @click="send(index)" v-if="addmore">
                        <v-icon color="green" size="20" style="width: auto;">mdi-plus-circle</v-icon>
                        <span class="ms-1" style="font-size:14; font-weight: 600;">Add More</span>
                      </span> -->
                    </div>
                    <div
                      class="col-lg-4 mt-lg-0 mb-3 d-flex pe-2 align-end justify-center justify-content-lg-end magnify-btn"
                    >
                      <div @click="sendedMulti()">
                        <v-btn
                          color="#faa41f"
                          height="30px"
                          width="130px"
                          class="ms-lg-3 ms-0"
                          rounded="1"
                        >
                          <v-icon color="white" size="18">mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="travelHistory">
            <!-- <div v-if="travelHistory" style="max-height: 132px;overflow-y: scroll;overflow-x: hidden;"> -->
            <p class="f-size-18 fw-500 m-0">Recent Searches</p>
            <div
              v-if="this.RecentSearchData.length > 0 ? true : false"
              class="searchrecent"
            >
              <div class="row">
                <div
                  class="col-md-4 mt-2"
                  v-for="data of this.RecentSearchData"
                  :key="data"
                >
                  <div v-if="!data.itinery">
                    <v-card @click="cardassigndata(data)" class="pa-2">
                      <div class="row">
                        <div class="col-5">
                          <p class="f-size-14 fw-500 m-0">
                            {{ data.from }}
                          </p>
                          <p class="f-size-14 fw-500 m-0">
                            {{ getdated(data.fromDate) }}
                          </p>
                        </div>
                        <div class="col-2">
                          <div v-if="data.city == 'roundone'">
                            <v-icon>mdi-swap-horizontal</v-icon>
                          </div>
                          <div v-if="data.city == 'onewaytwo'">
                            <v-icon>mdi-arrow-right</v-icon>
                          </div>
                          <!-- <div v-if="data.city == 'multithree'">
                              <v-icon>mdi-swap-horizontal</v-icon>
                              <v-icon>mdi-swap-horizontal</v-icon>
                              <v-icon>mdi-swap-horizontal</v-icon>
                            </div> -->
                        </div>
                        <div class="col-5">
                          <p class="f-size-14 fw-500 m-0">
                            {{ data.to }}
                          </p>
                          <p
                            class="f-size-14 fw-500 m-0"
                            v-if="data.toDate && data.city == 'roundone'"
                          >
                            {{ getdated(data.toDate) }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          <p class="f-size-14 fw-500 m-0">
                            Passenger(s):
                            <span v-if="data.adult > 0"
                              >{{ data.adult }}Adt</span
                            >
                            <span class="ms-1" v-if="data.child > 0"
                              >{{ data.child }}Ch</span
                            >
                            <span class="ms-1" v-if="data.inf > 0"
                              >{{ data.inf }}Inf</span
                            >
                          </p>
                        </div>
                        <div class="col-5" v-if="data.class">
                          <span class="f-size-14 fw-500"
                            >Class: {{ data.class }}</span
                          >
                        </div>
                      </div>
                    </v-card>
                  </div>

                  <div v-if="data.itinery">
                    <v-card @click="cardassigndata(data)" class="pa-2">
                      <div
                        class="row"
                        v-for="(item, index) of data.itinery"
                        :key="index"
                      >
                        <div class="col-5">
                          <p class="f-size-14 fw-500 m-0">
                            {{ item.from }}
                          </p>
                          <p class="f-size-14 fw-500 m-0">
                            {{ getdated(item.depdata) }}
                          </p>
                        </div>
                        <div class="col-2">
                          <div>
                            <v-icon>mdi-arrow-right</v-icon>
                          </div>
                        </div>
                        <div class="col-5">
                          <p class="f-size-14 fw-500 m-0">
                            {{ item.to }}
                          </p>
                          <!-- <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                              {{ getdated(data.toDate) }}
                            </p> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-7">
                          <p class="f-size-14 fw-500 m-0">
                            Passenger(s):
                            <span v-if="data.adult > 0"
                              >{{ data.adult }}Adt</span
                            >
                            <span class="ms-1" v-if="data.child > 0"
                              >{{ data.child }}Ch</span
                            >
                            <span class="ms-1" v-if="data.infrant > 0"
                              >{{ data.infrant }}Inf</span
                            >
                          </p>
                        </div>
                        <div class="col-5" v-if="data.class">
                          <span class="f-size-14 fw-500"
                            >Class: {{ data.class }}</span
                          >
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="border-1 border-top advanceOpt" v-if="advanceOptionvalue">
            <div class="row justify-content-between align-center py-2">
              <!-- <div class="col-md-8 d-flex align-center" v-if="clearallFilter"> -->
              <div class="col-md-8 d-flex align-center" v-if="clearallFilter">
                <div class="border-1 border-end pe-1" v-if="filterIcon()">
                  <v-icon size="20px"> mdi-filter </v-icon>
                </div>

                <div class="d-flex flex-wrap ms-2">
                  <span
                    v-if="includedAirlines.length > 0"
                    class="d-flex op-font"
                  >
                    <span class="fw-500">INCL:</span>
                    <div
                      v-for="(data, index) of includedAirlines"
                      :key="index"
                      class="ms-1"
                    >
                      <span>{{ data }}</span>
                      <span v-if="index !== includedAirlines.length - 1"
                        >,</span
                      >
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span
                    v-if="excludedAirlines.length > 0"
                    class="d-flex op-font"
                  >
                    <span class="fw-500">EXCL:</span>
                    <div
                      v-for="(data, index) of excludedAirlines"
                      :key="index"
                      class="ms-1"
                    >
                      <span>{{ data }}</span>
                      <span v-if="index !== excludedAirlines.length - 1"
                        >,</span
                      >
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span
                    v-if="selectedStops && selectedStops != 'Any Stop'"
                    class="d-flex op-font"
                  >
                    <span class="fw-500">Stops:</span>
                    <div class="ms-1">
                      <span>{{ selectedStops }}</span>
                      <span>,</span>
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span
                    v-if="layDuration && Object.keys(layDuration).length > 0"
                    class="d-flex op-font"
                  >
                    <span class="fw-500">Layover:</span>
                    <div class="ms-1">
                      <span
                        >{{
                          layDuration.min == 12
                            ? `${layDuration.min}+`
                            : `${layDuration.min}-${layDuration.max}`
                        }}
                        hrs</span
                      >
                      <!-- <span>,</span> -->
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span v-if="AvoidCountry.length > 0" class="d-flex op-font">
                    <span class="fw-500">Avoidcountry:</span>
                    <div
                      v-for="(data, index) of AvoidCountry"
                      :key="index"
                      class="ms-1"
                    >
                      <span>{{ data }}</span>
                      <span v-if="index !== AvoidCountry.length - 1">,</span>
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span
                    v-if="includedAirports.length > 0"
                    class="d-flex op-font"
                  >
                    <span class="fw-500">INCL AIRPORTS:</span>
                    <div
                      v-for="(data, index) of includedAirports"
                      :key="index"
                      class="ms-1"
                    >
                      <span>{{ data }}</span>
                      <span v-if="index !== includedAirports.length - 1"
                        >,</span
                      >
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span
                    v-if="excludedAirports.length > 0"
                    class="d-flex op-font"
                    ><span class="fw-500">EXCL AIRPORTS:</span>
                    <div
                      v-for="(data, index) of excludedAirports"
                      :key="index"
                      class="ms-1"
                    >
                      <span>{{ data }}</span>
                      <span v-if="index !== excludedAirports.length - 1"
                        >,</span
                      >
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span v-if="selectedDays" class="d-flex op-font">
                    <span class="fw-500">AlternateDays:</span>
                    <div class="ms-1">
                      <span>{{ selectedDays }}</span>
                    </div>
                    <span class="ms-1 fw-500">|</span>
                  </span>

                  <span
                    v-if="selectedFareTypes.length > 0"
                    class="d-flex op-font"
                  >
                    <span class="fw-500">FareType:</span>
                    <div
                      v-for="(data, index) of selectedFareTypes"
                      :key="index"
                      class="ms-1"
                    >
                      <span>{{ data }}</span>
                      <span v-if="index !== selectedFareTypes.length - 1"
                        >,</span
                      >
                    </div>
                  </span>

                  <span
                    v-if="selectedPenalties.length > 0"
                    class="d-flex op-font"
                  >
                    <span class="fw-500">| Penalties:</span>
                    <div
                      v-for="(data, index) of selectedPenalties"
                      :key="index"
                      class="ms-1"
                    >
                      <span>{{ data }}</span>
                      <span v-if="index !== selectedPenalties.length - 1"
                        >,</span
                      >
                    </div>
                  </span>

                  <span v-if="filterIcon()">
                    <v-icon
                      class="ms-2"
                      @click="clearadOption()"
                      size="20px"
                      color="red"
                      style="cursor: pointer"
                    >
                      mdi-close-circle
                    </v-icon>
                  </span>
                </div>
                <div></div>
              </div>

              <div
                class="col-md-3 d-flex align-items-center justify-content-end custom-font ms-auto"
              >
                <span
                  class="not-collapsed collapsed f-size-14"
                  data-bs-toggle="collapse"
                  href="#collapse-Example"
                  aria-expanded="false"
                  aria-controls="collapse-Example"
                  style="
                    padding-right: 5px;
                    cursor: pointer;
                    letter-spacing: 1px;
                  "
                  @click="toggleIcon"
                >
                  <span style="color: navy; text-decoration: underline"
                    >Advanced options</span
                  >
                </span>

                <v-icon
                  size="18"
                  class="not-collapsed collapsed me-3"
                  data-bs-toggle="collapse"
                  href="#collapse-Example"
                  aria-expanded="false"
                  aria-controls="collapse-Example"
                  v-model="isAdvanceOpt"
                  style="color: navy; cursor: pointer; overflow-anchor: none"
                >
                  {{
                    isAdvanceOpt
                      ? "mdi-chevron-up-box-outline"
                      : "mdi-chevron-down-box-outline"
                  }}</v-icon
                >
              </div>
            </div>

            <div
              class="p-1 collapse border-1 border-top"
              :class="{ show: isAdvanceOpt }"
              id="collapse-Example"
            >
              <div class="row gx-2 dropdownSection">
                <div class="col-lg-12 col-12 px-0">
                  <div
                    class="text-center mt-2 gx-3 justify-content-start d-flex gap-3 flex-wrap"
                  >
                    <div>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary dropdown-toggle btn-text"
                          :class="
                            isDropdownActive('airlines')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButtonAirlines"
                          data-toggle="dropdown"
                          data-bs-auto-close="false"
                          @click="toggleDropdown('airlines')"
                          :aria-expanded="isDropdownActive('airlines')"
                        >
                          Airlines
                        </button>

                        <div
                          class="dropdown-menu p-4"
                          :class="{ show: isDropdownActive('airlines') }"
                        >
                          <div class="form-check form-check-inline ps-0">
                            <div class="d-flex align-center">
                              <input
                                type="radio"
                                v-model="airex"
                                :value="true"
                                name="fav_language"
                              />
                              <label class="ms-1" for="includeOptionAirlines"
                                >Include</label
                              >
                            </div>
                          </div>
                          <div class="form-check form-check-inline">
                            <div class="d-flex align-center">
                              <input
                                type="radio"
                                v-model="airex"
                                :value="false"
                                name="fav_language"
                              />
                              <label class="ms-1" for="excludeOptionAirlines"
                                >Exclude</label
                              >
                            </div>
                          </div>
                          <input
  v-if="airex"
  type="text"
  @input="handleAirportInput($event, 1)"
  placeholder="Airline code/Name"
  class="form-control mt-2"
  v-model="airlineIncludeExclude"
/>

<input
  v-if="!airex"
  type="text"
  @input="handleAirportInput($event, 2)"
  placeholder="Airline code/Name"
  class="form-control mt-2"
  v-model="airlineIncludeExclude1"
/>

                          <div
                            v-if="airlineval"
                            style="font-size: 13px; color: red"
                          >
                            please enter the Airlines
                          </div>

                          <div class="d-flex justify-end">
                            <button
                              class="btn btn-primary mt-3 w-100 donebtn"
                              @click="addAirport1()"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary btn-text dropdown-toggle"
                          :class="
                            isDropdownActive('stops')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          @click="toggleDropdown('stops')"
                          :aria-expanded="isDropdownActive('stops')"
                          data-bs-auto-close="false"
                        >
                          Stops
                        </button>
                        <ul
                          class="dropdown-menu p-3"
                          :class="{ show: isDropdownActive('stops') }"
                          aria-labelledby="dropdownMenuButton2"
                        >
                          <li class="form-check ps-0">
                            <!-- <input
                              class="form-check-input"
                              type="checkbox"
                              @change="handleCheck('Any Stop')"
                              :checked="selectedStops === 'Any Stop'"
                            /> -->
                            <v-icon
                              color="primary"
                              size="23"
                              :disabled="!selectedStops"
                              :class="{ 'disabled-icon': selectedStops }"
                              >mdi-check
                            </v-icon>
                            <label
                              class="form-check-label"
                              :class="{ 'disabled-label': selectedStops }"
                            >
                              Any Stop
                            </label>
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkboxstop1"
                              value="0"
                              @change="handleCheck('0')"
                              :checked="selectedStops === '0'"
                            />
                            <label class="form-check-label" for="checkboxstop1"
                              >Non-Stop</label
                            >
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkboxstop2"
                              value="1"
                              @change="handleCheck('1')"
                              :checked="selectedStops === '1'"
                            />
                            <label class="form-check-label" for="checkboxstop2"
                              >upto 1 Stop</label
                            >
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkboxstop3"
                              value="2"
                              @change="handleCheck('2')"
                              :checked="selectedStops === '2'"
                            />
                            <label class="form-check-label" for="checkboxstop3"
                              >upto 2 Stops</label
                            >
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkboxstop4"
                              value="3"
                              @change="handleCheck('3')"
                              :checked="selectedStops === '3'"
                            />
                            <label class="form-check-label" for="checkboxstop4"
                              >upto 3 Stops</label
                            >
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkboxstop5"
                              value="4"
                              @change="handleCheck('4')"
                              :checked="selectedStops === '4'"
                            />
                            <label class="form-check-label" for="checkboxstop5"
                              >4+ Stops</label
                            >
                          </li>
                          <li class="mt-2">
                            <div class="d-flex justify-end">
                              <a
                                class="btn btn-primary w-100 donebtn"
                                @click="toggleDropdown('airlinesooo')"
                                >Done</a
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary btn-text dropdown-toggle"
                          :class="
                            isDropdownActive('Layover')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          @click="toggleDropdown('Layover')"
                          :aria-expanded="isDropdownActive('Layover')"
                          data-bs-auto-close="false"
                        >
                          Layover Duration
                        </button>
                        <ul
                          class="dropdown-menu p-3"
                          :class="{ show: isDropdownActive('Layover') }"
                          aria-labelledby="dropdownMenuButton2"
                        >
                          <li class="form-check ps-0">
                            <v-icon
                              color="primary"
                              size="23"
                              :disabled="!layDuration"
                              :class="{ 'disabled-icon': layDuration }"
                              >mdi-check
                            </v-icon>
                            <label
                              class="form-check-label"
                              :class="{ 'disabled-label': layDuration }"
                            >
                              No Preference
                            </label>
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox1"
                              :value="{ min: 1, max: 3 }"
                              @change="handleLayDuration({ min: 1, max: 3 })"
                              :checked="
                                layDuration &&
                                layDuration.min === 1 &&
                                layDuration.max === 3
                              "
                            />

                            <label class="form-check-label" for="checkbox1">
                              1-3 hrs
                            </label>
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox2"
                              :value="{ min: 3, max: 6 }"
                              @change="handleLayDuration({ min: 3, max: 6 })"
                              :checked="
                                layDuration &&
                                layDuration.min === 3 &&
                                layDuration.max === 6
                              "
                            />

                            <label class="form-check-label" for="checkbox2">
                              3-6 hrs
                            </label>
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox3"
                              :value="{ min: 6, max: 12 }"
                              @change="handleLayDuration({ min: 6, max: 12 })"
                              :checked="
                                layDuration &&
                                layDuration.min === 6 &&
                                layDuration.max === 12
                              "
                            />
                            <label class="form-check-label" for="checkbox3">
                              6-12 hrs
                            </label>
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox4"
                              :value="{ min: 12, max: 24 }"
                              @change="handleLayDuration({ min: 12, max: 24 })"
                              :checked="
                                layDuration &&
                                layDuration.min === 12 &&
                                layDuration.max === 24
                              "
                            />
                            <label class="form-check-label" for="checkbox3">
                              12+ hrs
                            </label>
                          </li>

                          <li class="mt-2">
                            <div class="d-flex justify-end">
                              <a
                                class="btn btn-primary w-100 donebtn"
                                @click="
                                  submitLayover,
                                    toggleDropdown('airlinesooo'),
                                    console.log(layDuration, 'pppp')
                                "
                                >Done</a
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary btn-text dropdown-toggle"
                          :class="
                            isDropdownActive('Via-Airport')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButtonAirports"
                          data-bs-toggle="dropdown"
                          @click="toggleDropdown('Via-Airport')"
                          :aria-expanded="isDropdownActive('Via-Airport')"
                          data-bs-auto-close="false"
                        >
                          Via-Airport
                        </button>
                        <div
                          class="dropdown-menu p-4"
                          :class="{ show: isDropdownActive('Via-Airport') }"
                        >
                          <div class="form-check form-check-inline ps-0">
                            <div class="d-flex align-center">
                              <input
                                type="radio"
                                v-model="includeExclude"
                                value="include"
                                id="includeOptionAirports"
                              />
                              <label class="ms-1" for="includeOptionAirports"
                                >Include</label
                              >
                            </div>
                          </div>
                          <div class="form-check form-check-inline">
                            <div class="d-flex align-center">
                              <input
                                type="radio"
                                v-model="includeExclude"
                                value="exclude"
                                id="excludeOptionAirports"
                              />
                              <label class="ms-1" for="excludeOptionAirports"
                                >Exclude</label
                              >
                            </div>
                          </div>
                          <input
                            type="text"
                            :value="formattedAirportInput"
                            @input="updateAirportInput"
                            placeholder="Airport code/Name"
                            class="form-control mt-2"
                          />

                          <div
                            v-if="airlineval1"
                            style="font-size: 13px; color: red"
                          >
                            please enter the Airport
                          </div>

                          <div class="d-flex justify-end">
                            <button
                              class="btn btn-primary mt-3 w-100 donebtn"
                              @click="addAirport()"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary btn-text dropdown-toggle"
                          :class="
                            isDropdownActive('Avoid Country')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButton4"
                          data-bs-toggle="dropdown"
                          @click="toggleDropdown('Avoid Country')"
                          :aria-expanded="isDropdownActive('Avoid Country')"
                          data-bs-auto-close="false"
                        >
                          Avoid Country
                        </button>
                        <ul
                          class="dropdown-menu p-3"
                          :class="{ show: isDropdownActive('Avoid Country') }"
                          aria-labelledby="dropdownMenuButton6"
                        >
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox1"
                              value="US"
                              v-model="AvoidCountry"
                            />

                            <label class="form-check-label" for="checkbox1"
                              >US</label
                            >
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox2"
                              value="UK"
                              v-model="AvoidCountry"
                            />
                            <label class="form-check-label" for="checkbox2"
                              >UK</label
                            >
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox3"
                              value="CA"
                              v-model="AvoidCountry"
                            />
                            <label class="form-check-label" for="checkbox3"
                              >CA</label
                            >
                          </li>
                          <li class="mt-2">
                            <div class="d-flex justify-end">
                              <a
                                class="btn btn-primary w-100 donebtn"
                                @click="
                                  handleSubmit, toggleDropdown('airlinesooo')
                                "
                                >Done</a
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div v-if="!this.multicity">
                      <div class="dropdown">
                        <button
                          class="btn btn-primary btn-text dropdown-toggle"
                          :class="
                            isDropdownActive('Alternate')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButton5"
                          data-bs-toggle="dropdown"
                          @click="toggleDropdown('Alternate')"
                          :aria-expanded="isDropdownActive('Alternate')"
                          data-bs-auto-close="false"
                        >
                          Alternate Days
                        </button>
                        <ul
                          class="dropdown-menu p-3"
                          :class="{ show: isDropdownActive('Alternate') }"
                          aria-labelledby="dropdownMenuButton5"
                          style="cursor: pointer"
                        >
                          <li
                            v-for="(day, index) in days"
                            :key="index"
                            class="mt-2 p-1"
                            :style="{
                              border: '1px solid grey',
                              backgroundColor: day.valdata ? 'gold' : 'white',
                            }"
                          >
                            <div
                              @click="selectDays(day, index)"
                              class="d-flex justify-space-between"
                            >
                              <div>
                                {{ day.label }}
                              </div>
                              <div style="display: flex; align-items: center">
                                <v-icon size="16" color="grey"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>
                          </li>
                          <li
                            class="mt-2"
                            @click="toggleDropdown('airlinesooo')"
                          >
                            <div class="d-flex justify-end">
                              <a class="btn btn-primary w-100 donebtn">Done</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary btn-text dropdown-toggle"
                          :class="
                            isDropdownActive('Fare Type')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButton6"
                          data-bs-toggle="dropdown"
                          @click="toggleDropdown('Fare Type')"
                          :aria-expanded="isDropdownActive('Fare Type')"
                          data-bs-auto-close="false"
                        >
                          Fare Type
                        </button>
                        <ul
                          class="dropdown-menu p-3"
                          :class="{ show: isDropdownActive('Fare Type') }"
                          aria-labelledby="dropdownMenuButton6"
                        >
                          <li class="form-check ps-0">
                            <!-- <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox11"
                              checked
                              :disabled="selectedFareTypes.length > 0"
                            /> -->
                            <v-icon
                              color="primary"
                              size="23"
                              :class="{
                                'disabled-icon': selectedFareTypes.length > 0,
                              }"
                              >mdi-check
                            </v-icon>
                            <label
                              class="form-check-label"
                              :class="{
                                'disabled-label': selectedFareTypes.length > 0,
                              }"
                            >
                              Any FareType
                            </label>
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox11"
                              value="PUB"
                              v-model="selectedFareTypes"
                            />
                            <label class="form-check-label" for="checkbox11"
                              >PUB</label
                            >
                          </li>
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox21"
                              value="NET"
                              v-model="selectedFareTypes"
                            />
                            <label class="form-check-label" for="checkbox21"
                              >NET</label
                            >
                          </li>
                          <li
                            class="mt-2"
                            @click="toggleDropdown('airlinesooo')"
                          >
                            <div class="d-flex justify-end">
                              <a class="btn btn-primary w-100 donebtn">Done</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="dropdown">
                        <button
                          class="btn btn-primary btn-text dropdown-toggle"
                          :class="
                            isDropdownActive('Penalties')
                              ? 'activeBtn'
                              : 'advanceBtn'
                          "
                          type="button"
                          id="dropdownMenuButton7"
                          data-bs-toggle="dropdown"
                          @click="toggleDropdown('Penalties')"
                          :aria-expanded="isDropdownActive('Penalties')"
                          data-bs-auto-close="false"
                        >
                          Penalties
                        </button>
                        <ul
                          class="dropdown-menu p-3"
                          :class="{ show: isDropdownActive('Penalties') }"
                          aria-labelledby="dropdownMenuButton7"
                        >
                          <!-- <li class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkbox1"
                                value="Zero Change fee"
                                v-model="selectedPenalties"
                              />
                              <label class="form-check-label" for="checkbox1"
                                >Zero Change fee</label
                              >
                            </li>
                            <li class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkbox2"
                                value="No Cancellation fees"
                                v-model="selectedPenalties"
                              />
                              <label class="form-check-label" for="checkbox2"
                                >No Cancellation fees</label
                              >
                            </li> -->
                          <li class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="checkbox39"
                              value="Refundable"
                              v-model="selectedPenalties"
                            />
                            <label class="form-check-label" for="checkbox39"
                              >Refundable</label
                            >
                          </li>

                          <li
                            class="mt-2"
                            @click="toggleDropdown('airlinesooo')"
                          >
                            <div class="d-flex justify-end">
                              <a class="btn btn-primary w-100 donebtn">Done</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </div>

    <div
      class="container"
      v-if="this.onewayfilterData.length > 0 || this.filterData.length > 0"
      style="max-width: 1300px !important"
    >
      <v-card
        rounded="3"
        class="pa-5 calenderSection"
        v-if="dateArray.length > 1 || dateArray1.length > 1"
      >
        <div
          :class="addOption ? 'show' : ''"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="true"
          aria-controls="collapseExample"
          v-if="this.onewayfilterData.length > 0 || this.filterData.length > 0"
          style="display: flex; justify-content: end"
        >
          <span
            style="
              color: navy;
              text-decoration: underline;
              font-size: 14px;
              font-weight: 500;
            "
            @click="addOption = !addOption"
          >
            {{ addOption ? "Hide" : "Show" }} calender
          </span>
          <span class="ms-2">
            <v-icon
              size="20"
              @click="addOption = !addOption"
              color="navy"
              :class="addOption ? 'rotate-icon' : ''"
            >
              mdi-chevron-down-box-outline</v-icon
            >
          </span>
          <!-- <span v-if="addOption">
            <v-icon size="25" @click="addOption = false" color="navy"
              >mdi-chevron-down-box-outline</v-icon
            >
          </span> -->
        </div>

        <div class="collapse show" id="collapseExample">
          <div v-if="dateArray.length > 1" class="cal-se1">
            <div class="row ps-1 gx-0 w-100" style="cursor: pointer">
              <div>
                <div class="cal-se2">
                  <div>ONWARD DATES</div>
                  <div>
                    <span>All Amount in-</span>
                    <span style="font-weight: 600">{{ recCurrecy }}</span>
                  </div>
                </div>
                <table width="auto">
                  <thead>
                    <tr>
                      <th
                        v-for="date in tempArray"
                        :key="date.Date"
                        :style="{
                          fontSize:
                            this.advanceDetailData &&
                            this.advanceDetailData.selectedDays == 7
                              ? '10px'
                              : '13px',
                        }"
                      >
                        <div class="cal-h3">
                          {{ getcalaDate(date.Date) }}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        v-for="(data, index) in dateArray"
                        :key="index"
                        :class="{
                          'nor-date2':
                            data.price ===
                            (this.selectCalenterDate.length
                              ? this.selectCalenterDate[0].price
                              : 0),
                          'nor-date': data.Date == this.datashort,
                          'spl-date': data.Date !== this.datashort,
                        }"
                        @click="
                          (this.datashort = data.Date),
                            datecalData(data, true),
                            (displayDate = true)
                        "
                      >
                        <div class="cal-se1">
                          <span v-if="data.logo !== 'mdi-magnify'">
                            <v-img :src="data.logo" class="airIconimg"></v-img>
                          </span>

                          <span v-if="data.logo === 'mdi-magnify'">
                            <v-icon size="20" color="grey">{{
                              data.logo
                            }}</v-icon>
                            <v-tooltip activator="parent" location="top"
                              >Search for the date</v-tooltip
                            >
                          </span>
                          <span>
                            <div v-if="data.price > 0" class="ms-1 priceFont">
                              {{ data.price }}
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="mt-3 cal-se1" :disabled="!displayDate">
                  <div class="calenter-head"></div>
                  <div
                    @click="this.onewayresulted = this.onewayfilterData"
                    :class="displayDate ? 'calenter-head' : 'disabled-cal'"
                  >
                    Display all dates
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="dateArray1.length > 1" class="cal-se1 flex-nowrap">
            <div
              style="
                writing-mode: vertical-lr;
                text-align: center;
                transform: rotate(180deg);
              "
            >
              ONWARD DATES
            </div>
            <div class="row ps-1 gx-0" style="cursor: pointer">
              <div style="margin: 0px 0px 10px 0px">
                <div class="cal-se2">
                  <div>RETURN DATES</div>
                  <div>
                    <span>All Amount in-</span>
                    <span style="font-weight: 600">{{ recCurrecy }}</span>
                  </div>
                </div>
                <table width="auto">
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        v-for="date in tempArray1"
                        :key="date.Date"
                        :style="{
                          fontSize:
                            this.advanceDetailData &&
                            this.advanceDetailData.selectedDays == 7
                              ? '10px'
                              : '13px',
                        }"
                      >
                        <div class="cal-h3">
                          {{ getcalaDate(date.Date) }}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(flightRow, index) in dateArray1" :key="index">
                      <th
                        :style="{
                          fontSize:
                            this.advanceDetailData &&
                            this.advanceDetailData.selectedDays == 7
                              ? '10px'
                              : '13px',
                        }"
                        class="cal-h3"
                      >
                        {{ getcalaDate(tempArray[index].Date) }}
                      </th>
                      <td
                        v-for="(flight, idx) in flightRow.flights"
                        :key="idx"
                        :class="{
                          'nor-date1': flight.clickValue,
                          'nor-date2':
                            flight.price ===
                            (this.selectCalenterDate.length
                              ? this.selectCalenterDate[0].price
                              : 0),
                        }"
                        @click="
                          datecalData1(flight, true), (displayDate = true)
                        "
                      >
                        <div class="cal-se1">
                          <span v-if="flight.logo !== 'mdi-magnify'">
                            <v-img
                              :src="flight.logo"
                              class="airIconimg"
                            ></v-img>
                          </span>

                          <span v-if="flight.logo === 'mdi-magnify'">
                            <v-icon size="20" color="grey">{{
                              flight.logo
                            }}</v-icon>
                            <v-tooltip activator="parent" location="top"
                              >Search for the date</v-tooltip
                            >
                          </span>

                          <span>
                            <div v-if="flight.price > 0" class="ms-1 priceFont">
                              {{ flight.price }}
                              <v-tooltip activator="parent" location="top"
                                >{{ recCurrecy }}- {{ flight.price }}</v-tooltip
                              >
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  class="mt-3 cal-se1"
                  :disabled="displayDate ? false : true"
                >
                  <div class="calenter-head"></div>
                  <div
                    @click="this.rountresult = this.filterData"
                    :class="displayDate ? 'calenter-head' : 'disabled-cal'"
                  >
                    Display all dates
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <div
      class="container"
      v-if="flight1Date.length > 1"
      style="max-width: 1300px !important"
    >
      <v-card rounded="3" class="pa-5">
        <div v-if="flight1Date.length > 1">
          <div class="pa-2">Departure</div>
          <div class="row ps-1 gx-0">
            <div
              class="col-1"
              v-for="(data, index) of flight1Date"
              :key="index"
            >
              <div
                :class="data === this.flightDate1 ? 'nor-date' : 'spl-date'"
                @click="(this.flightDate1 = data), datecalMultiData()"
              >
                {{ data }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="flight2Date.length > 1">
          <div class="pa-2">Return</div>
          <div class="row ps-1 gx-0">
            <div
              class="col-1"
              v-for="(data, index) of flight2Date"
              :key="index"
            >
              <div
                :class="data === this.flightDate2 ? 'nor-date' : 'spl-date'"
                @click="(flightDate2 = data), datecalMultiData()"
              >
                {{ data }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="flight3Date.length > 1">
          <div class="pa-2">Return</div>
          <div class="row ps-1 gx-0">
            <div
              class="col-1"
              v-for="(data, index) of flight3Date"
              :key="index"
            >
              <div
                :class="data === this.flightDate3 ? 'nor-date' : 'spl-date'"
                @click="(flightDate3 = data), datecalMultiData()"
              >
                {{ data }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="flight4Date.length > 1">
          <div class="pa-2">Return</div>
          <div class="row ps-1 gx-0">
            <div
              class="col-1"
              v-for="(data, index) of flight4Date"
              :key="index"
            >
              <div
                :class="data === this.flightDate4 ? 'nor-date' : 'spl-date'"
                @click="(flightDate4 = data), datecalMultiData()"
              >
                {{ data }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <!-- <div class="container mt-5">
    <v-card rounded="0" style="position: inherit">
      <div class="air-title">
        <h6 style="margin-left: 15px">Pick your preferred Airline</h6>
      </div>
      <div class="row mt-3 ms-2">
        <div class="col-md-4">
          <p>Compare and Choose preffered Airlines</p>
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              Non Stop
            </v-btn>
          </div>
          <br />
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              1+ Stops
            </v-btn>
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <v-card class="cart1">

            <h6 class="mt-2 ms-2">Airline</h6>
            <hr>
            <p>USD 100</p>
            <hr />
            <p>USD 100</p>
          </v-card>
        </div>
      </div>
      <p class="ms-3 mt-2" style="font-size: 13px; font-weight: 500">
        Fares incl. taxes & fees. Airfares include an applied Booking Bonus.
        Additional baggage fees may apply. Flights displayed may be for
        alternate dates and/or airports. Certain results may be outside your
        search criteria.
      </p>
      <br />
    </v-card>
  </div> -->

    <div class="container mt-2" style="max-width: 1300px !important">
      <div class="row" v-if="filterView">
        <div class="col-lg-3 mb-2 mb-lg-0">
          <v-card rounded="3">
            <div
              style="
                background-color: #faa41f;
                padding: 3px;
                border-radius: 10px 10px 0px 0px;
              "
            >
              <div class="row p-2 align-center" style="color: white">
                <div class="col-6">
                  <div>
                    <v-icon size="20px"> mdi-filter </v-icon><span>|</span>
                    <span style="font-size: 16px; font-weight: 500">
                      Filter By</span
                    >
                  </div>
                </div>
                <div class="col-4" align="center">
                  <!-- <div>Reset All</div> -->
                </div>
                <div class="col-2 filter-toggle" align="center">
                  <v-icon
                    size="30"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    @click="isIcon = !isIcon"
                    style="overflow-anchor: none"
                    >{{
                      isIcon
                        ? "mdi-chevron-up-circle-outline"
                        : "mdi-chevron-down-circle-outline"
                    }}</v-icon
                  >
                </div>
              </div>
            </div>

            <div
              class="filter_icons"
              style="padding: 10px"
              :class="{ collapse: !isIcon, show: isIcon }"
              aria-expanded="false"
              :id="{ 'collapse-Example': isIcon }"
            >
              <div>
                <div class="row gy-0">
                  <div class="col-8">
                    <v-checkbox
                      label="Non Stop"
                      v-model="NonStopFil"
                      :disabled="showNonStop ? false : true"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox
                      :disabled="showOneStop ? false : true"
                      label="1 Stop"
                      v-model="OneStopFil"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox
                      :disabled="showOneplusStop ? false : true"
                      label="1+ Stop"
                      v-model="OnePlusFil"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <!-- <hr />
                <div class="stop mb-3">Baggage</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox label="Baggage Inclusive" color="primary"></v-checkbox>
                  </div>
                  <div class="col-4" align="center">
                  <div>(100)</div>
                </div>
                </div> -->

                <hr />
                <div class="stop">Fare Type</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox
                      label="Refundable"
                      color="primary"
                      v-model="RefundableData"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox
                      label="Non Refundable"
                      color="primary"
                      v-model="nonRefundableData"
                    ></v-checkbox>
                  </div>
                </div>

                <hr />
                <div class="stop">
                  Price Range
                  <span style="font-size: 13px"> (Avg.per pax)</span>
                </div>
                <div class="row me-1">
                  <v-range-slider
                    v-model="changevalue"
                    :min="minimum"
                    :max="maximum"
                    :step="steps"
                    thumb-label
                    class="price_slider"
                    color="#0c4ca3"
                  ></v-range-slider>
                </div>
                <div class="row">
                  <div class="col-6" align="center">
                    <p>{{ currencyCode }} {{ changevalue[0] }}</p>
                  </div>
                  <div class="col-6" align="center">
                    <p>{{ currencyCode }} {{ changevalue[1] }}</p>
                  </div>
                </div>

                <hr />

                <div class="my-2" v-if="this.onedatafil || this.rounddatafil">
                  <h5>Onward Journey</h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Departure From {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilter1()">
                      <v-card
                        :color="this.timeFilterData1 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="this.timeFilterData1 ? '#faa41f' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter2()">
                      <v-card
                        :color="this.timeFilterData2 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="this.timeFilterData2 ? '#faa41f' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter3()">
                      <v-card
                        :color="this.timeFilterData3 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="this.timeFilterData3 ? '#faa41f' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Arrival at {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilterarr1()">
                      <v-card
                        :color="this.timeFilterArrData1 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData1 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterarr2()">
                      <v-card
                        :color="this.timeFilterArrData2 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData2 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterarr3()">
                      <v-card
                        :color="this.timeFilterArrData3 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData3 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="my-2" v-if="this.rounddatafil">
                  <h5>Return Journey</h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Departure From {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilter1()">
                      <v-card
                        :color="this.timearrFilterData1 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterData1 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter2()">
                      <v-card
                        :color="this.timearrFilterData2 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterData2 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter3()">
                      <v-card
                        :color="this.timearrFilterData3 ? '#e3e3e3' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterData3 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      Arrival at {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilterarr1()">
                      <v-card
                        :color="
                          this.timearrFilterArrData1 ? '#e3e3e3' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterArrData1 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr2()">
                      <v-card
                        :color="
                          this.timearrFilterArrData2 ? '#e3e3e3' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterArrData2 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr3()">
                      <v-card
                        :color="
                          this.timearrFilterArrData3 ? '#e3e3e3' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-2">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timearrFilterArrData3 ? '#faa41f' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div
                  class="stop"
                  v-if="this.multicityData.length > 0 ? false : true"
                >
                  Airlines
                </div>
                <div
                  class="row mb-4"
                  style="font-size: 14px; cursor: pointer"
                  v-if="this.multicityData.length > 0 ? false : true"
                >
                  <div class="col-md-6" @click="airlineSelect()">
                    Select All
                  </div>
                  <div class="col-md-6" @click="airlineClear()">Clear All</div>
                </div>

                <div
                  class="row"
                  v-for="(data, index) of airlineList.slice(0, maxItemsToShow)"
                  :key="index"
                >
                  <div class="col-8">
                    <v-checkbox
                      class="text-truncate"
                      :label="data.airline"
                      color="primary"
                      v-model="data.active"
                      @click="selectAirline(data, index)"
                    >
                    </v-checkbox>
                  </div>
                </div>

                <div v-if="airlineList.length > 5">
                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="!readMore"
                  >
                    ( +{{ airlineList.length - maxItemsToShow }} More )
                  </p>

                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="readMore"
                  >
                    ( Show less )
                  </p>
                </div>
                <br />
              </div>
            </div>
          </v-card>
        </div>
        <div class="col-lg-9">
          <v-card
            class="row gx-0"
            style="cursor: pointer; box-shadow: none"
            v-if="false"
          >
            <div class="pa-3">
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(data, index) of selectCalenterDate"
                  :key="index"
                >
                  <div
                    v-if="this.onewayfilterData.length > 0"
                    @click="datecalData(data, false, index)"
                    outlined
                    :class="data.valueData ? 'calSelect1' : 'calSelect'"
                  >
                    <div>{{ getcalaDate(data.Date) }}</div>
                    <div style="font-weight: 500">{{ data.price }}</div>
                  </div>

                  <div
                    v-if="this.filterData.length > 0"
                    @click="datecalData1(data, false, index)"
                    outlined
                    :class="data.valueData ? 'calSelect1' : 'calSelect'"
                  >
                    <div>{{ getcalaDate(data.depdate) }}</div>
                    <div style="font-weight: 500">{{ data.price }}</div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>

          <v-card
            class="row mb-3 mt-3 gx-0"
            style="cursor: pointer; box-shadow: none"
          >
            <div
              class="col-md-3 col-6"
              style="
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              "
              :class="active ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Recommend()"
              >
                <div>
                  <v-icon
                    :style="active ? 'color:#faa41f' : 'color:grey'"
                    size="40"
                    >mdi-seal</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Recommended</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active1 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="bestlayTime(1)"
              >
                <div>
                  <v-icon
                    :style="active1 ? 'color:#faa41f' : 'color:grey'"
                    size="40"
                    >mdi-star</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Best</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ layperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active2 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Cheapest(1)"
              >
                <div>
                  <v-icon
                    :style="active2 ? 'color:#faa41f' : 'color:grey'"
                    size="40"
                    >mdi-currency-usd</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Cheapest</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active3 ? 'recment' : 'normal-rec'"
              style="
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Shortest(1)"
              >
                <div>
                  <v-icon
                    :style="active3 ? 'color:#faa41f' : 'color:grey'"
                    size="40"
                    >mdi-clock</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Shortest</div>
                  <div class="top_filterprice">
                    {{ shorCurrecy }} {{ shotperperson }}
                  </div>
                </div>
              </div>
            </div>
          </v-card>

          <div class="mb-3" v-for="(res, index) of rountresult" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                  style="width: 15%"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <!-- <br />
                      {{ getid(res.Depature) }} -
                      {{ getNumber(res.Depature) }} -->
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="me-sm-auto m-auto">
                          <span class="f-size-14 fw-500">Total Fare:</span>
                        </div>
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                class="f-size-20 position-relative"
                                style="left: 2px; top: -3px"
                                >.</span
                              >
                              <span
                                id="f1"
                                class="f-size-18 fw-600"
                                style="margin-bottom: 10px; margin-left: 2px"
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        color="#faa41f"
                        width="100px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-7 Timing-section"
                  style="width: 65%; padding: 10px 5px"
                >
                  <div class="mt-2">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radioData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>

                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px; width: 100%"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getAirportCode(res.Depature) }} -->
                                {{ getCountryName1(tripData.from) }}
                              </p>
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getTime(res.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          totalTime(res.layoverTimes1)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          res.layoverTimes1[0]
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          res.layoverTimes1[1]
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getArrivalTime(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getArrivalCode(res.Depature) }} -->
                                {{ getCountryName2(tripData.to) }}
                              </p>
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameTo(res.Return) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-start"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>

                      <div v-if="res.multipleDatas">
                        <div
                          v-for="(data, multindex) of multiDepatureData"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multione(data, multindex, index)">
                              <v-radio-group v-model="data.radios">
                                <v-radio :value="true"></v-radio>
                              </v-radio-group>
                            </div>
                            <div style="width: 100%; border-radius: 10px">
                              <div class="row p-2">
                                <div
                                  class="col-md-3"
                                  style="border-right: 1px dotted gray"
                                >
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName1(tripData.from) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getAirportNameFrom(data.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-6 col-8"
                                  style="border-right: 1px dotted gray"
                                >
                                  <div class="row g-0 mt-1 justify-center">
                                    <div class="col-md-4 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getDate(data.Depature) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getDateDeparture(data.Depature) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getTime(data.Depature) }}
                                      </p>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops == 0"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        Direct
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                      >
                                        <hr
                                          style="
                                            width: 87%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops == 1"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Depaturejourney.Stops }} Stop
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Depature.length >= 2"
                                          class="hoverData1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Depature)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops >= 2"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Depaturejourney.Stops }} Stops
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Depature.length >= 2"
                                          class="hoverDataStop1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Depature)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 15%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails2(data, index)"
                                          @mouseout="hideDetails2(data)"
                                          >mdi-circle</v-icon
                                        >
                                        <div
                                          v-if="data.Depature.length >= 3"
                                          class="hoverDataStop2"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName2,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              get1arrtime(data.Depature, index)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              get2arrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getArrivaldate(data.Depature) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{
                                          getArrivaldateReturn(data.Depature)
                                        }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getArrivalTime(data.Depature) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName2(tripData.to) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getretairname(data.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>

                    <br />

                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radiretData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>

                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px; width: 100%"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getreturnAirportCode(res.Return) }} -->
                                {{ getCountryName2(tripData.to) }}
                              </p>

                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameTo(res.Return) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getreturnAirportCode(res.Return) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getreturnDate(res.Return) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getreturnDateDeparture(res.Return) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getreturnTime(res.Return) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>
                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Returnjourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName3 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          totalTime(res.layoverTimes2)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Returnjourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName3 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          res.layoverTimes2[0]
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails4(res, index)"
                                      @mouseout="hideDetails4(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName4 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1returntime(res.Return, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          res.layoverTimes2[1]
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getreturnArrivaldate(res.Return) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getreturnArrivaldateReturn(res.Return) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getreturnArrivalTime(res.Return) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="res-time"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getreturnArrivalcode(res.Return) }} -->
                                {{ getCountryName1(tripData.from) }}
                              </p>

                              <p
                                class="res-time"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="res-time text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getreturnArrivalcode(res.Return) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>
                      <div v-if="res.multipleDatas">
                        <div
                          v-for="(data, multindex) of multireturnData"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multiredone(data, multindex, index)">
                              <v-radio-group v-model="data.radiosret">
                                <v-radio :value="true"></v-radio>
                              </v-radio-group>
                            </div>
                            <div style="width: 100%; border-radius: 10px">
                              <div class="row p-2">
                                <div
                                  class="col-md-3"
                                  style="border-right: 1px dotted gray"
                                >
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName1(tripData.to) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getAirportNameFrom(data.Return) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-6 col-8"
                                  style="border-right: 1px dotted gray"
                                >
                                  <div class="row g-0 mt-1 justify-center">
                                    <div class="col-md-4 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getDate(data.Return) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getDateDeparture(data.Return) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getTime(data.Return) }}
                                      </p>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops == 0"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        Direct
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                      >
                                        <hr
                                          style="
                                            width: 87%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops == 1"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Returnjourney.Stops }} Stop
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Return.length >= 2"
                                          class="hoverData1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Return)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops >= 2"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Returnjourney.Stops }} Stops
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Return.length >= 2"
                                          class="hoverDataStop1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Return)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 15%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails2(data, index)"
                                          @mouseout="hideDetails2(data)"
                                          >mdi-circle</v-icon
                                        >
                                        <div
                                          v-if="data.Return.length >= 3"
                                          class="hoverDataStop2"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName2,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              get1arrtime(data.Return, index)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              get2arrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getArrivaldate(data.Return) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getArrivaldateReturn(data.Return) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 400;
                                          font-size: 18px;
                                        "
                                      >
                                        {{ getArrivalTime(data.Return) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName2(tripData.from) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getretairname(data.Return) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0">
                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex justify-center align-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        this.oneway.class
                      }}</span>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-sm-center"
                      @click="flighted1(res, index)"
                    >
                      <span
                        style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        "
                      >
                        More Details</span
                      >

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>
                    <!-- <div class="col-md-3 col-4" @click="addmoreroundData(res, index)" style="font-size: 12px">
                      {{ res.multi }}
                    </div> -->
                    <div
                      class="col-md-3 col-4 text-center"
                      @click="getBaggage(res, index)"
                      style="
                        color: #36c;
                        font-size: 12px;
                        margin-top: 2px;
                        cursor: pointer;
                      "
                    >
                      Fare Rules
                    </div>

                    <div class="text-center col-6 fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p class="m-0 f-size-15 fw-500">Total Price</p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span class="f-size-13 fw-500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div class="col-6 f-size-14 fw-400 text-end">
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6 f-size-14">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div class="col-6 f-size-14 text-end">
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-3 d-flex flex-column align-center justify-content-between select-section-hide"
                  style="
                    width: 20%;
                    background-color: #e3e3e3;
                    border-radius: 0px 10px 10px 0px !important;
                  "
                >
                  <div
                    class="d-flex flex-column align-center justify-center mt-2"
                  >
                    <div class="me-auto">
                      <span class="f-size-14 fw-500">Total Fare:</span>
                    </div>
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            class="f-size-20 position-relative"
                            style="left: 2px; top: 2px"
                            >.</span
                          >
                          <span
                            id="f1"
                            class="f-size-18 fw-600"
                            style="
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <span
                      class="f-size-14 fw-500"
                      style="margin-top: -10px; margin-bottom: 10px"
                      >Per Person</span
                    > -->
                    <v-btn
                      color="#faa41f"
                      width="100px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >Book</v-btn
                    >
                  </div>
                  <!-- <div class="text-center Fare-overlay">
                    <a :href="res.deeplinkurl">Deeplink URL</a>
                  </div> -->

                  <div class="text-center f-size-14 fw-500">
                    <div class="text-center mb-3">
                      <span class="m-0">Comm:</span>
                      <span
                        >{{ res.currency }}
                        {{
                          res.CommissionDetails.final_commission_amount
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <v-card v-if="res.Flightdepature">
                  <v-tabs v-model="res.tabSelect" bg-color="primary">
                    <v-tab value="1">Flight Details</v-tab>
                    <v-tab value="2">Fare Details</v-tab>
                  </v-tabs>

                  <v-card-text class="p-0">
                    <v-tabs-window v-model="res.tabSelect">
                      <div v-if="res.tabSelect == 1">
                        <div class="col-md-12 col-12">
                          <v-card v-if="res.Flightdepature">
                            <div v-if="res.Flightdepature">
                              <div
                                class="d-flex p-2 justify-content-between"
                                style="background-color: #e8f0fd"
                              >
                                <div class="d-flex align-center">
                                  <v-icon
                                    style="transform: rotate(90deg) !important"
                                    >mdi-airplane</v-icon
                                  >
                                  <span class="f-size-15 fw-500 ps-2"
                                    >{{ getAirportCode(res.Depature) }} -
                                    {{ getArrivalCode(res.Depature) }}</span
                                  >
                                </div>

                                <div class="d-flex align-center justify-end">
                                  <span class="f-size-15 fw-500"
                                    >Total Trip Duration :
                                    {{
                                      flightdetailsData.Depaturejourney.Time
                                    }}</span
                                  >
                                  <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                                </div>
                              </div>

                              <div class="px-2" style="max-width: 100%">
                                <div
                                  v-for="(
                                    data, index
                                  ) of flightdetailsData.Depature"
                                  :key="index"
                                >
                                  <div class="row mt-3 mb-3">
                                    <div class="col-md-3 col-3 mb-3 mb-md-0">
                                      <div class="row ms-sm-1 ms-0 w-100">
                                        <div
                                          class="col-md-4 col-12 airline-logo"
                                        >
                                          <v-img
                                            :src="data.MarketingCarrier.logo"
                                            width="30px"
                                          ></v-img>
                                        </div>
                                        <div
                                          class="col-md-8 col-12 airline-profile"
                                        >
                                          <p class="f-size-13 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="col-md-7 col-9">
                                      <div class="row d-flex">
                                        <div class="col-sm-3 p-0">
                                          <span
                                            class="f-size-10 airline-date"
                                            >{{
                                              getdated(data.Departure.Date)
                                            }}</span
                                          >
                                          <span
                                            class="f-size-13 airline-time"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                        <div
                                          class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                          style="margin-bottom: 4px"
                                        >
                                          <span class="fw-500"
                                            >{{
                                              data.Departure.AirportCode
                                            }} </span
                                          >, {{ data.Departure.AirportName }}
                                        </div>
                                      </div>

                                      <div class="row d-flex">
                                        <div class="col-sm-3 p-0">
                                          <span
                                            class="f-size-10 airline-date"
                                            >{{
                                              getdated1(data.Arrival.Date)
                                            }}</span
                                          >
                                          <span
                                            class="f-size-13 airline-time"
                                            style="margin-left: 5px"
                                            >{{ getflightarr(data) }}</span
                                          >
                                        </div>

                                        <div
                                          class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                          style="margin-bottom: 4px"
                                        >
                                          <span class="fw-500"
                                            >{{
                                              data.Arrival.AirportCode
                                            }} </span
                                          >, {{ data.Arrival.AirportName }}
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                    >
                                      <div class="d-flex class-type">
                                        <v-icon
                                          size="15px"
                                          color="#ccc"
                                          class="mt-1"
                                        >
                                          mdi-seat-passenger
                                        </v-icon>

                                        <span
                                          class="f-size-12"
                                          style="padding-left: 5px"
                                        >
                                          {{ oneway.class }}
                                        </span>
                                      </div>
                                      <div class="d-flex f-size-12">
                                        {{ data.Code.ResBookDesigCode }}/{{
                                          data.FareBasisCode
                                        }}
                                      </div>

                                      <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                          <v-icon size="15px" color="#ccc">
                                            mdi-food-fork-drink
                                          </v-icon>
                                        
                                          <span class="f-size-12" style="padding-left: 5px">
                                            Meal provided
                                          </span>
                                        </div> -->
                                    </div>

                                    <div class="d-flex position-relative mt-3">
                                      <hr
                                        v-if="res.Depature.length - 1 !== index"
                                        style="
                                          width: 100%;
                                          position: absolute;
                                          margin-top: 8px !important;
                                          margin-bottom: 0px !important;
                                        "
                                      />
                                      <div
                                        class="d-flex justify-center"
                                        v-if="res.Depature.length - 1 !== index"
                                        style="
                                          width: 180px;
                                          background: antiquewhite;
                                          border-radius: 18px;
                                          z-index: 1;
                                          margin: auto;
                                        "
                                      >
                                        <div class="d-flex align-center">
                                          <span class="f-size-10"
                                            >Layover
                                            {{
                                              flightdetailsData.layoverTimes1[
                                                index
                                              ]
                                            }}
                                            ({{
                                              data.Arrival.AirportCode
                                            }})</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="res.Flightdepature">
                              <div class="mt-2">
                                <div
                                  class="d-flex p-2 justify-content-between"
                                  style="background-color: #e8f0fd"
                                >
                                  <div class="d-flex align-center">
                                    <v-icon
                                      style="
                                        transform: rotate(-90deg) !important;
                                      "
                                      >mdi-airplane</v-icon
                                    >
                                    <span class="f-size-15 fw-500 ps-2"
                                      >{{ getreturnAirportCode(res.Return) }} -
                                      {{
                                        getreturnArrivalcode(res.Return)
                                      }}</span
                                    >
                                  </div>

                                  <div class="d-flex align-center justify-end">
                                    <span class="f-size-15 fw-500"
                                      >Total Trip Duration :
                                      {{
                                        flightdetailsData.Returnjourney.Time
                                      }}</span
                                    >
                                    <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                                  </div>
                                </div>

                                <div class="px-2" style="max-width: 100%">
                                  <div
                                    v-for="(
                                      data, index
                                    ) of flightdetailsData.Return"
                                    :key="index"
                                  >
                                    <div class="row mt-3 mb-3">
                                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                                        <div class="row ms-sm-1 ms-0 w-100">
                                          <div
                                            class="col-md-4 col-12 airline-logo"
                                          >
                                            <v-img
                                              :src="data.MarketingCarrier.logo"
                                              width="30px"
                                            ></v-img>
                                          </div>
                                          <div
                                            class="col-md-8 col-12 airline-profile"
                                          >
                                            <p
                                              class="f-size-12 airline-name m-0"
                                            >
                                              {{ data.MarketingCarrier.Name }}

                                              <br />
                                              <span
                                                class="f-size-12 airline-id"
                                              >
                                                {{
                                                  data.MarketingCarrier
                                                    .AirlineID
                                                }}
                                                -
                                                {{
                                                  data.MarketingCarrier
                                                    .FlightNumber
                                                }}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-md-7 col-9">
                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated2(data.Departure.Date)
                                              }}</span
                                            >

                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightdep1(data) }}</span
                                            >
                                          </div>
                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Departure.AirportCode
                                              }} </span
                                            >,
                                            {{ data.Departure.AirportName }}
                                          </div>
                                        </div>

                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated3(data.Arrival.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{
                                                getflightarrival(data)
                                              }}</span
                                            >
                                          </div>

                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500">{{
                                              data.Arrival.AirportCode
                                            }}</span>
                                            , {{ data.Arrival.AirportName }}
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                      >
                                        <div class="d-flex class-type">
                                          <v-icon
                                            size="15px"
                                            color="#ccc"
                                            class="mt-1"
                                          >
                                            mdi-seat-passenger
                                          </v-icon>

                                          <span
                                            class="f-size-12"
                                            style="padding-left: 5px"
                                          >
                                            {{ oneway.class }}
                                          </span>
                                        </div>
                                        <div class="d-flex f-size-12">
                                          {{ data.Code.ResBookDesigCode }}/{{
                                            data.FareBasisCode
                                          }}
                                        </div>

                                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                          <v-icon size="15px" color="#ccc">
                                            mdi-food-fork-drink
                                          </v-icon>
                                        
                                          <span class="f-size-12" style="padding-left: 5px">
                                            Meal provided
                                          </span>
                                        </div> -->
                                      </div>
                                    </div>

                                    <div class="d-flex position-relative mt-3">
                                      <hr
                                        v-if="index !== res.Return.length - 1"
                                        style="
                                          width: 100%;
                                          position: absolute;
                                          margin-top: 8px !important;
                                          margin-bottom: 0px !important;
                                        "
                                      />
                                      <div
                                        class="d-flex justify-center"
                                        v-if="index !== res.Return.length - 1"
                                        style="
                                          width: 180px;
                                          background: antiquewhite;
                                          border-radius: 18px;
                                          z-index: 1;
                                          margin: auto;
                                        "
                                      >
                                        <div class="d-flex align-center">
                                          <span class="f-size-10"
                                            >Layover
                                            {{
                                              flightdetailsData.layoverTimes2[
                                                index
                                              ]
                                            }}
                                            ({{
                                              data.Arrival.AirportCode
                                            }})</span
                                          >
                                        </div>
                                      </div>
                                    </div>

                                    <!-- <div class="d-flex justify-content-between align-center">
                                      <hr v-if="index !== res.Return.length - 1" style="width: 40%" class="mt-0 mb-0" />
                                      <div class="d-flex justify-center mx-2 layover-size" v-if="index !== res.Return.length - 1"
                                        style="width: 20%">
                                        <div class="d-flex align-center">
                                          <span class="f-size-11 lay-fsize">
                                            {{ data.layoverTimes }} Layover
                                            Time</span>
                                        </div>
                                      </div>
                                      <hr v-if="index !== res.Return.length - 1" style="width: 40%" class="mt-0 mb-0" />
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                      <div v-if="res.tabSelect == 2">
                        <div>
                          <div
                            class="d-flex p-2 d-none"
                            style="background-color: #e8f0fd"
                          >
                            <div class="d-flex align-center invisible">
                              <v-img
                                :src="require('@/assets/icons/fareIcon.png')"
                                width="18px"
                              ></v-img>
                              <span class="f-size-15 fw-500 ps-2"
                                >Fare Details</span
                              >
                            </div>
                          </div>

                          <div class="row pa-3 comDetailSection">
                            <div
                              class="col-lg-3 priceSection"
                              style="width: 30%"
                            >
                              <table class="table">
                                <thead>
                                  <tr class="f-size-14 fw-500">
                                    <td>Pax</td>
                                    <td>Base</td>
                                    <td>Tax</td>
                                  </tr>
                                </thead>
                                <tbody class="f-size-14 fw-400">
                                  <tr
                                    v-if="
                                      res.priceDetails.adultPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.childPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.infPriceDetails.baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <table class="table mb-0">
                                <thead>
                                  <tr>
                                    <td colspan="3" class="f-size-14 fw-500">
                                      Passenger(s)
                                    </td>
                                  </tr>
                                </thead>
                                <tbody class="f-size-14 fw-400">
                                  <tr
                                    v-if="
                                      res.priceDetails.adultPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .adultcount
                                      }}
                                    </td>
                                    <td>
                                      {{ getadultTotBase(res.priceDetails) }}
                                    </td>
                                    <!-- <td>{{(res.priceDetails.adultPriceDetails.baseprice) * (res.priceDetails.adultPriceDetails.adultcount) }}</td> -->
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.childPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .childcount
                                      }}
                                    </td>
                                    <td>
                                      {{ getchildTotBase(res.priceDetails) }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.infPriceDetails.baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .infcount
                                      }}
                                    </td>
                                    <td>
                                      {{ getInfTotBase(res.priceDetails) }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <div class="mt-2">
                                <table
                                  class="table table-borderless f-size-14 fw-500 mb-0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>Base Price</td>
                                      <td>
                                        {{ res.currency }} {{ res.Baseprice }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Taxes & Fees</td>
                                      <td>
                                        {{ res.currency }} {{ res.Taxprice }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ res.Gds }}-{{ res.sourcePcc }}/{{
                                          res.fareType
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <!-- <div class="d-flex justify-content-between fw-500">
                                  <div>
                                    <p class="m-0" style="font-size: 18px; font-weight: 500">
                                      Total Price
                                    </p>
                                  </div>
                                  <div>
                                    <p class="tot-price m-0">
                                      {{ res.currency }}
                                      {{ res.TotalPrice }}
                                    </p>
                                  </div>
                                </div> -->

                                <!-- <div class="row mt-2">
                                  <div class="col-md-12">
                                    <div class="row justify-content-between fw-500">
                                      <div class="col-6">
                                        <span class="f-size-14 fw-500">Passengers</span>
                                      </div>
                                      <div class="col-6 f-size-14 text-end">
                                        <span v-if="aduls > 0">{{ aduls }} x Adult</span><br v-if="aduls1 > 0" />
                                        <span v-if="aduls1 > 0">{{ aduls1 }} x Child</span><br v-if="aduls2 > 0" />
                                        <span v-if="aduls2 > 0">{{ aduls2 }} x Lap Infant</span>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->

                                <!-- <div class="p-1 collapse" :class="{ show: fareIcon }" id="collapse-Example">
                                    <div class="row fw-500">
                                      <div class="col-6 px-2" style="font-size: 14px">
                                        Base Price<br />
                                        Taxes & Fees
                                      </div>
                                      <div class="col-6 px-2" style="font-size: 14px; text-align: end">
                                        {{ res.currency }}
                                        {{ res.Baseprice }}<br />
                                        {{ res.currency }}
                                        {{ res.Taxprice }}
                                      </div>
                                    </div>
                                  </div> -->
                              </div>
                            </div>

                            <div
                              class="col-lg-3 border-start border-end border-1"
                              style="width: 20%"
                            >
                              <div class="mb-2">
                                <p class="f-size-14 fw-500 m-0">Total Price:</p>
                                <span class="fw-500"
                                  >{{ res.currency }} {{ res.TotalPrice }}</span
                                >
                              </div>
                              <div class="mb-2">
                                <p class="f-size-14 fw-500 m-0">
                                  Total Commission:
                                </p>
                                <span class="fw-500"
                                  >{{ res.currency }}
                                  {{
                                    res.CommissionDetails
                                      .total_commission_amount
                                  }}</span
                                >
                              </div>

                              <div class="mb-3">
                                <p class="f-size-14 fw-500 m-0">
                                  Agent Commission:
                                </p>
                                <span class="fw-500"
                                  >{{ res.currency }}
                                  {{
                                    res.CommissionDetails
                                      .final_commission_amount
                                  }}</span
                                >
                              </div>
                            </div>

                            <div class="col-lg-6 calculationAgent">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row d-flex mb-2">
                                    <div
                                      class="col-lg-6"
                                      style="width: fit-content"
                                    >
                                      <p class="m-0 fw-500">
                                        Agent Markup/Discount
                                      </p>
                                      <div class="mt-1">
                                        <v-radio-group
                                          v-model="res.instandradio"
                                          inline
                                          class="f-size-12"
                                        >
                                          <div class="d-flex align-center">
                                            <v-radio value="markup"></v-radio>
                                            <span
                                              class="f-size-14 fw-500 text-grey me-3"
                                              >Mark Up</span
                                            >
                                          </div>
                                          <div class="d-flex align-center">
                                            <v-radio value="discount"></v-radio>
                                            <span
                                              class="f-size-14 fw-500 text-grey"
                                              >Discount</span
                                            >
                                          </div>
                                        </v-radio-group>
                                      </div>
                                    </div>
                                    <div
                                      class="col-lg-6 comPart"
                                      style="width: 55%"
                                    >
                                      <label
                                        for="exampleInputCardName"
                                        class="form-label fw-500"
                                        >Markup Amount</label
                                      >
                                      <div
                                        class="d-flex input-group position-relative"
                                      >
                                        <span
                                          class="input-group-text rounded"
                                          style="
                                            height: fit-content;
                                            padding: 3px 6px;
                                            width: 120px;
                                          "
                                          >{{ displayCurrency }}
                                          {{ res.tempInstantValue }}</span
                                        >
                                        <v-menu
                                          v-model="res.markupInput"
                                          :close-on-content-click="false"
                                          persistent
                                        >
                                          <template
                                            v-slot:activator="{ props }"
                                          >
                                            <v-btn
                                              height="30px"
                                              v-bind="props"
                                              :disabled="!props ? true : false"
                                              color="#faa41f"
                                              class="ms-1 rounded"
                                              style="
                                                min-width: 36px;
                                                padding: 0 5px !important;
                                              "
                                            >
                                              <v-icon color="white"
                                                >mdi-pencil</v-icon
                                              >
                                            </v-btn>
                                          </template>

                                          <v-card
                                            max-width="250"
                                            class="p-2 pb-0"
                                          >
                                            <v-form
                                              ref="commissionform"
                                              v-model="res.validMarkupform"
                                              lazy-validation
                                              @submit.prevent="
                                                submitCommission(res, index)
                                              "
                                            >
                                              <p class="m-0 f-size-14 fw-500">
                                                Enter Amount
                                              </p>
                                              <div
                                                class="d-flex input-group position-relative comPart"
                                              >
                                                <span
                                                  class="input-group-text"
                                                  style="
                                                    height: 32px;
                                                    padding: 3px 6px;
                                                  "
                                                >
                                                  {{ displayCurrency }}</span
                                                >

                                                <v-text-field
                                                  v-model="res.instantvalue"
                                                  type="text"
                                                  placeholder="00.0"
                                                  :rules="[
                                                    (v) =>
                                                      !!v || 'Enter Amount',
                                                  ]"
                                                  @keypress="filterNumber"
                                                  @keyup="
                                                    handleKeyup(res, index)
                                                  "
                                                  style="width: 100px"
                                                ></v-text-field>

                                                <v-btn
                                                  icon
                                                  type="submit"
                                                  height="30px"
                                                  :disabled="res.disabledBtn"
                                                  color="#faa41f"
                                                  class="ms-1 rounded"
                                                >
                                                  <v-icon
                                                    v-if="!res.calcLoader"
                                                    color="white"
                                                    >mdi-cached</v-icon
                                                  >
                                                  <v-progress-circular
                                                    v-if="res.calcLoader"
                                                    :width="3"
                                                    :size="20"
                                                    color="white"
                                                    indeterminate
                                                  ></v-progress-circular>
                                                </v-btn>
                                              </div>
                                            </v-form>
                                          </v-card>
                                        </v-menu>
                                        <span class="ms-2 d-flex align-center">
                                          <img :src="require('../assets/icons/booking.png')" style="width:26px;height:25px;" @click.prevent="selected(res, index)"/>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12 d-flex px-0">
                                  <table class="table table-bordered mb-0">
                                    <thead>
                                      <tr>
                                        <th style="background-color: #e8f0fd;font-weight: 500;font-size: 15px;">
                                          Fare
                                        </th>
                                        <th style="background-color: #e8f0fd;font-weight: 500;font-size: 15px;">
                                          Agent Commission
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {{ res.currency }}
                                          {{ res.getTotalfare }}
                                        </td>
                                        <td>
                                          {{ res.currency }}
                                          {{ res.getAgentCommission }}
                                        </td>
                                        <!-- <td>{{ res.currency }} {{ gatCalcTotal() }}</td>
                                          <td>{{ res.currency }} {{ getCalcCommission() }}</td> -->
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div class="row d-none">
                                    <div class="col-3 mb-2">
                                      <p class="m-0">
                                        <span class="f-size-18 fw-500"
                                          >Fare:</span
                                        >
                                        <span class="f-size-14 fw-500"
                                          >{{ res.currency }} 00.00</span
                                        >
                                      </p>
                                    </div>

                                    <div class="col-3 mb-3">
                                      <p class="m-0">
                                        <span class="f-size-18 fw-500"
                                          >Agent:</span
                                        >
                                        <span class="f-size-14 fw-500"
                                          >{{ res.currency }} 00.00</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-tabs-window>
                  </v-card-text>
                </v-card>
              </div>
            </v-card>
          </div>

          <div class="mb-3" v-for="(res, index) of onewayresulted" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                  style="width: 15%"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="60px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="me-sm-auto m-auto">
                          <span class="f-size-14 fw-500">Total Fare:</span>
                        </div>
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>

                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                class="f-size-20 position-relative"
                                style="left: 2px; top: -3px"
                                >.</span
                              >
                              <span
                                id="f1"
                                class="f-size-18 fw-600"
                                style="margin-bottom: 10px; margin-left: 2px"
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        color="#faa41f"
                        width="100px"
                        height="40px"
                        style="color: white"
                        @click.prevent="onewayselect(res, index)"
                        >Book</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-7 Timing-section"
                  style="width: 65%; padding: 10px 5px"
                >
                  <v-card class="mutilti_data">
                    <div
                      class="mt-2 d-flex"
                      style="display: flex; align-items: center"
                    >
                      <div
                        style="display: flex; align-items: center"
                        @click="radiosData = 0"
                      >
                        <v-radio-group v-model="res.radios">
                          <v-radio :value="true"></v-radio>
                        </v-radio-group>
                      </div>
                      <div style="width: 100%">
                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getCountryName1(tripData.from) }}
                              </p>
                              <p
                                class="text-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getTime(res.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          totalTime(res.layoverTimes)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          res.layoverTimes[0]
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          res.layoverTimes[1]
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getArrivalTime(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getCountryName2(tripData.to) }}
                              </p>
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getretairname(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-start"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <!-- ------------multi Start-------- -->

                    <div v-if="res.multipleDatas">
                      <div
                        v-for="(data, multindex) of multiplesameData"
                        :key="multindex"
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            margin-top: 8px;
                            border-top: 1px solid lightgray;
                          "
                          v-if="multindex !== 0"
                        >
                          <div @click="multione1(data, multindex, index)">
                            <v-radio-group v-model="radiosData">
                              <v-radio :value="multindex + 1"></v-radio>
                            </v-radio-group>
                          </div>
                          <div style="width: 100%; border-radius: 10px">
                            <div class="row p-2">
                              <div
                                class="col-md-3"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName1(tripData.from) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getAirportNameFrom(data.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-6 col-8"
                                style="border-right: 1px dotted gray"
                              >
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(data.Depature) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(data, index)"
                                        @mouseout="hideDetails2(data)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="data.Depature.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(data.Depature, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(data.Depature) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName2(tripData.to) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getretairname(data.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <!-- ------------multi end-------- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{ getdepseat(res.Depature) }} Seats</span
                      >
                    </div> -->

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        this.oneway.class
                      }}</span>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-sm-center"
                      @click="flighted2(res, index)"
                    >
                      <span
                        style="color: #36c; font-size: 12px; cursor: pointer"
                      >
                        More Details</span
                      >

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>

                    <!-- <div class="col-md-3 col-4" @click="addmoreData(res, index)" style="font-size: 12px">
                      {{ res.multi }}
                    </div> -->
                    <div
                      class="col-md-3 col-4 text-center"
                      @click="getBaggage(res, index)"
                      style="
                        color: #36c;
                        font-size: 12px;
                        margin-top: 2px;
                        cursor: pointer;
                      "
                    >
                      Fare Rules
                    </div>

                    <div class="text-center col-6 fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />
                            <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-content-between select-section-hide"
                  style="width: 20%; background-color: #e3e3e3"
                >
                  <div>
                    <div class="d-flex flex-column align-center justify-center">
                      <div class="me-auto">
                        <span class="f-size-14 fw-500">Total Fare:</span>
                      </div>
                      <div class="price-head d-flex">
                        <span class="price-currency">{{ res.currency }}</span>
                        <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                        <div id="demo" class="d-flex">
                          <span id="f0" class="price-Amount">{{
                            res.find1
                          }}</span>

                          <div class="d-flex">
                            <span
                              class="f-size-20 position-relative"
                              style="left: 2px; top: 2px"
                              >.</span
                            >
                            <span
                              id="f1"
                              class="f-size-18 fw-600"
                              style="
                                margin-bottom: 10px;
                                margin-left: 2px;
                                margin-top: 5px;
                              "
                              >{{ res.find2 }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <v-btn
                        color="#faa41f"
                        width="100px"
                        height="40px"
                        style="color: white"
                        @click.prevent="onewayselect(res, index)"
                        >Book</v-btn
                      >
                    </div>
                  </div>

                  <!-- <div class="text-center Fare-overlay">
                    <a :href="res.deeplinkurl">Deeplink URL</a>
                  </div> -->

                  <div class="text-center f-size-14 fw-500">
                    <div class="text-center mb-1">
                      <span class="m-0">Comm:</span>
                      <span
                        >{{ res.currency }}
                        {{
                          res.CommissionDetails.final_commission_amount
                        }}</span
                      >
                    </div>
                  </div>

                  <!-- <div class="text-center f-size-14 fw-500">
                      <div data-v-92fd54d6="" class="text-center mb-2">
                          <p class="m-0">Total Fare:</p>
                          <span>{{ res.currency }} {{ res.TotalPrice }}</span>
                      </div>

                      <div data-v-92fd54d6="" class="text-center mb-3">
                          <p class="m-0">Commission:</p>
                          <span>{{ res.currency }} {{ res.CommissionDetails.final_commission_amount }}</span>
                      </div>
                  </div> -->

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay d-none">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          class="p-0 pt-1"
                          v-bind="props"
                        >
                          Fare Details
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>Fare Details</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              Total Price
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span style="font-size: 14px; font-weight: 500"
                                  >Passengers</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                          <hr class="mt-1 mb-2" />

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                Base Price<br />
                                Taxes & Fees
                              </div>
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div>
                            </div>
                          </div>

                          <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <v-card v-if="res.Flightdepature">
                  <v-tabs v-model="res.tabSelect" bg-color="primary">
                    <v-tab value="1">Flight Details</v-tab>
                    <v-tab value="2">Fare Details</v-tab>
                  </v-tabs>

                  <v-card-text class="p-0">
                    <v-tabs-window v-model="res.tabSelect">
                      <div v-if="res.tabSelect == 1">
                        <div class="col-md-12 col-12">
                          <v-card v-if="res.Flightdepature">
                            <div v-if="res.Flightdepature">
                              <div>
                                <div
                                  class="d-flex p-2 justify-content-between"
                                  style="background-color: #e8f0fd"
                                >
                                  <div class="d-flex align-center">
                                    <v-icon
                                      style="
                                        transform: rotate(90deg) !important;
                                      "
                                      >mdi-airplane</v-icon
                                    >
                                    <span class="f-size-15 fw-500 ps-2"
                                      >{{ getAirportCode(res.Depature) }} -
                                      {{ getArrivalCode(res.Depature) }}</span
                                    >
                                  </div>

                                  <div class="d-flex align-center justify-end">
                                    <span class="f-size-15 fw-500"
                                      >Total Trip Duration:
                                      {{
                                        flightdetailsData.Depaturejourney.Time
                                      }}</span
                                    >
                                    <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                                  </div>
                                </div>

                                <div class="px-2" style="max-width: 100%">
                                  <div
                                    v-for="(
                                      data, index
                                    ) of flightdetailsData.Depature"
                                    :key="index"
                                  >
                                    <div class="row mt-3">
                                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                                        <div class="row ms-sm-1 ms-0 w-100">
                                          <div
                                            class="col-md-4 col-12 airline-logo"
                                          >
                                            <v-img
                                              :src="data.MarketingCarrier.logo"
                                              width="30px"
                                            ></v-img>
                                          </div>
                                          <div
                                            class="col-md-8 col-12 airline-profile"
                                          >
                                            <p
                                              class="f-size-13 airline-name m-0"
                                            >
                                              {{ data.MarketingCarrier.Name }}

                                              <br />
                                              <span
                                                class="f-size-12 airline-id"
                                              >
                                                {{
                                                  data.MarketingCarrier
                                                    .AirlineID
                                                }}
                                                -
                                                {{
                                                  data.MarketingCarrier
                                                    .FlightNumber
                                                }}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-md-7 col-9">
                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated(data.Departure.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightdep(data) }}</span
                                            >
                                          </div>
                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Departure.AirportCode
                                              }} </span
                                            >, {{ data.Departure.AirportName }}
                                          </div>
                                        </div>

                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated1(data.Arrival.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightarr(data) }}</span
                                            >
                                          </div>

                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Arrival.AirportCode
                                              }} </span
                                            >, {{ data.Arrival.AirportName }}
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                      >
                                        <div class="d-flex class-type">
                                          <v-icon
                                            size="15px"
                                            color="#ccc"
                                            class="mt-1"
                                          >
                                            mdi-seat-passenger
                                          </v-icon>

                                          <span
                                            class="f-size-12"
                                            style="padding-left: 5px"
                                          >
                                            {{ oneway.class }}
                                          </span>
                                        </div>
                                        <div class="d-flex f-size-12">
                                          {{ data.Code.ResBookDesigCode }}/{{
                                            data.FareBasisCode
                                          }}
                                        </div>

                                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                          <v-icon size="15px" color="#ccc">
                                            mdi-food-fork-drink
                                          </v-icon>
                                        
                                          <span class="f-size-12" style="padding-left: 5px">
                                            Meal provided
                                          </span>
                                        </div> -->
                                      </div>

                                      <div
                                        class="d-flex position-relative mt-3"
                                      >
                                        <hr
                                          v-if="
                                            res.Depature.length - 1 !== index
                                          "
                                          style="
                                            width: 100%;
                                            position: absolute;
                                            margin-top: 8px !important;
                                            margin-bottom: 0px !important;
                                          "
                                        />
                                        <div
                                          class="d-flex justify-center"
                                          v-if="
                                            res.Depature.length - 1 !== index
                                          "
                                          style="
                                            width: 180px;
                                            background: antiquewhite;
                                            border-radius: 18px;
                                            z-index: 1;
                                            margin: auto;
                                          "
                                        >
                                          <div class="d-flex align-center">
                                            <span class="f-size-10"
                                              >Layover
                                              {{
                                                flightdetailsData.layoverTimes[
                                                  index
                                                ]
                                              }}
                                              ({{
                                                data.Arrival.AirportCode
                                              }})</span
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <!-- <div class="d-flex justify-content-between align-center">
                                      <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                                      <div class="d-flex justify-center mx-2 layover-size"
                                        v-if="res.Depature.length - 1 !== index" style="width: 20%">
                                        <div class="d-flex align-center">
                                          <span class="f-size-11 lay-fsize">
                                            {{ data.layoverTimes }} Layover
                                            Time</span>
                                        </div>
                                      </div>
                                      <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                                    </div> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                      <div v-if="res.tabSelect == 2">
                        <div>
                          <div
                            class="d-flex p-2 d-none"
                            style="background-color: #e8f0fd"
                          >
                            <div class="d-flex align-center invisible">
                              <v-img
                                :src="require('@/assets/icons/fareIcon.png')"
                                width="18px"
                              ></v-img>
                              <span class="f-size-15 fw-500 ps-2"
                                >Fare Details</span
                              >
                            </div>
                          </div>

                          <div class="row pa-3 comDetailSection">
                            <div class="col-lg-3" style="width: 30%">
                              <table class="table">
                                <thead>
                                  <tr class="f-size-14 fw-500">
                                    <td>Pax</td>
                                    <td>Base</td>
                                    <td>Tax</td>
                                  </tr>
                                </thead>
                                <tbody class="f-size-14 fw-400">
                                  <tr
                                    v-if="
                                      res.priceDetails.adultPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.childPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.infPriceDetails.baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <table class="table mb-0">
                                <thead>
                                  <tr>
                                    <td colspan="3" class="f-size-14 fw-500">
                                      Passenger(s)
                                    </td>
                                  </tr>
                                </thead>
                                <tbody class="f-size-14 fw-400">
                                  <tr
                                    v-if="
                                      res.priceDetails.adultPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .adultcount
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .baseprice *
                                        res.priceDetails.adultPriceDetails
                                          .adultcount
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.childPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .childcount
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .baseprice *
                                        res.priceDetails.childPriceDetails
                                          .childcount
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.infPriceDetails.baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .infcount
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .baseprice *
                                        res.priceDetails.infPriceDetails
                                          .infcount
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <div class="mt-2">
                                <table
                                  class="table table-borderless f-size-14 fw-500 mb-0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>Base Price</td>
                                      <td>
                                        {{ res.currency }} {{ res.Baseprice }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Taxes & Fees</td>
                                      <td>
                                        {{ res.currency }} {{ res.Taxprice }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ res.Gds }}-{{ res.sourcePcc }}/{{
                                          res.fareType
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <!-- <div class="d-flex justify-content-between fw-500">
                                  <div>
                                    <p class="m-0" style="font-size: 18px; font-weight: 500">
                                      Total Price
                                    </p>
                                  </div>
                                  <div>
                                    <p class="tot-price m-0">
                                      {{ res.currency }}
                                      {{ res.TotalPrice }}
                                    </p>
                                  </div>
                                </div>
                              
                                <div class="row mt-2">
                                  <div class="col-md-12">
                                    <div class="row justify-content-between fw-500">
                                      <div class="col-6">
                                        <span class="f-size-14 fw-500">Passengers</span>
                                      </div>
                                      <div class="col-6 f-size-14 text-end">
                                        <span v-if="aduls > 0">{{ aduls }} x Adult</span><br v-if="aduls1 > 0" />
                                        <span v-if="aduls1 > 0">{{ aduls1 }} x Child</span><br v-if="aduls2 > 0" />
                                        <span v-if="aduls2 > 0">{{ aduls2 }} x Lap Infant</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-1">
                                  <hr class="mt-1 mb-2" />
                                
                                  <hr class="mt-1 mb-2" />
                                
                                  <div class="p-1 collapse" :class="{ show: fareIcon }" id="collapse-Example">
                                    <div class="row fw-500">
                                      <div class="col-6 px-2" style="font-size: 14px">
                                        Base Price<br />
                                        Taxes & Fees
                                      </div>
                                      <div class="col-6 px-2" style="font-size: 14px; text-align: end">
                                        {{ res.currency }}
                                        {{ res.Baseprice }}<br />
                                        {{ res.currency }}
                                        {{ res.Taxprice }}
                                      </div>
                                    </div>
                                  </div> -->
                              </div>
                            </div>

                            <div
                              class="col-lg-3 border-start border-end border-1"
                              style="width: 20%"
                            >
                              <div class="mb-2">
                                <p class="f-size-14 fw-500 m-0">Total Price:</p>
                                <span class="fw-500"
                                  >{{ res.currency }} {{ res.TotalPrice }}</span
                                >
                              </div>
                              <div class="mb-2">
                                <p class="f-size-14 fw-500 m-0">
                                  Total Commission:
                                </p>
                                <span class="fw-500"
                                  >{{ res.currency }}
                                  {{
                                    res.CommissionDetails
                                      .total_commission_amount
                                  }}</span
                                >
                              </div>

                              <div class="mb-3">
                                <p class="f-size-14 fw-500 m-0">
                                  Agent Commission:
                                </p>
                                <span class="fw-500"
                                  >{{ res.currency }}
                                  {{
                                    res.CommissionDetails
                                      .final_commission_amount
                                  }}</span
                                >
                              </div>
                            </div>

                            <div class="col-lg-6 calculationAgent">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row d-flex mb-2">
                                    <div
                                      class="col-lg-6"
                                      style="width: fit-content"
                                    >
                                      <p class="m-0 fw-500">
                                        Agent Markup/Discount
                                      </p>
                                      <div class="mt-1">
                                        <v-radio-group
                                          v-model="res.instandradio"
                                          inline
                                          class="f-size-12"
                                        >
                                          <div class="d-flex align-center">
                                            <v-radio value="markup"></v-radio>
                                            <span
                                              class="f-size-14 fw-500 text-grey me-3"
                                              >Mark Up</span
                                            >
                                          </div>
                                          <div class="d-flex align-center">
                                            <v-radio value="discount"></v-radio>
                                            <span
                                              class="f-size-14 fw-500 text-grey"
                                              >Discount</span
                                            >
                                          </div>
                                        </v-radio-group>
                                      </div>
                                    </div>
                                    <div class="col-lg-6 comPart">
                                      <label
                                        for="exampleInputCardName"
                                        class="form-label fw-500"
                                        >Markup Amount</label
                                      >
                                      <div
                                        class="d-flex input-group position-relative"
                                      >
                                        <span
                                          class="input-group-text rounded"
                                          style="
                                            height: fit-content;
                                            padding: 3px 6px;
                                            width: 120px;
                                          "
                                          >{{ displayCurrency }}
                                          {{ res.tempInstantValue }}</span
                                        >
                                        <v-menu
                                          v-model="res.markupInput"
                                          :close-on-content-click="false"
                                          persistent
                                        >
                                          <template
                                            v-slot:activator="{ props }"
                                          >
                                            <v-btn
                                              height="30px"
                                              v-bind="props"
                                              :disabled="!props ? true : false"
                                              color="#faa41f"
                                              class="ms-1 rounded"
                                              style="
                                                min-width: 36px;
                                                padding: 0 5px !important;
                                              "
                                            >
                                              <v-icon color="white"
                                                >mdi-pencil</v-icon
                                              >
                                            </v-btn>
                                          </template>

                                          <v-card
                                            max-width="250"
                                            class="p-2 pb-0"
                                          >
                                            <v-form
                                              ref="commissionform"
                                              v-model="res.validMarkupform"
                                              lazy-validation
                                              @submit.prevent="
                                                submitCommission(res, index)
                                              "
                                            >
                                              <p class="m-0 f-size-14 fw-500">
                                                Enter Amount
                                              </p>
                                              <div
                                                class="d-flex input-group position-relative comPart"
                                              >
                                                <span
                                                  class="input-group-text"
                                                  style="
                                                    height: 32px;
                                                    padding: 3px 6px;
                                                  "
                                                >
                                                  {{ displayCurrency }}</span
                                                >

                                                <v-text-field
                                                  v-model="res.instantvalue"
                                                  type="text"
                                                  placeholder="00.0"
                                                  :rules="[
                                                    (v) =>
                                                      !!v || 'Enter Amount',
                                                  ]"
                                                  @keypress="filterNumber"
                                                  @keyup="
                                                    handleKeyup(res, index)
                                                  "
                                                  style="width: 100px"
                                                ></v-text-field>

                                                <v-btn
                                                  icon
                                                  type="submit"
                                                  height="30px"
                                                  :disabled="res.disabledBtn"
                                                  color="#faa41f"
                                                  class="ms-1 rounded"
                                                >
                                                  <v-icon
                                                    v-if="!res.calcLoader"
                                                    color="white"
                                                    >mdi-cached</v-icon
                                                  >
                                                  <v-progress-circular
                                                    v-if="res.calcLoader"
                                                    :width="3"
                                                    :size="20"
                                                    color="white"
                                                    indeterminate
                                                  ></v-progress-circular>
                                                </v-btn>
                                              </div>
                                            </v-form>
                                          </v-card>
                                        </v-menu>
                                        <span class="ms-2 d-flex align-center">
                                          <img :src="require('../assets/icons/booking.png')" style="width:26px;height:25px;" @click.prevent="onewayselect(res, index)"/>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12 d-flex px-0">
                                  <table class="table table-bordered mb-0">
                                    <thead>
                                      <tr>
                                        <th style="background-color: #e8f0fd;font-weight: 500;font-size: 15px;">
                                          Fare
                                        </th>
                                        <th style="background-color: #e8f0fd;font-weight: 500;font-size: 15px;">
                                          Agent Commission
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {{ res.currency }}
                                          {{ res.getTotalfare }}
                                        </td>
                                        <td>
                                          {{ res.currency }}
                                          {{ res.getAgentCommission }}
                                        </td>
                                        <!-- <td>{{ res.currency }} {{ gatCalcTotal() }}</td>
                                          <td>{{ res.currency }} {{ getCalcCommission() }}</td> -->
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div class="row d-none">
                                    <div class="col-3 mb-2">
                                      <p class="m-0">
                                        <span class="f-size-18 fw-500"
                                          >Fare:</span
                                        >
                                        <span class="f-size-14 fw-500"
                                          >{{ res.currency }} 00.00</span
                                        >
                                      </p>
                                    </div>

                                    <div class="col-3 mb-3">
                                      <p class="m-0">
                                        <span class="f-size-18 fw-500"
                                          >Agent:</span
                                        >
                                        <span class="f-size-14 fw-500"
                                          >{{ res.currency }} 00.00</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-tabs-window>
                  </v-card-text>
                </v-card>
              </div>
            </v-card>
          </div>

          <div class="mb-3" v-for="(res, index) of multicityData" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                  style="width: 15%"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <br />
                      {{ getid(res.Flight1) }} -
                      {{ getNumber(res.Flight1) }}
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="me-auto">
                          <span class="f-size-14 fw-500">Total Fare:</span>
                        </div>

                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                "
                                >.</span
                              >
                              <span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        color="#faa41f"
                        width="100px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-7 Timing-section"
                  style="width: 65%; padding: 10px 5px"
                >
                  <!-- ------flight1 start----- -->
                  <div class="mt-2">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radioData1 = 0"
                        >
                          <v-radio-group v-model="res.radios1">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[0].from }}
                                </p>
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight1) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight1) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight1) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight1) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight1JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight1JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight1.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight1)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes1[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight1JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight1JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight1.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight1)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes1[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight1.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight1, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes1[1]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight1JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight1) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight1) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight1) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  <!-- {{ getArrivalCode(res.Depature) }} -->
                                  {{ codeData[0].to }}
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight1) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight1) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas1">
                        <div
                          v-for="(res, multindex) of multiflight1"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone1(res, multindex, index)">
                              <v-radio-group v-model="radioData1">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[0].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight1) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight1) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(res.Flight1) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight1JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight1JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight1.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight1)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                res.layoverTimes2[0]
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight1JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight1JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight1.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight1)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                res.layoverTimes2[0]
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(res, index)
                                            "
                                            @mouseout="hideDetails2(res)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="res.Flight1.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight1, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                res.layoverTimes1[1]
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight1JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight1) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight1)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight1) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      <!-- {{ getArrivalCode(res.Depature) }} -->
                                      {{ codeData[0].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight1) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight1 end----- -->

                  <!-- ------flight2 start----- -->

                  <div class="mt-2">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radioData2 = 0"
                        >
                          <v-radio-group v-model="res.radios2">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 gx-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[1].from }}
                                </p>
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight2) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight2) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight2) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight2) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight2JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight2JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight2.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight2)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes2[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight2JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight2JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight2.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight2)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes2[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight2.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight2, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes2[1]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight2JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight2) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight2) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight2) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[1].to }}
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight2) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight2) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas2">
                        <div
                          v-for="(data, multindex) of multiflight2"
                          :key="multindex"
                        >
                          <!-- {{data.Flight2JourneyTime}} -->
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex > 0 ? true : false"
                          >
                            <div @click="multicityone2(data, multindex, index)">
                              <v-radio-group v-model="radioData2">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>
                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 gx-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[1].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(data.Flight2) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(data.Flight2) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(data.Flight2) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops == 0
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ data.Flight2JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops == 1
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ data.Flight2JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(data, index)
                                            "
                                            @mouseout="hideDetails1(data)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="data.Flight2.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: data.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(data.Flight2)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            data.Flight2JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops >= 2
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ data.Flight2JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(data, index)
                                            "
                                            @mouseout="hideDetails1(data)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="data.Flight2.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: data.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(data.Flight2)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(data, index)
                                            "
                                            @mouseout="hideDetails2(data)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="data.Flight2.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: data.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(data.Flight2, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            data.Flight2JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(data.Flight2) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(data.Flight2)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(data.Flight2) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[1].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(data.Flight2) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>

                  <!-- ------flight2 end----- -->

                  <!-- ------flight3 start----- -->
                  <div class="mt-2" v-if="res.Flight3 ? true : false">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radioData3 = 0"
                        >
                          <v-radio-group v-model="res.radios3">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>
                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[2].from }}
                                </p>
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight3) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight3) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight3) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight3) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight3JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight3JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight3.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight3)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes3[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight3JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight3JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight3.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight3)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes3[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight3.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight3, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes3[1]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight3JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight3) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight3) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight3) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[2].to }}
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight3) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight3) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas3">
                        <div
                          v-for="(res, multindex) of multiflight3"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone3(res, multindex, index)">
                              <v-radio-group v-model="radioData3">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[2].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight3) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight3) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(res.Flight3) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight3JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight3JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight3.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight3)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight3JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight3JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight2.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight3)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(res, index)
                                            "
                                            @mouseout="hideDetails2(res)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="res.Flight3.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight3, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight3JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight3) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight3)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight3) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[2].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight3) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight3 end----- -->

                  <!-- ------flight4 start----- -->
                  <div class="mt-2" v-if="res.Flight4 ? true : false">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <div
                          style="display: flex; align-items: center"
                          @click="radioData4 = 0"
                        >
                          <v-radio-group v-model="res.radios4">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div>

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[3].from }}
                                </p>
                                <p
                                  class="text-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight4) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight4) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight4) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight4) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight4JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight4JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight4.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight4)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes4[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight4JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight4JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight4.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight4)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes4[0]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight4.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight4, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            res.layoverTimes4[1]
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight4JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight4) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight4) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight4) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[3].to }}
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight4) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight4) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas4">
                        <div
                          v-for="(res, multindex) of multiflight4"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone4(res, multindex, index)">
                              <v-radio-group v-model="radioData4">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[3].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight4) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight4) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(res.Flight4) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight4JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight4JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight4.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight4)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight4JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight4JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight4.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight4)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(res, index)
                                            "
                                            @mouseout="hideDetails2(res)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="res.Flight4.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight4, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight4JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight4) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight4)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight4) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[3].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight4) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight4 end----- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{
                          getdepseat(res.Depature) >= getretseat(res.Return)
                            ? getdepseat(res.Return)
                            : getretseat(res.Depature)
                        }}
                        Seats</span
                      >
                    </div> -->

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex justify-center align-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        classType
                      }}</span>
                    </div>

                    <div
                      class="col-md-3 col-4 d-flex align-center justify-content-sm-center"
                      @click="flighted3(res, index)"
                    >
                      <span
                        style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        "
                      >
                        More Details</span
                      >

                      <v-icon style="color: #36c" size="16">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>

                    <!-- <div class="col-md-3 col-4" @click="multiroundData(res, index)" style="font-size: 12px">
                      {{ res.multi }}
                    </div> -->
                    <div
                      class="col-md-3 col-4 text-center"
                      @click="getBaggage(res, index)"
                      style="
                        color: #36c;
                        font-size: 12px;
                        margin-top: 2px;
                        cursor: pointer;
                      "
                    >
                      Fare Rules
                    </div>

                    <div class="text-center col-6 fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                          <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />
                            <div class="d-flex align-end mb-2">
                              <span
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                style="
                                  padding-right: 5px;
                                  cursor: pointer;
                                  font-size: 14px;
                                  letter-spacing: 1px;
                                "
                              >
                                <a href="#">Fare Summary</a></span
                              >

                              <v-icon
                                size="18"
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                v-model="fareIcon"
                                style="
                                  color: 0D6EFD;
                                  cursor: pointer;
                                  overflow-anchor: none;
                                "
                                >{{
                                  fareIcon
                                    ? "mdi-chevron-up-box-outline"
                                    : "mdi-chevron-down-box-outline"
                                }}</v-icon
                              >
                            </div>

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <br />
                            <p style="font-size: 12px">
                              All fares are quoted in USD .Some airlines may
                              charge bag gage fees. Your credit/debit card may
                              be billed in multiple charges totaling the final
                              total price.
                            </p>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center select-section-hide1"
                  style="width: 20%; background-color: #e3e3e3"
                >
                  <div
                    class="d-flex flex-column align-center justify-center"
                    style="margin-top: 35px; margin-bottom: 26px"
                  >
                    <div class="me-auto">
                      <span class="f-size-14 fw-500">Total Fare:</span>
                    </div>
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            class="f-size-20 position-relative"
                            style="left: 2px; top: 2px"
                            >.</span
                          >
                          <span
                            class="f-size-18 fw-600"
                            id="f1"
                            style="
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <span
                      class="f-size-14 fw-500"
                      style="margin-top: -10px; margin-bottom: 10px"
                      >Per Person</span
                    > -->
                    <v-btn
                      color="#faa41f"
                      width="100px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >Book</v-btn
                    >
                  </div>
                  <!-- <div class="text-center Fare-overlay">
                    <a :href="res.deeplinkurl">Deeplink URL</a>
                  </div> -->

                  <div class="text-center f-size-14 fw-500">
                    <!-- <div data-v-92fd54d6="" class="text-center mb-2">
                          <p class="m-0">Total Fare:</p>
                          <span>{{ res.currency }} {{ res.TotalPrice }}</span>
                      </div> -->

                    <div data-v-92fd54d6="" class="text-center mb-3">
                      <span class="0">Comm: </span>
                      <span
                        >{{ res.currency }}
                        {{
                          res.CommissionDetails.final_commission_amount
                        }}</span
                      >
                    </div>
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay d-none">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          v-bind="props"
                        >
                          Fare Details
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>Fare Details</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              Total Price
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span style="font-size: 14px; font-weight: 500"
                                  >Passengers</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <div class="d-flex align-end mb-2">
                            <span
                              class="not-collapsed"
                              data-bs-toggle="collapse"
                              href="#collapse-Example"
                              aria-expanded="true"
                              aria-controls="collapse-Example"
                              style="
                                padding-right: 5px;
                                cursor: pointer;
                                font-size: 14px;
                                letter-spacing: 1px;
                              "
                            >
                              <a href="#">Fare Summary</a></span
                            >

                            <v-icon
                              size="18"
                              class="not-collapsed"
                              data-bs-toggle="collapse"
                              href="#collapse-Example"
                              aria-expanded="true"
                              aria-controls="collapse-Example"
                              v-model="fareIcon"
                              style="
                                color: 0D6EFD;
                                cursor: pointer;
                                overflow-anchor: none;
                              "
                              >{{
                                fareIcon
                                  ? "mdi-chevron-up-box-outline"
                                  : "mdi-chevron-down-box-outline"
                              }}</v-icon
                            >
                          </div>

                          <hr class="mt-1 mb-2" />

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                Base Price<br />
                                Taxes & Fees
                              </div>
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div>
                            </div>
                          </div>

                          <br />
                          <p style="font-size: 12px">
                            All fares are quoted in USD .Some airlines may
                            charge bag gage fees. Your credit/debit card may be
                            billed in multiple charges totaling the final total
                            price.
                          </p>
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <v-card v-if="res.Flightdepature">
                  <v-tabs v-model="res.tabSelect" bg-color="primary">
                    <v-tab value="1">Flight Details</v-tab>
                    <v-tab value="2">Fare Details</v-tab>
                  </v-tabs>

                  <v-card-text class="p-0">
                    <v-tabs-window v-model="res.tabSelect">
                      <div v-if="res.tabSelect == 1">
                        <div class="col-md-12 col-12">
                          <v-card class="pb-2" v-if="res.Flightdepature">
                            <!-- FLight1 Details   -->
                            <div v-if="res.Flightdepature">
                              <div>
                                <div
                                  class="d-flex p-2 justify-content-between"
                                  style="background-color: #e8f0fd"
                                >
                                  <div class="d-flex align-center">
                                    <v-icon
                                      style="
                                        transform: rotate(90deg) !important;
                                      "
                                      >mdi-airplane</v-icon
                                    >
                                    <span class="f-size-15 fw-500 ps-2"
                                      >{{ getAirportCode(res.Flight1) }} -
                                      {{ getArrivalCode(res.Flight1) }}</span
                                    >
                                  </div>

                                  <div class="d-flex align-center justify-end">
                                    <span class="f-size-15 fw-500"
                                      >Total Trip Duration :
                                      {{ res.Flight1JourneyTime.Time }}</span
                                    >
                                    <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                                  </div>
                                </div>

                                <div class="px-2" style="max-width: 100%">
                                  <div
                                    v-for="(data, index) of res.Flight1"
                                    :key="index"
                                  >
                                    <div class="row mt-3 mb-3">
                                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                                        <div class="row ms-sm-1 ms-0 w-100">
                                          <div
                                            class="col-md-4 col-12 airline-logo"
                                          >
                                            <v-img
                                              :src="data.MarketingCarrier.logo"
                                              width="30px"
                                            ></v-img>
                                          </div>
                                          <div
                                            class="col-md-8 col-12 airline-profile"
                                          >
                                            <p
                                              class="f-size-13 airline-name m-0"
                                            >
                                              {{ data.MarketingCarrier.Name }}

                                              <br />
                                              <span
                                                class="f-size-12 airline-id"
                                              >
                                                {{
                                                  data.MarketingCarrier
                                                    .AirlineID
                                                }}
                                                -
                                                {{
                                                  data.MarketingCarrier
                                                    .FlightNumber
                                                }}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-md-7 col-9">
                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated(data.Departure.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightdep(data) }}</span
                                            >
                                          </div>
                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Departure.AirportCode
                                              }} </span
                                            >, {{ data.Departure.AirportName }}
                                          </div>
                                        </div>

                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated1(data.Arrival.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightarr(data) }}</span
                                            >
                                          </div>

                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Arrival.AirportCode
                                              }} </span
                                            >, {{ data.Arrival.AirportName }}
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                      >
                                        <div class="d-flex class-type">
                                          <v-icon
                                            size="15px"
                                            color="#ccc"
                                            class="mt-1"
                                          >
                                            mdi-seat-passenger
                                          </v-icon>

                                          <span
                                            class="f-size-12"
                                            style="padding-left: 5px"
                                          >
                                            {{ oneway.class }}
                                          </span>
                                        </div>
                                        <div class="d-flex f-size-12">
                                          {{ data.Code.ResBookDesigCode }}/{{
                                            data.FareBasisCode
                                          }}
                                        </div>

                                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                          <v-icon size="15px" color="#ccc">
                                            mdi-food-fork-drink
                                          </v-icon>
                                        
                                          <span class="f-size-12" style="padding-left: 5px">
                                            Meal provided
                                          </span>
                                        </div> -->
                                      </div>

                                      <div
                                        class="d-flex position-relative mt-3"
                                      >
                                        <hr
                                          v-if="
                                            res.Flight1.length - 1 !== index
                                          "
                                          style="
                                            width: 100%;
                                            position: absolute;
                                            margin-top: 8px !important;
                                            margin-bottom: 0px !important;
                                          "
                                        />
                                        <div
                                          class="d-flex justify-center"
                                          v-if="
                                            res.Flight1.length - 1 !== index
                                          "
                                          style="
                                            width: 180px;
                                            background: antiquewhite;
                                            border-radius: 18px;
                                            z-index: 1;
                                            margin: auto;
                                          "
                                        >
                                          <div class="d-flex align-center">
                                            <span class="f-size-10"
                                              >Layover
                                              {{ res.layoverTimes1[index] }} ({{
                                                data.Arrival.AirportCode
                                              }})
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- FLight1 Details End  -->

                            <!-- FLight2 Details   -->
                            <div v-if="res.Flightdepature">
                              <div>
                                <div
                                  class="d-flex p-2 justify-content-between"
                                  style="background-color: #e8f0fd"
                                >
                                  <div class="d-flex align-center">
                                    <v-icon
                                      style="
                                        transform: rotate(90deg) !important;
                                      "
                                      >mdi-airplane</v-icon
                                    >
                                    <span class="f-size-15 fw-500 ps-2"
                                      >{{ getAirportCode(res.Flight2) }} -
                                      {{ getArrivalCode(res.Flight2) }}</span
                                    >
                                  </div>

                                  <div class="d-flex align-center justify-end">
                                    <span class="f-size-15 fw-500"
                                      >Total Trip Duration :
                                      {{ res.Flight2JourneyTime.Time }}</span
                                    >
                                    <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                                  </div>
                                </div>

                                <div class="px-2" style="max-width: 100%">
                                  <div
                                    v-for="(data, index) of res.Flight2"
                                    :key="index"
                                  >
                                    <div class="row mt-3 mb-3">
                                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                                        <div class="row ms-sm-1 ms-0 w-100">
                                          <div
                                            class="col-md-4 col-12 airline-logo"
                                          >
                                            <v-img
                                              :src="data.MarketingCarrier.logo"
                                              width="30px"
                                            ></v-img>
                                          </div>
                                          <div
                                            class="col-md-8 col-12 airline-profile"
                                          >
                                            <p
                                              class="f-size-13 airline-name m-0"
                                            >
                                              {{ data.MarketingCarrier.Name }}

                                              <br />
                                              <span
                                                class="f-size-12 airline-id"
                                              >
                                                {{
                                                  data.MarketingCarrier
                                                    .AirlineID
                                                }}
                                                -
                                                {{
                                                  data.MarketingCarrier
                                                    .FlightNumber
                                                }}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-md-7 col-9">
                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated(data.Departure.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightdep(data) }}</span
                                            >
                                          </div>
                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Departure.AirportCode
                                              }} </span
                                            >, {{ data.Departure.AirportName }}
                                          </div>
                                        </div>

                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated1(data.Arrival.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightarr(data) }}</span
                                            >
                                          </div>

                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Arrival.AirportCode
                                              }} </span
                                            >, {{ data.Arrival.AirportName }}
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                      >
                                        <div class="d-flex class-type">
                                          <v-icon
                                            size="15px"
                                            color="#ccc"
                                            class="mt-1"
                                          >
                                            mdi-seat-passenger
                                          </v-icon>

                                          <span
                                            class="f-size-12"
                                            style="padding-left: 5px"
                                          >
                                            {{ oneway.class }}
                                          </span>
                                        </div>
                                        <div class="d-flex f-size-12">
                                          {{ data.Code.ResBookDesigCode }}/{{
                                            data.FareBasisCode
                                          }}
                                        </div>

                                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                          <v-icon size="15px" color="#ccc">
                                            mdi-food-fork-drink
                                          </v-icon>
                                        
                                          <span class="f-size-12" style="padding-left: 5px">
                                            Meal provided
                                          </span>
                                        </div> -->
                                      </div>

                                      <div
                                        class="d-flex position-relative mt-3"
                                      >
                                        <hr
                                          v-if="
                                            res.Flight2.length - 1 !== index
                                          "
                                          style="
                                            width: 100%;
                                            position: absolute;
                                            margin-top: 8px !important;
                                            margin-bottom: 0px !important;
                                          "
                                        />
                                        <div
                                          class="d-flex justify-center"
                                          v-if="
                                            res.Flight2.length - 1 !== index
                                          "
                                          style="
                                            width: 180px;
                                            background: antiquewhite;
                                            border-radius: 18px;
                                            z-index: 1;
                                            margin: auto;
                                          "
                                        >
                                          <div class="d-flex align-center">
                                            <span class="f-size-10"
                                              >Layover
                                              {{ res.layoverTimes2[index] }} ({{
                                                data.Arrival.AirportCode
                                              }})
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- FLight2 Details End  -->

                            <!-- FLight3 Details   -->
                            <div v-if="res.Flightdepature">
                              <div v-if="res.Flight3 ? true : false">
                                <div
                                  class="d-flex p-2 justify-content-between"
                                  style="background-color: #e8f0fd"
                                >
                                  <div class="d-flex align-center">
                                    <v-icon
                                      style="
                                        transform: rotate(90deg) !important;
                                      "
                                      >mdi-airplane</v-icon
                                    >
                                    <span class="f-size-15 fw-500 ps-2"
                                      >{{ getAirportCode(res.Flight3) }} -
                                      {{ getArrivalCode(res.Flight3) }}</span
                                    >
                                  </div>

                                  <div class="d-flex align-center justify-end">
                                    <span class="f-size-15 fw-500"
                                      >Total Trip Duration :
                                      {{ res.Flight3JourneyTime.Time }}</span
                                    >
                                    <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                                  </div>
                                </div>

                                <div class="px-2" style="max-width: 100%">
                                  <div
                                    v-for="(data, index) of res.Flight3"
                                    :key="index"
                                  >
                                    <div class="row mt-3 mb-3">
                                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                                        <div class="row ms-sm-1 ms-0 w-100">
                                          <div
                                            class="col-md-4 col-12 airline-logo"
                                          >
                                            <v-img
                                              :src="data.MarketingCarrier.logo"
                                              width="30px"
                                            ></v-img>
                                          </div>
                                          <div
                                            class="col-md-8 col-12 airline-profile"
                                          >
                                            <p
                                              class="f-size-13 airline-name m-0"
                                            >
                                              {{ data.MarketingCarrier.Name }}

                                              <br />
                                              <span
                                                class="f-size-12 airline-id"
                                              >
                                                {{
                                                  data.MarketingCarrier
                                                    .AirlineID
                                                }}
                                                -
                                                {{
                                                  data.MarketingCarrier
                                                    .FlightNumber
                                                }}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-md-7 col-9">
                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated(data.Departure.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightdep(data) }}</span
                                            >
                                          </div>
                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Departure.AirportCode
                                              }} </span
                                            >, {{ data.Departure.AirportName }}
                                          </div>
                                        </div>

                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated1(data.Arrival.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightarr(data) }}</span
                                            >
                                          </div>

                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Arrival.AirportCode
                                              }} </span
                                            >, {{ data.Arrival.AirportName }}
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                      >
                                        <div class="d-flex class-type">
                                          <v-icon
                                            size="15px"
                                            color="#ccc"
                                            class="mt-1"
                                          >
                                            mdi-seat-passenger
                                          </v-icon>

                                          <span
                                            class="f-size-12"
                                            style="padding-left: 5px"
                                          >
                                            {{ oneway.class }}
                                          </span>
                                        </div>
                                        <div class="d-flex f-size-12">
                                          {{ data.Code.ResBookDesigCode }}/{{
                                            data.FareBasisCode
                                          }}
                                        </div>

                                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                          <v-icon size="15px" color="#ccc">
                                            mdi-food-fork-drink
                                          </v-icon>
                                        
                                          <span class="f-size-12" style="padding-left: 5px">
                                            Meal provided
                                          </span>
                                        </div> -->
                                      </div>

                                      <div
                                        class="d-flex position-relative mt-3"
                                      >
                                        <hr
                                          v-if="
                                            res.Flight3.length - 1 !== index
                                          "
                                          style="
                                            width: 100%;
                                            position: absolute;
                                            margin-top: 8px !important;
                                            margin-bottom: 0px !important;
                                          "
                                        />
                                        <div
                                          class="d-flex justify-center"
                                          v-if="
                                            res.Flight3.length - 1 !== index
                                          "
                                          style="
                                            width: 180px;
                                            background: antiquewhite;
                                            border-radius: 18px;
                                            z-index: 1;
                                            margin: auto;
                                          "
                                        >
                                          <div class="d-flex align-center">
                                            <span class="f-size-10"
                                              >Layover
                                              {{ res.layoverTimes3[index] }} ({{
                                                data.Arrival.AirportCode
                                              }})
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- FLight3 Details End  -->

                            <!-- FLight4 Details   -->
                            <div v-if="res.Flightdepature">
                              <div v-if="res.Flight4 ? true : false">
                                <div
                                  class="d-flex p-2 justify-content-between"
                                  style="background-color: #e8f0fd"
                                >
                                  <div class="d-flex align-center">
                                    <v-icon
                                      style="
                                        transform: rotate(90deg) !important;
                                      "
                                      >mdi-airplane</v-icon
                                    >
                                    <span class="f-size-15 fw-500 ps-2"
                                      >{{ getAirportCode(res.Flight4) }} -
                                      {{ getArrivalCode(res.Flight4) }}</span
                                    >
                                  </div>

                                  <div class="d-flex align-center justify-end">
                                    <span class="f-size-15 fw-500"
                                      >Total Trip Duration :
                                      {{ res.Flight4JourneyTime.Time }}</span
                                    >
                                    <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                                  </div>
                                </div>

                                <div class="px-2" style="max-width: 100%">
                                  <div
                                    v-for="(data, index) of res.Flight4"
                                    :key="index"
                                  >
                                    <div class="row mt-3 mb-3">
                                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                                        <div class="row ms-sm-1 ms-0 w-100">
                                          <div
                                            class="col-md-4 col-12 airline-logo"
                                          >
                                            <v-img
                                              :src="data.MarketingCarrier.logo"
                                              width="30px"
                                            ></v-img>
                                          </div>
                                          <div
                                            class="col-md-8 col-12 airline-profile"
                                          >
                                            <p
                                              class="f-size-13 airline-name m-0"
                                            >
                                              {{ data.MarketingCarrier.Name }}

                                              <br />
                                              <span
                                                class="f-size-12 airline-id"
                                              >
                                                {{
                                                  data.MarketingCarrier
                                                    .AirlineID
                                                }}
                                                -
                                                {{
                                                  data.MarketingCarrier
                                                    .FlightNumber
                                                }}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-md-7 col-9">
                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated(data.Departure.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightdep(data) }}</span
                                            >
                                          </div>
                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Departure.AirportCode
                                              }} </span
                                            >, {{ data.Departure.AirportName }}
                                          </div>
                                        </div>

                                        <div class="row d-flex">
                                          <div class="col-sm-3 p-0">
                                            <span
                                              class="f-size-10 airline-date"
                                              >{{
                                                getdated1(data.Arrival.Date)
                                              }}</span
                                            >
                                            <span
                                              class="f-size-13 airline-time"
                                              style="margin-left: 5px"
                                              >{{ getflightarr(data) }}</span
                                            >
                                          </div>

                                          <div
                                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                            style="margin-bottom: 4px"
                                          >
                                            <span class="fw-500"
                                              >{{
                                                data.Arrival.AirportCode
                                              }} </span
                                            >, {{ data.Arrival.AirportName }}
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                                      >
                                        <div class="d-flex class-type">
                                          <v-icon
                                            size="15px"
                                            color="#ccc"
                                            class="mt-1"
                                          >
                                            mdi-seat-passenger
                                          </v-icon>

                                          <span
                                            class="f-size-12"
                                            style="padding-left: 5px"
                                          >
                                            {{ oneway.class }}
                                          </span>
                                        </div>
                                        <div class="d-flex f-size-12">
                                          {{ data.Code.ResBookDesigCode }}/{{
                                            data.FareBasisCode
                                          }}
                                        </div>

                                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                          <v-icon size="15px" color="#ccc">
                                            mdi-food-fork-drink
                                          </v-icon>

                                          <span class="f-size-12" style="padding-left: 5px">
                                            Meal provided
                                          </span>
                                        </div> -->
                                      </div>

                                      <div
                                        class="d-flex position-relative mt-3"
                                      >
                                        <hr
                                          v-if="
                                            res.Flight4.length - 1 !== index
                                          "
                                          style="
                                            width: 100%;
                                            position: absolute;
                                            margin-top: 8px !important;
                                            margin-bottom: 0px !important;
                                          "
                                        />
                                        <div
                                          class="d-flex justify-center"
                                          v-if="
                                            res.Flight4.length - 1 !== index
                                          "
                                          style="
                                            width: 180px;
                                            background: antiquewhite;
                                            border-radius: 18px;
                                            z-index: 1;
                                            margin: auto;
                                          "
                                        >
                                          <div class="d-flex align-center">
                                            <span class="f-size-10"
                                              >Layover
                                              {{ res.layoverTimes4[index] }} ({{
                                                data.Arrival.AirportCode
                                              }})
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- FLight4 Details End  -->
                          </v-card>
                        </div>
                      </div>
                      <div v-if="res.tabSelect == 2">
                        <div>
                          <div
                            class="d-flex p-2 d-none"
                            style="background-color: #e8f0fd"
                          >
                            <div class="d-flex align-center invisible">
                              <v-img
                                :src="require('@/assets/icons/fareIcon.png')"
                                width="18px"
                              ></v-img>
                              <span class="f-size-15 fw-500 ps-2"
                                >Fare Details</span
                              >
                            </div>
                          </div>

                          {{ console.log(res, "resresresadultPriceDetails") }}

                          <div class="row pa-3 comDetailSection">
                            <div class="col-lg-3" style="width: 30%">
                              <table class="table">
                                <thead>
                                  <tr class="f-size-14 fw-500">
                                    <td>Pax</td>
                                    <td>Base</td>
                                    <td>Tax</td>
                                  </tr>
                                </thead>
                                <tbody class="f-size-14 fw-400">
                                  <tr
                                    v-if="
                                      res.priceDetails.adultPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.childPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.infPriceDetails.baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .baseprice
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .taxprice
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <table class="table mb-0">
                                <thead>
                                  <tr>
                                    <td colspan="3" class="f-size-14 fw-500">
                                      Passenger(s)
                                    </td>
                                  </tr>
                                </thead>
                                <tbody class="f-size-14 fw-400">
                                  <tr
                                    v-if="
                                      res.priceDetails.adultPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .adultcount
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.adultPriceDetails
                                          .baseprice *
                                        res.priceDetails.adultPriceDetails
                                          .adultcount
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.childPriceDetails
                                        .baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .childcount
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.childPriceDetails
                                          .baseprice *
                                        res.priceDetails.childPriceDetails
                                          .childcount
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      res.priceDetails.infPriceDetails.baseprice
                                    "
                                  >
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .pax_type
                                      }}
                                    </td>
                                    <td>
                                      x
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .infcount
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        res.priceDetails.infPriceDetails
                                          .baseprice *
                                        res.priceDetails.infPriceDetails
                                          .infcount
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <div class="mt-2">
                                <table
                                  class="table table-borderless f-size-14 fw-500 mb-0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>Base Price</td>
                                      <td>
                                        {{ res.currency }} {{ res.Baseprice }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Taxes & Fees</td>
                                      <td>
                                        {{ res.currency }} {{ res.Taxprice }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ res.Gds }}-{{ res.sourcePcc }}/{{
                                          res.fareType
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <!-- <div class="d-flex justify-content-between fw-500">
                                  <div>
                                    <p class="m-0" style="font-size: 18px; font-weight: 500">
                                      Total Price
                                    </p>
                                  </div>
                                  <div>
                                    <p class="tot-price m-0">
                                      {{ res.currency }}
                                      {{ res.TotalPrice }}
                                    </p>
                                  </div>
                                </div>
                              
                                <div class="row mt-2">
                                  <div class="col-md-12">
                                    <div class="row justify-content-between fw-500">
                                      <div class="col-6">
                                        <span class="f-size-14 fw-500">Passengers</span>
                                      </div>
                                      <div class="col-6 f-size-14 text-end">
                                        <span v-if="aduls > 0">{{ aduls }} x Adult</span><br v-if="aduls1 > 0" />
                                        <span v-if="aduls1 > 0">{{ aduls1 }} x Child</span><br v-if="aduls2 > 0" />
                                        <span v-if="aduls2 > 0">{{ aduls2 }} x Lap Infant</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-1">
                                  <hr class="mt-1 mb-2" />
                                
                                  <hr class="mt-1 mb-2" />
                                
                                  <div class="p-1 collapse" :class="{ show: fareIcon }" id="collapse-Example">
                                    <div class="row fw-500">
                                      <div class="col-6 px-2" style="font-size: 14px">
                                        Base Price<br />
                                        Taxes & Fees
                                      </div>
                                      <div class="col-6 px-2" style="font-size: 14px; text-align: end">
                                        {{ res.currency }}
                                        {{ res.Baseprice }}<br />
                                        {{ res.currency }}
                                        {{ res.Taxprice }}
                                      </div>
                                    </div>
                                  </div> -->
                              </div>
                            </div>

                            <div
                              class="col-lg-3 border-start border-end border-1"
                              style="width: 20%"
                            >
                              <div class="mb-2">
                                <p class="f-size-14 fw-500 m-0">Total Price:</p>
                                <span class="fw-500"
                                  >{{ res.currency }} {{ res.TotalPrice }}</span
                                >
                              </div>
                              <div class="mb-2">
                                <p class="f-size-14 fw-500 m-0">
                                  Total Commission:
                                </p>
                                <span class="fw-500"
                                  >{{ res.currency }}
                                  {{
                                    res.CommissionDetails
                                      .total_commission_amount
                                  }}</span
                                >
                              </div>

                              <div class="mb-3">
                                <p class="f-size-14 fw-500 m-0">
                                  Agent Commission:
                                </p>
                                <span class="fw-500"
                                  >{{ res.currency }}
                                  {{
                                    res.CommissionDetails
                                      .final_commission_amount
                                  }}</span
                                >
                              </div>
                            </div>

                            <div class="col-lg-6 calculationAgent">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row d-flex mb-2">
                                    <div
                                      class="col-lg-6"
                                      style="width: fit-content"
                                    >
                                      <p class="m-0 fw-500">
                                        Agent Markup/Discount
                                      </p>
                                      <div class="mt-1">
                                        <v-radio-group
                                          v-model="res.instandradio"
                                          inline
                                          class="f-size-12"
                                        >
                                          <div class="d-flex align-center">
                                            <v-radio value="markup"></v-radio>
                                            <span
                                              class="f-size-14 fw-500 text-grey me-3"
                                              >Mark Up</span
                                            >
                                          </div>
                                          <div class="d-flex align-center">
                                            <v-radio value="discount"></v-radio>
                                            <span
                                              class="f-size-14 fw-500 text-grey"
                                              >Discount</span
                                            >
                                          </div>
                                        </v-radio-group>
                                      </div>
                                    </div>
                                    <div class="col-lg-6 comPart">
                                      <label
                                        for="exampleInputCardName"
                                        class="form-label fw-500"
                                        >Markup Amount</label
                                      >
                                      <div
                                        class="d-flex input-group position-relative"
                                      >
                                        <span
                                          class="input-group-text rounded"
                                          style="
                                            height: fit-content;
                                            padding: 3px 6px;
                                            width: 120px;
                                          "
                                          >{{ displayCurrency }}
                                          {{ res.tempInstantValue }}</span
                                        >
                                        <v-menu
                                          v-model="res.markupInput"
                                          :close-on-content-click="false"
                                          persistent
                                        >
                                          <template
                                            v-slot:activator="{ props }"
                                          >
                                            <v-btn
                                              height="30px"
                                              v-bind="props"
                                              :disabled="!props ? true : false"
                                              color="#faa41f"
                                              class="ms-1 rounded"
                                              style="
                                                min-width: 36px;
                                                padding: 0 5px !important;
                                              "
                                            >
                                              <v-icon color="white"
                                                >mdi-pencil</v-icon
                                              >
                                            </v-btn>
                                          </template>

                                          <v-card
                                            max-width="250"
                                            class="p-2 pb-0"
                                          >
                                            <v-form
                                              ref="commissionform"
                                              v-model="res.validMarkupform"
                                              lazy-validation
                                              @submit.prevent="
                                                submitCommission(res, index)
                                              "
                                            >
                                              <p class="m-0 f-size-14 fw-500">
                                                Enter Amount
                                              </p>
                                              <div
                                                class="d-flex input-group position-relative comPart"
                                              >
                                                <span
                                                  class="input-group-text"
                                                  style="
                                                    height: 32px;
                                                    padding: 3px 6px;
                                                  "
                                                >
                                                  {{ displayCurrency }}</span
                                                >

                                                <v-text-field
                                                  v-model="res.instantvalue"
                                                  type="text"
                                                  placeholder="00.0"
                                                  :rules="[
                                                    (v) =>
                                                      !!v || 'Enter Amount',
                                                  ]"
                                                  @keypress="filterNumber"
                                                  @keyup="
                                                    handleKeyup(res, index)
                                                  "
                                                  style="width: 100px"
                                                ></v-text-field>

                                                <v-btn
                                                  icon
                                                  type="submit"
                                                  height="30px"
                                                  :disabled="res.disabledBtn"
                                                  color="#faa41f"
                                                  class="ms-1 rounded"
                                                >
                                                  <v-icon
                                                    v-if="!res.calcLoader"
                                                    color="white"
                                                    >mdi-cached</v-icon
                                                  >
                                                  <v-progress-circular
                                                    v-if="res.calcLoader"
                                                    :width="3"
                                                    :size="20"
                                                    color="white"
                                                    indeterminate
                                                  ></v-progress-circular>
                                                </v-btn>
                                              </div>
                                            </v-form>
                                          </v-card>
                                        </v-menu>
                                        <span class="ms-2 d-flex align-center">
                                          <img :src="require('../assets/icons/booking.png')" style="width:26px;height:25px;" @click.prevent="selected(res, index)"/>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12 d-flex px-0">
                                  <table class="table table-bordered mb-0">
                                    <thead>
                                      <tr>
                                        <th style="background-color: #e8f0fd;font-weight: 500;font-size: 15px;">
                                          Fare
                                        </th>
                                        <th style="background-color: #e8f0fd;font-weight: 500;font-size: 15px;">
                                          Agent Commission
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {{ res.currency }}
                                          {{ res.getTotalfare }}
                                        </td>
                                        <td>
                                          {{ res.currency }}
                                          {{ res.getAgentCommission }}
                                        </td>
                                        <!-- <td>{{ res.currency }} {{ gatCalcTotal() }}</td>
                                          <td>{{ res.currency }} {{ getCalcCommission() }}</td> -->
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div class="row d-none">
                                    <div class="col-3 mb-2">
                                      <p class="m-0">
                                        <span class="f-size-18 fw-500"
                                          >Fare:</span
                                        >
                                        <span class="f-size-14 fw-500"
                                          >{{ res.currency }} 00.00</span
                                        >
                                      </p>
                                    </div>

                                    <div class="col-3 mb-3">
                                      <p class="m-0">
                                        <span class="f-size-18 fw-500"
                                          >Agent:</span
                                        >
                                        <span class="f-size-14 fw-500"
                                          >{{ res.currency }} 00.00</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-tabs-window>
                  </v-card-text>
                </v-card>
              </div>
            </v-card>
          </div>

          <div class="drawer-section">
            <div class="modal" v-if="modalTab">
              <div class="modal-content">
                <div
                  class="modal-header d-flex flex-row justify-content-between pt-0"
                >
                  <h5 class="mb-0">Baggage and Cancellation Rules</h5>
                  <span class="close-button" @click="closeModal()"
                    >&times;</span
                  >
                </div>
                <div class="modal-body">
                  <v-card class="">
                    <v-tabs v-model="tab" align-tabs="center" stacked>
                      <v-tab value="tab-1">
                        <v-icon>mdi-bag-checked</v-icon>
                        Baggage
                      </v-tab>
                      <v-tab value="tab-2" class="m-0">
                        <v-icon>mdi-book-cancel-outline</v-icon>
                        Cancellation Rules
                      </v-tab>
                    </v-tabs>

                    <v-card-text class="pt-0">
                      <keep-alive>
                        <v-window v-model="tab">
                          <div
                            v-if="tab === 'tab-1'"
                            style="height: 204px; overflow-y: scroll"
                          >
                            <h6 class="mt-1">Baggage Rules:</h6>
                            <div v-if="rountresult">
                              <div
                                v-for="(res, index) of rountresult"
                                :key="index"
                              >
                                <div v-if="baggageModal === index">
                                  <div>
                                    <div
                                      style="font-size: 15px; font-weight: 500"
                                    >
                                      {{
                                        res.Depature[0].Departure.AirportCode
                                      }}
                                      -
                                      {{
                                        res.Depature[res.Depature.length - 1]
                                          .Arrival.AirportCode
                                      }}
                                    </div>
                                    <div class="ms-2 mt-2">
                                      <!-- <div >
                                  
                                </div> -->
                                      <div
                                        class="d-flex flex-row"
                                        style="
                                          font-size: 14px;
                                          font-weight: 400;
                                          margin-left: 5px;
                                        "
                                      >
                                        <span>
                                          <v-icon
                                            color="gray"
                                            style="color: gray !important"
                                          >
                                            mdi-bag-checked
                                          </v-icon></span
                                        >
                                        <span>Carry-On:</span>
                                        <span
                                          v-if="res.depBaggage"
                                          class="ms-1"
                                        >
                                          {{
                                            res.depBaggage.PieceAllowance
                                              .TotalQuantity > 0
                                              ? 1 + " Piece"
                                              : 1 + " Piece"
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="ms-2 mt-2">
                                      <!-- <div>
                                  
                                </div> -->
                                      <div
                                        class="d-flex flex-row"
                                        style="
                                          font-size: 14px;
                                          font-weight: 400;
                                          margin-left: 5px;
                                        "
                                      >
                                        <span>
                                          <v-icon
                                            color="gray"
                                            style="color: gray !important"
                                          >
                                            mdi-bag-suitcase
                                          </v-icon></span
                                        >
                                        <span> Checked Bag:</span>
                                        <span
                                          v-if="res.depBaggage"
                                          class="ms-1"
                                        >
                                          {{
                                            res.depBaggage.PieceAllowance
                                              .TotalQuantity || 0
                                          }}

                                          {{
                                            res.depBaggage.PieceAllowance
                                              .TotalQuantity > 1
                                              ? "Pieces"
                                              : "Piece"
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <hr
                                    v-if="res.Return"
                                    style="
                                      margin-top: 10px !important;
                                      margin-bottom: 10px !important;
                                    "
                                  />
                                  <div v-if="res.Return" class="mt-2">
                                    <div
                                      class="mt-1"
                                      style="font-size: 15px; font-weight: 500"
                                    >
                                      {{
                                        res.Depature[res.Depature.length - 1]
                                          .Arrival.AirportCode
                                      }}
                                      -
                                      {{
                                        res.Depature[0].Departure.AirportCode
                                      }}
                                    </div>
                                    <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                    <div class="ms-2 mt-2">
                                      <!-- <div >
                                  
                                </div> -->
                                      <div
                                        class="d-flex flex-row"
                                        style="
                                          font-size: 14px;
                                          font-weight: 400;
                                          margin-left: 5px;
                                        "
                                      >
                                        <span>
                                          <v-icon
                                            color="gray"
                                            style="color: gray !important"
                                          >
                                            mdi-bag-checked
                                          </v-icon></span
                                        >
                                        <span>Carry-On:</span>
                                        <span class="ms-1">
                                          {{
                                            res.arrBaggage.PieceAllowance
                                              .TotalQuantity > 0
                                              ? 1 + " Piece"
                                              : 1 + " Piece"
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="ms-2 mt-1">
                                      <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                      <div
                                        class="d-flex flex-row"
                                        style="
                                          font-size: 14px;
                                          font-weight: 400;
                                          margin-left: 5px;
                                        "
                                      >
                                        <span>
                                          <v-icon
                                            color="gray"
                                            style="color: gray !important"
                                          >
                                            mdi-bag-suitcase
                                          </v-icon></span
                                        >
                                        <span> Checked Bag:</span>
                                        <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                        <span class="ms-1">
                                          {{
                                            res.arrBaggage.PieceAllowance
                                              .TotalQuantity || 0
                                          }}
                                          {{
                                            res.arrBaggage.PieceAllowance
                                              .TotalQuantity > 1
                                              ? "Pieces"
                                              : "Piece"
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="onewayresulted">
                              <div
                                v-for="(res, index) of onewayresulted"
                                :key="index"
                              >
                                <div v-if="baggageModal === index">
                                  {{ console.log(res, "onewayresultedd") }}
                                  <div>
                                    <div
                                      style="font-size: 15px; font-weight: 500"
                                    >
                                      {{
                                        res.Depature[0].Departure.AirportCode
                                      }}
                                      -
                                      {{
                                        res.Depature[res.Depature.length - 1]
                                          .Arrival.AirportCode
                                      }}
                                    </div>
                                    <div class="ms-2 mt-2">
                                      <!-- <div >
                                    
                                  </div> -->
                                      <div
                                        class="d-flex flex-row"
                                        style="
                                          font-size: 14px;
                                          font-weight: 400;
                                          margin-left: 5px;
                                        "
                                      >
                                        <span>
                                          <v-icon
                                            color="gray"
                                            style="color: gray !important"
                                          >
                                            mdi-bag-checked
                                          </v-icon></span
                                        >
                                        <span>Carry-On:</span>
                                        <span
                                          v-if="res.BaggageData"
                                          class="ms-1"
                                        >
                                          {{
                                            res.BaggageData.PieceAllowance
                                              .TotalQuantity > 0
                                              ? 1 + " Piece"
                                              : 1 + " Piece"
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="ms-2 mt-2">
                                      <!-- <div>
                                    
                                  </div> -->
                                      <div
                                        class="d-flex flex-row"
                                        style="
                                          font-size: 14px;
                                          font-weight: 400;
                                          margin-left: 5px;
                                        "
                                      >
                                        <span>
                                          <v-icon
                                            color="gray"
                                            style="color: gray !important"
                                          >
                                            mdi-bag-suitcase
                                          </v-icon></span
                                        >
                                        <span> Checked Bag:</span>
                                        <span class="ms-1">
                                          {{
                                            res.BaggageData.PieceAllowance
                                              .TotalQuantity || 0
                                          }}

                                          {{
                                            res.BaggageData.PieceAllowance
                                              .TotalQuantity > 1
                                              ? "Pieces"
                                              : "Piece"
                                          }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="multicityData.length > 0">
                              <div
                                v-for="(res, index) of multicityData"
                                :key="index"
                              >
                                <div v-if="baggageModal === index">
                                  <div>
                                    <div class="mb-2">
                                      <div
                                        style="
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        {{
                                          res.Flight1[0].Departure.AirportCode
                                        }}
                                        -
                                        {{
                                          res.Flight1[res.Flight1.length - 1]
                                            .Arrival.AirportCode
                                        }}
                                      </div>
                                      <div class="ms-2 mt-2">
                                        <div
                                          class="d-flex flex-row"
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span>
                                            <v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-checked
                                            </v-icon></span
                                          >
                                          <span>Carry-On: </span>
                                          <span class="ms-1">{{
                                            res.BaggageData1.PieceAllowance
                                              .TotalQuantity > 0
                                              ? 1 + " Piece"
                                              : 1 + " Piece"
                                          }}</span>
                                        </div>
                                      </div>
                                      <div class="ms-2 mt-2">
                                        <div
                                          class="d-flex flex-row"
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span>
                                            <v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-suitcase
                                            </v-icon></span
                                          >
                                          <span> Checked Bag: </span>
                                          <span class="ms-1">
                                            {{
                                              res.BaggageData1.PieceAllowance
                                                .TotalQuantity || 0
                                            }}{{
                                              res.BaggageData1.PieceAllowance
                                                .TotalQuantity > 1
                                                ? " Pieces"
                                                : " Piece"
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <hr v-if="res.Flight2" />
                                    <div v-if="res.Flight2" class="mt-2 mb-2">
                                      <div
                                        class="mt-1"
                                        style="
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        {{
                                          res.Flight2[0].Departure.AirportCode
                                        }}
                                        -
                                        {{
                                          res.Flight2[res.Flight2.length - 1]
                                            .Arrival.AirportCode
                                        }}
                                      </div>
                                      <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                      <div class="ms-2 mt-2">
                                        <div
                                          class="d-flex flex-row"
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span>
                                            <v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-checked
                                            </v-icon></span
                                          >
                                          <span>Carry-On: </span>
                                          <span class="ms-1">{{
                                            res.BaggageData2.PieceAllowance
                                              .TotalQuantity > 0
                                              ? 1 + " Piece"
                                              : 1 + " Piece"
                                          }}</span>
                                        </div>
                                      </div>
                                      <div class="ms-2 mt-2">
                                        <div
                                          class="d-flex flex-row"
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span>
                                            <v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-suitcase
                                            </v-icon></span
                                          >
                                          <span> Checked Bag: </span>
                                          <span class="ms-1">
                                            {{
                                              res.BaggageData2.PieceAllowance
                                                .TotalQuantity || 0
                                            }}{{
                                              res.BaggageData2.PieceAllowance
                                                .TotalQuantity > 1
                                                ? " Pieces"
                                                : " Piece"
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <hr v-if="res.Flight3" />
                                    <div v-if="res.Flight3" class="mt-2 mb-2">
                                      <div
                                        class="mt-1"
                                        style="
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        {{
                                          res.Flight3[0].Departure.AirportCode
                                        }}
                                        -
                                        {{
                                          res.Flight3[res.Flight3.length - 1]
                                            .Arrival.AirportCode
                                        }}
                                      </div>
                                      <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                      <div class="ms-2 mt-2">
                                        <div
                                          class="d-flex flex-row"
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span>
                                            <v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-checked
                                            </v-icon></span
                                          >
                                          <span>Carry-On: </span>
                                          <span class="ms-1">{{
                                            res.BaggageData3.PieceAllowance
                                              .TotalQuantity > 0
                                              ? 1 + " Piece"
                                              : 1 + " Piece"
                                          }}</span>
                                        </div>
                                      </div>
                                      <div class="ms-2 mt-2">
                                        <div
                                          class="d-flex flex-row"
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span>
                                            <v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-suitcase
                                            </v-icon></span
                                          >
                                          <span> Checked Bag: </span>
                                          <span class="ms-1">
                                            {{
                                              res.BaggageData3.PieceAllowance
                                                .TotalQuantity || 0
                                            }}{{
                                              res.BaggageData3.PieceAllowance
                                                .TotalQuantity > 1
                                                ? " Pieces"
                                                : " Piece"
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <hr v-if="res.Flight4" />
                                    <div v-if="res.Flight4" class="mt-2">
                                      <div
                                        class="mt-1"
                                        style="
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        {{
                                          res.Flight4[0].Departure.AirportCode
                                        }}
                                        -
                                        {{
                                          res.Flight4[res.Flight4.length - 1]
                                            .Arrival.AirportCode
                                        }}
                                      </div>
                                      <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                      <div class="ms-2 mt-2">
                                        <div
                                          class="d-flex flex-row"
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span>
                                            <v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-checked
                                            </v-icon></span
                                          >
                                          <span>Carry-On: </span>
                                          <span class="ms-1">{{
                                            res.BaggageData4.PieceAllowance
                                              .TotalQuantity > 0
                                              ? 1 + " Piece"
                                              : 1 + " Piece"
                                          }}</span>
                                        </div>
                                      </div>
                                      <div class="d-flex ms-2 mt-2">
                                        <div
                                          style="
                                            font-size: 14px;
                                            font-weight: 400;
                                            margin-left: 5px;
                                          "
                                        >
                                          <span
                                            ><v-icon
                                              color="gray"
                                              style="color: gray !important"
                                            >
                                              mdi-bag-suitcase
                                            </v-icon></span
                                          >
                                          <span> Checked Bag: </span>
                                          <span class="ms-1">
                                            {{
                                              res.BaggageData4.PieceAllowance
                                                .TotalQuantity || 0
                                            }}{{
                                              res.BaggageData4.PieceAllowance
                                                .TotalQuantity > 1
                                                ? " Pieces"
                                                : " Piece"
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="tab === 'tab-2'"
                            style="height: 204px; overflow-y: scroll"
                          >
                            <div
                              v-if="fareLoader"
                              class="text-center mt-5"
                              style="margin: auto"
                            >
                              Please wait...
                              <v-progress-circular
                                color="primary"
                                class="ms-1"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                            <div v-else>
                              <h6 class="mt-2">Cancellation Rules:</h6>
                              <div class="p-2 cancellationRules">
                                <div v-html="fareRulesContent"></div>
                              </div>
                            </div>
                          </div>
                        </v-window>
                      </keep-alive>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="searchloader">
    <loader></loader>
  </div>
  <div v-if="pageError">
    <pageError></pageError>
  </div>
  <div v-if="pageLoad" persistent>
    <pageloaded />
  </div>
  <v-dialog v-model="popup" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h5 class="text-center">Please wait while we confirm the fare and seat availability....</h5>
        <div class="spinner-border spinner-border-md"
          style="display: flex;align-items: center;justify-content:center;margin:auto;;color: #124d85;"
          role="status"
         ></div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="pricedialog" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h5 class="text-center">{{ priceText }}</h5>
        <span class="d-flex flex-row align-center">Old Fare: <h6 class="ms-2 m-0">{{ displayCurrency }} {{priceChangeValue.TotalPrice}}</h6></span>
        <span class="d-flex flex-row align-center">New Fare: <h6 class="ms-2 m-0">{{ displayCurrency }} {{priceRes.payload.itin_total_fare}}</h6></span>
        <br>
        <span class="d-flex flex-row align-center">Agent Markup: <h6 class="ms-2 m-0">{{ displayCurrency }} {{priceChangeValue.instantvalue == null ? "0" : priceChangeValue.instantvalue}}</h6></span>
        <br>
        <span> Do you want to proceed with the booking?</span>

        <div class="d-flex justify-space-between mt-5">
          <v-btn color="#faa41f" @click="priceConfirm(1)">Continue To Book</v-btn>
          <v-btn color="#faa41f" @click="pricedialog = false">Select Another Flight</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="priceError" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h6 class="text-center">
          We are unable to confirm the price and seat availability at this
          moment.
        </h6>
        <h6 class="text-center">
          Please retry booking the flight after some time.
        </h6>

        <div class="d-flex justify-center mt-5">
          <v-btn color="#faa41f" @click="resetBooking()"
            >Book another flight</v-btn
          >
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="flightpage" persistent>
    <v-card>
      <div
        style="
          cursor: pointer;
          display: flex;
          justify-content: end;
          padding: 3px 7px;
        "
      >
        <v-icon
          class="ms-2"
          @click="flightpage = false"
          size="30px"
          color="red"
        >
          mdi-close-circle
        </v-icon>
      </div>
      <div>
        <flightInfo :info="flightInfoData" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from "@/components/dataLoder.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import flightInfo from "../components/flightInfo.vue";
// import AutoComplete from "primevue/autocomplete";
import axios from "axios";
const totalPricefilter = require("lodash");
import pageError from "@/components/pageError.vue";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import pageloaded from "@/components/pageLoader.vue";

export default {
  components: {
    VueDatePicker,
    // AutoComplete,
    loader,
    pageError,
    flightInfo,
    pageloaded,
  },

  data() {
    return {
      dates: [
        "2024-09-26",
        "2024-09-27",
        "2024-09-28",
        "2024-09-29",
        "2024-09-30",
        "2024-10-01",
        "2024-10-02",
      ],

      selectCalenterDate: [],
      flightpage: false,
      advanceDetailData: {},
      datashort: 0,
      returnDate: "",
      pricedialog: false,
      priceRes:null,
      popup:false,
      priceError: false,
      priceapiData: "",
      priceText: "",
      priceChangeValue: "",
      dateArray: [],
      addOption: true,
      dateArray1: [],
      tempArray: [],
      tempArray1: [],
      flight1Date: [],
      flight2Date: [],
      flight3Date: [],
      flight4Date: [],
      flightDate1: "",
      flightDate2: "",
      flightDate3: "",
      flightDate4: "",
      selectedStops: "",
      // layDuration: { min: 6, max: 12 },
      layDuration: null,
      AvoidCountry: [],
      alterDays: [],
      selectedDays: "",
      advanceOptionvalue: "",
      days: [
        { label: "+/- 1 Days", value: "1", valdata: false },
        { label: "+/- 2 Days", value: "2", valdata: false },
        { label: "+/- 3 Days", value: "3", valdata: false },
        { label: "+/- 7 Days", value: "7", valdata: false },
      ],
      // selectedFareTypes: ["PUB", "NET"],
      selectedFareTypes: [],
      selectedPenalties: [],
      includeExclude: "include",
      formattedAirportInput: "",
      formattedAirportInput1: "",
      airportInput: "",
      includedAirports: [],
      excludedAirports: [],
      airlineval: false,
      airlineval1: false,
      airlineIncludeExclude: "",
      airlineIncludeExclude1: "",
      airex: null,
      airlineInput: "",
      includedAirlines: [],
      excludedAirlines: [],

      modalTab: false,
      tab: null,
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",
      filterView: false,
      pageError: false,
      dialog: false,
      fareOpen: false,
      multiCalender: true,
      totaladult: 0,
      classSelect: false,
      triggerRadio: false,
      vj: "",
      modifySection: true,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: "dd MMM yyyy",
      items: [],
      airlogodata: [],
      oneway1: false,
      roundtrip: false,
      multitrip: false,
      multicityTotalData: [],
      searchresult: false,
      searchloader: true,
      pageLoad: false,
      isIcon1: true,
      fareIcon: true,
      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      bookingRadio: "roundone",
      displayDate: false,
      // red1: "roundone",
      // paxhide: true,
      // tabSelect:null,
      // validMarkupform:false,

      roundModify: true,
      onewayModify: false,
      multiModify: false,

      find1: [],
      find2: [],

      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      offerData5: [],

      returnData: [],
      Oneway: false,
      rount: true,
      multicity: false,
      travelHistory: false,
      RecentSearchData: [],
      recendFrom: [],

      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      uniqueId: [],
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      codeData: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      multicityData: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      localData: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      multiFilterData: [],
      multishowData: [],
      routtime: [],
      airportname: "",
      traveltime: "",
      Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],

      agentId: "",
      agencyId: "",
      domainPos: "",
      domainName: "",
      domainCurrency: "",
      sessionId: "",
      platformSrc: "",
      loginType: "",
      accessLevel: 0,
      // accessLevel: "",

      fareType: [],
      // fareType: [],

      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      addition: 0,
      classType: "Economy",
      classTypeitems: [
        {
          text: "Economy",
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: "Prem. Economy",
          value: "PremiumEconomy",
          department: "PremiumEconomy Department",
        },
        {
          text: "Business",
          value: "Business",
          department: "Business Department",
        },
        {
          text: "Prem. Business",
          value: "PremiumBusiness",
          department: "PremiumBusiness Department",
        },
        {
          text: "First Class",
          value: "First Class",
          department: "First Class Department",
        },
        {
          text: "Prem. First ",
          value: "PremiumFirst",
          department: "PremiumFirst Department",
        },
        // { text: this.$t('homePageContent.contentSection.premiumEconomy'), value: 'Premium Economy', department: 'Premium Economy Department' },
      ],

      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "roundone",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      data_id: "",
      roundOfferId: [],
      airlineList: [],
      airlineArr: [],
      oneway_id: "",
      onewayOfferId: [],

      responseData: [],
      clicked1: false,
      clicked2: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      storefilterData: [],

      showNonStop: false,
      showOneStop: false,
      showOneplusStop: false,
      recperperson: "",
      recCurrecy: "",
      shotperperson: "",
      layperperson: "",
      shorCurrecy: "",
      active: false,
      active1: false,
      active2: false,
      active3: false,
      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,
      RefundableData: false,
      nonRefundableData: false,
      radiosData: 0,
      radioData1: 0,
      radioData2: 0,
      radioData3: 0,
      radioData4: 0,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      flightdetailsData: [],
      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      totalpassenger: [],
      onewaymultiData: [],
      rountmultiData: [],
      airCanada: false,
      qatarAirline: false,
      // layoverTimes: [],
      // layoverTimes1: [],

      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight5: [],
      resulted: [],
      onedatafil: false,
      rounddatafil: false,
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      multiplesameData: [],
      multiDepatureData: [],
      multireturnData: [],
      multiflight1: [],
      multiflight2: [],
      multiflight3: [],
      multiflight4: [],
      timearrFilterData1: false,
      timearrFilterData2: false,
      timearrFilterData3: false,
      timearrFilterArrData1: false,
      timearrFilterArrData2: false,
      timearrFilterArrData3: false,
      totalMinutesT1: "",
      totalMinutesT2: "",
      preferCurrency: "",
      metaCurrency: "",
      portalId: "",
      displayCurrency: "",

      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "ECONOMY",
              Currency: "",
              FareType: [],
              CalendarSearch: "",
              CalendarSearchDays: "",
              IncludedAirlines: [],
              ExcludedAirlines: [],
              IncludedViaStops: [],
              ExcludedViaStops: [],
              Stops: [],
              AvoidCountry: [],
              LayoverHours: [],
              Penalities: [],
            },
            MetaData: {
              Currency: "",
              PointOfSaleCurrency: "",
              PointOfSale: "",
            },
          },
        },
        agentid: "",
        agencyid: "",
        agentemail: "",
        sessionid: "",
        PlatformSource: "",
        login_type: "",
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "ECONOMY",
              Currency: "",
              FareType: [],
              CalendarSearch: "",
              CalendarSearchDays: "",
              IncludedAirlines: [],
              ExcludedAirlines: [],
              IncludedViaStops: [],
              ExcludedViaStops: [],
              Stops: [],
              AvoidCountry: [],
              LayoverHours: [],
              Penalities: [],
            },
            MetaData: {
              Currency: "",
              PointOfSaleCurrency: "",
              PointOfSale: "",
            },
          },
        },
        agentid: "",
        agencyid: "",
        agentemail: "",
        sessionid: "",
        PlatformSource: "",
        login_type: "",
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      MulticityReq: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [],
              },
            },
            Preference: {
              TripType: "MULTICITY",
              CabinType: "ECONOMY",
              Currency: "",
              FareType: [],
              CalendarSearch: "",
              CalendarSearchDays: "",
              IncludedAirlines: [],
              ExcludedAirlines: [],
              IncludedViaStops: [],
              ExcludedViaStops: [],
              Stops: [],
              AvoidCountry: [],
              LayoverHours: [],
              Penalities: [],
            },
            MetaData: {
              Currency: "",
              PointOfSaleCurrency: "",
              PointOfSale: "",
            },
          },
        },
        agentid: "",
        agencyid: "",
        agentemail: "",
        sessionid: "",
        PlatformSource: "",
        login_type: "",
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      itinaryRequest: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "RoundTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      itinaryRequestoneway: {
        SearchClass: "",
        OriginCity1: "",
        DestinationCity1: "",
        OriginCity2: "",
        DestinationCity2: "",
        TripType: "Oneway",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },
      agentTotalFare: "",
      formMultiData: [],
      FilterTimeDepname: "",
      FilterTimearrname: "",
      multi_city: {
        itinery: [
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "multithree",
      },

      showMulti_data: {},

      addmore: true,
      clearall: false,
      deleteSeg: true,

      autocompleteUrl: "",
      makeSearchUrl: "",
      instantApi: "",
      airLogoApi: [],

      airline_logoData: [],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      readMore: false,
      maxItemsToShow: 5,
      filterAirline: [],

      agentcommisionReq: {
        service_type: "applyInstantMarkup",
        api_ver: "1",
        portal_id: "",
        payload: {
          shopping_response_id: "",
          offer_id: "",
          total_price_per_person: null,
          total_price_itin: null,
          instant_add_type: "markup",
          instant_add_value: 0,
          instant_add_currency: "CAD",
        },
      },

      getCommission: "",
      fareRulesApi: "",
      pricingApi: "",
      baggageModal: null,
      fareLoader: false,
      urldedate: "",
      urlredate: "",
      roundfrom: "",
      roundto: "",
      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,
      fromloader: false,
      toloader: false,
      multidrop: [],
      multidrop1: [],
      activeDropdown: null,
      advaceData: {},
      selectedFrom: false,
      selectedTo: false,
      // getTotalfare:"",
      // getAgentCommission:""
    };
  },

  beforeUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },

  methods: {
    handleOutsideClick(event) {
      // Close the dropdown if the click is outside any dropdown
      const dropdowns = document.querySelectorAll(".dropdown");
      let clickedInside = false;

      dropdowns.forEach((dropdown) => {
        if (dropdown.contains(event.target)) {
          clickedInside = true;
        }
      });

      if (!clickedInside) {
        this.activeDropdown = null;
      }
    },
    minDate() {
      const today = new Date();
      // Setting time to 00:00:00 to avoid timezone issues
      return new Date(
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
      )
        .toISOString()
        .split("T")[0];
    },
    maxDate() {
      const today = new Date();
      return new Date(
        Date.UTC(today.getFullYear() + 1, today.getMonth(), today.getDate())
      )
        .toISOString()
        .split("T")[0];
    },
    handleCheck(value) {
      // this.selectedStops = value;
      if (this.selectedStops === value) {
        this.selectedStops = "";
      } else {
        this.selectedStops = value;
      }

      // console.log('Selected Stops:', this.selectedStops);
    },
    handleLayDuration(value) {
      if (
        this.layDuration &&
        this.layDuration.min === value.min &&
        this.layDuration.max === value.max
      ) {
        this.layDuration = null;
      } else {
        this.layDuration = { min: value.min, max: value.max };
      }

      // console.log('layDuration', this.layDuration);
    },

    toggleDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null;
      } else {
        this.activeDropdown = dropdownName;
        // this.isDropdownActive(false);
      }
    },
    isDropdownActive(dropdownName) {
      return this.activeDropdown === dropdownName;
    },
    filterIcon() {
      let obj = {};

      obj.includedAirlines = this.includedAirlines;
      obj.excludedAirlines = this.excludedAirlines;
      obj.includedAirports = this.includedAirports;
      obj.excludedAirports = this.excludedAirports;
      obj.selectedDays = this.selectedDays;
      obj.selectedStops = this.selectedStops;
      obj.layDuration =
        this.layDuration && Object.keys(this.layDuration).length > 0;
      obj.AvoidCountry = this.AvoidCountry;
      obj.selectedPenalties = this.selectedPenalties;
      obj.selectedFareTypes = this.selectedFareTypes || this.fareType;

      return Object.values(obj).some((value) => {
        return Array.isArray(value) ? value.length > 0 : !!value;
      });
    },

    localDataValue() {
      let obj = {};

      obj.includedAirlines = this.includedAirlines;
      obj.excludedAirlines = this.excludedAirlines;
      obj.includedAirports = this.includedAirports;
      obj.excludedAirports = this.excludedAirports;
      obj.selectedDays = this.selectedDays;
      obj.selectedStops = this.selectedStops;
      obj.layDuration = this.layDuration;
      obj.AvoidCountry = this.AvoidCountry;
      obj.selectedPenalties = this.selectedPenalties;
      obj.selectedFareTypes = this.selectedFareTypes;

      this.advaceData = obj;

      // localStorage.setItem("advanceOption", JSON.stringify(obj));
    },
    calculateDatesmulti() {
      console.log(this.multi_city, "this.multi_city");
      let $option = JSON.parse(localStorage.getItem("advanceOption")) || {};
      console.log($option, "$option$option");
      const daysRange = $option.selectedDays || 0;

      if (this.multi_city && this.multi_city.itinery) {
        if (this.multi_city.itinery[0] && this.multi_city.itinery[0].depdata) {
          this.flight1Date = this.generateDateRange(
            this.multi_city.itinery[0].depdata,
            daysRange
          );
          this.flightDate1 = this.flight1Date[0];
        }

        if (this.multi_city.itinery[1] && this.multi_city.itinery[1].depdata) {
          this.flight2Date = this.generateDateRange(
            this.multi_city.itinery[1].depdata,
            daysRange
          );
          this.flightDate2 = this.flight2Date[0];
        }

        if (this.multi_city.itinery[2] && this.multi_city.itinery[2].depdata) {
          this.flight3Date = this.generateDateRange(
            this.multi_city.itinery[2].depdata,
            daysRange
          );
          this.flightDate3 = this.flight3Date[0];
        }

        if (this.multi_city.itinery[3] && this.multi_city.itinery[3].depdata) {
          this.flight4Date = this.generateDateRange(
            this.multi_city.itinery[3].depdata,
            daysRange
          );
          this.flightDate4 = this.flight4Date[0];
        }
      }
    },

    generateDateRange(baseDate, daysRange) {
      let tempArray = [];
      let date = new Date(baseDate);

      for (let i = -daysRange; i <= daysRange; i++) {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + i);
        tempArray.push(newDate.toISOString().split("T")[0]);
      }

      return tempArray;
    },

    calculateDates() {
      // this.tempArray1 = [];
      // this.tempArray = [];
      const daysRange =
        (this.advanceDetailData && this.advanceDetailData.selectedDays) || "";
      console.log(daysRange, "daysRange");

      let baseDate = new Date(this.$route.query.dedate);
      let baseDate1 = new Date(this.$route.query.redate);
      for (let i = -daysRange; i <= daysRange; i++) {
        const newDate = new Date(baseDate);
        newDate.setDate(baseDate.getDate() + i);
        this.tempArray.push({
          Date: newDate.toISOString().split("T")[0],
          price: "0",
          valueData: false,
          logo: "mdi-magnify",
        });
      }
      if (this.$route.query.city == "roundone") {
        for (let i = -daysRange; i <= daysRange; i++) {
          const newDate = new Date(baseDate1);
          newDate.setDate(baseDate1.getDate() + i);
          this.tempArray1.push({
            Date: newDate.toISOString().split("T")[0],
            price: "0",
            valueData: false,
            logo: "mdi-magnify",
          });
        }
        console.log(this.dateArray1, "this.dateArray");
      }

      console.log(this.tempArray1, this.tempArray, "this.tempArray222");

      if (this.onewayfilterData.length > 0) {
        this.tempArray.forEach((a, i) => {
          this.onewayfilterData.forEach((c) => {
            c.Depature.forEach((e) => {
              if (e.Departure.Date === a.Date) {
                console.log(c.ownerlogo, "owner");
                this.tempArray[i].price = a.price == 0 ? c.TotalPrice : a.price;
                this.tempArray[i].logo =
                  a.price !== 0 ? c.ownerlogo : "mdi-magnify";
                this.tempArray[i].valueData = true;
              }
            });
          });
        });

        this.dateArray = this.tempArray;
        console.log(this.tempArray, "this.tempArray");
        this.calCheckDateOne();
      } else if (this.filterData.length > 0) {
        console.log(this.tempArray1, "this.tempArray1");
        this.tempArray.forEach((a) => {
          let $data = [];

          this.tempArray1.forEach((b) => {
            let $obj = {
              price: 0,
              logo: "mdi-magnify",
              valueData: false,
              returnDate: b.Date,
              depdate: a.Date,
              clickValue: false,
            };

            let matchFound = false;

            this.filterData.forEach((c) => {
              if (c.Return && c.Depature) {
                c.Depature.forEach((d) => {
                  c.Return.forEach((e) => {
                    if (
                      a.Date === d.Departure.Date &&
                      b.Date === e.Departure.Date
                    ) {
                      // console.log(a.Date, b.Date, c, "ammmamama");/

                      $obj.price = $obj.price == 0 ? c.TotalPrice : $obj.price;
                      $obj.logo = b.price !== 0 ? c.ownerlogo : "mdi-magnify";
                      $obj.valueData = true;
                      matchFound = true;
                    }
                  });
                });
              }
            });

            if (!matchFound) {
              $obj.price = "0";
              $obj.logo = "mdi-magnify";
              $obj.valueData = false;
            }
            $data.push({ ...$obj });
          });

          this.dateArray1.push({
            flights: $data,
          });
        });

        this.calCheckDateReturn();
      }
      if (this.dateArray1.length > 0) {
        this.updateLowestPrices();
      }
      console.log(this.dateArray1, "this.dateArray1");
      this.selectCalenterDate.sort((a, b) => a.price - b.price);
    },

    calCheckDateReturn() {
      this.rountresult.forEach((a) => {
        a.Depature.forEach((d) => {
          a.Return.forEach((r) => {
            this.dateArray1.forEach((v) => {
              v.flights.forEach((f) => {
                if (
                  d.Departure.Date === f.depdate &&
                  r.Departure.Date === f.returnDate &&
                  this.selectCalenterDate.length < 4
                ) {
                  const alreadyExists = this.selectCalenterDate.some(
                    (q) =>
                      q.depdate === f.depdate && q.returnDate === f.returnDate
                  );

                  if (!alreadyExists) {
                    this.selectCalenterDate.push(f);
                  }
                }
              });
            });
          });
        });
      });
      console.log(this.selectCalenterDate, "selectCalenterDate");
    },

    calCheckDateOne() {
      this.onewayfilterData.forEach((a) => {
        a.Depature.forEach((d) => {
          this.dateArray.forEach((v) => {
            if (
              d.Departure.Date === v.Date &&
              this.selectCalenterDate.length < 4
            ) {
              const alreadyExists = this.selectCalenterDate.some(
                (q) => q.Date === v.Date
              );

              if (!alreadyExists) {
                this.selectCalenterDate.push(v);
              }
            }
          });
        });
      });
      console.log(this.selectCalenterDate, "selectCalenterDate");
    },

    updateLowestPrices() {
      // Iterate over each flight set in dateArray1
      this.dateArray1.forEach((flightSet) => {
        // Extract prices, ensuring to convert them to numbers and filter out zero prices
        const prices = flightSet.flights
          .map((flight) => parseFloat(flight.price.replace(/[^0-9.-]+/g, "")))
          .filter((price) => price > 0); // Only consider prices greater than zero

        // Check if there are valid prices
        if (prices.length > 0) {
          // Find the lowest price among valid prices
          const lowestPrice = Math.min(...prices);

          // Iterate over each flight in the current flight set
          flightSet.flights.forEach((flight) => {
            const flightPrice = parseFloat(
              flight.price.replace(/[^0-9.-]+/g, "")
            );

            // Mark only the flight with the lowest price
            flight.lowpriceValue = flightPrice === lowestPrice; // This will set it to true if it's the lowest, else false
          });
        } else {
          // If no valid prices, set lowpriceValue to false for all flights
          flightSet.flights.forEach((flight) => {
            flight.lowpriceValue = false; // All flights are not the lowest
          });
        }
      });
    },

    // calDatesearch(data, index) {
    //   console.log(data.Date, index, "bububuuuuu");
    //   this.tempArray1.forEach((c) => {
    //     if (c.Date == data.Date) {
    //       console.log("ccccc", c.Date);
    //       return c.Date;
    //     } else {
    //       console.log("ccccc", c.Date);
    //     }
    //   });
    // },
    // calDatesearch(data) {
    //   console.log(data.Date, "bububuuuuu");

    //   this.tempArray1.forEach((c) => {
    //     console.log(c.Date, data.returnDate, "uyuyuyuyuy");
    //     if (c.Date === data.returnDate) {
    //       console.log("Match found:", c.Date);
    //       return c.Date;
    //     } else {
    //       console.log("No match for:", c.Date);
    //       return false;
    //     }
    //   });
    // },
    calDatesearch(data) {
      let foundDate = null;

      this.tempArray1.forEach((c) => {
        if (c.Date === data.returnDate) {
          console.log("Match found:", c.Date);
          foundDate = c.Date;
        }
      });
      return foundDate ? foundDate : "No match";
    },

    datecalMultiData() {
      let $event = [];
      console.log(this.multiFilterData, "multiFilterData");

      this.multiFilterData.forEach((a) => {
        if (a.Flight1 && a.Flight2 && (a.Flight3 || a.Flight4)) {
          a.Flight1.forEach((b) => {
            a.Flight2.forEach((c) => {
              let matchFlight3 =
                !a.Flight3 ||
                a.Flight3.some((d) => d.Departure.Date === this.flightDate3);
              let matchFlight4 =
                !a.Flight4 ||
                a.Flight4.some((e) => e.Departure.Date === this.flightDate4);

              if (
                b.Departure.Date === this.flightDate1 &&
                c.Departure.Date === this.flightDate2 &&
                matchFlight3 &&
                matchFlight4
              ) {
                $event.push(a);
              }
            });
          });
        }
      });

      this.multicityData = $event;
      console.log(this.multicityData, "Filtered Data");
    },

    datecalData($data, value, index) {
      let $event = [];
      // console.log($data, " this.datashort");
      if (!$data.valueData && value) {
        this.searchCalDate($data, 1);
      } else {
        if (this.onewayfilterData.length > 0 && $data.valueData) {
          this.onewayfilterData.forEach((a) => {
            a.Depature.forEach((b) => {
              if (b.Departure.Date === this.datashort) {
                console.log(b.Departure.Date, this.datashort, a, "bbb");
                $event.push(a);
              }
            });
          });
          if (this.selectCalenterDate.length < 4 && value) {
            // this.selectCalenterDate.push($data);
          } else {
            this.selectCalenterDate.filter((v, i) =>
              i == index
                ? ((v.valueData = !v.valueData), (this.datashort = v.Date))
                : (v.valueData = true)
            );
            console.log(this.datashort, "this.datashort");
          }
          console.log(this.selectCalenterDate, "this.selectCalenterDate");

          this.onewayresulted = $event;
        }
      }
    },
    datecalData1(flight, value, index) {
      if (flight.valueData) {
        let $event = [];
        console.log(flight, value, index, " this.datashort");
        this.dateArray1.forEach((c) => {
          c.flights.forEach((f) => {
            f.clickValue = false;
          });
        });
        flight.clickValue = !flight.clickValue;

        this.filterData.forEach((a) => {
          a.Depature.forEach((b) => {
            a.Return.forEach((c) => {
              if (
                b.Departure.Date === flight.depdate &&
                c.Departure.Date === flight.returnDate
              ) {
                $event.push(a);
              }
            });
          });
        });
        if (this.selectCalenterDate.length < 4 && value) {
          //  this.selectCalenterDate.push(flight);
        } else {
          this.selectCalenterDate.filter((v, i) =>
            i == index ? (v.valueData = !v.valueData) : (v.valueData = true)
          );
        }

        console.log(this.selectCalenterDate, "this.selectCalenterDate");

        this.rountresult = $event;
      } else if (!flight.valueData && value) {
        this.searchCalDate(flight, 2);
      }
    },

    searchCalDate(data, event) {
      console.log("data232", data);
      this.inputCity =
        this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      this.inputCityto =
        this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);
      this.onewayfilterData = [];
      this.rountresult = [];
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("recentPrefillData");

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.bookingRadio;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;

        this.searchloader = true;

        let result1 = [];

        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        if (event == 1) {
          this.urldedate = moment(data.Date).format("YYYY-MM-DD");
        } else {
          this.urldedate = moment(data.depdate).format("YYYY-MM-DD");
          this.urlredate = moment(data.returnDate).format("YYYY-MM-DD");
        }

        let rountdetail = {
          from: this.inputCity,
          to: this.inputCityto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
          agentid: this.agentId,
          agencyid: this.agencyId,
          agentemail: this.agentEmail,
          sessionid: this.sessionId,
          domain: this.domainName,
          pos: this.domainPos,
          currency: this.domainCurrency,
          platform_src: this.platformSrc,
          login_type: this.loginType,
          access_level: this.accessLevel,
          fare_type: this.fareType,
        };
        if (event == 1) {
          delete rountdetail.redate;
        }
        console.log(rountdetail, "rountdetailrountdetail");
        const resultData = this.handleRecentSearch();

        localStorage.setItem("recentSearchData", JSON.stringify(resultData));

        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        this.validateOnewayForm();
      }
    },

    clearadOption() {
      this.includedAirlines = [];
      this.excludedAirlines = [];
      this.selectedStops = "";
      this.AvoidCountry = [];
      this.includedAirports = [];
      this.excludedAirports = [];
      (this.selectedDays = ""), (this.selectedFareTypes = []);
      this.selectedPenalties = [];

      this.includeExclude = "include";
      this.airlineIncludeExclude = "include";
      // this.selectedStops
      this.formattedAirportInput1 = "";
      // this.selectedStops
      this.layDuration = "";
      this.formattedAirportInput = "";
      this.AvoidCountry = [];
    },

    addAirline() {
      console.log(this.airlineInput, "this.airlineInput");

      if (this.airlineInput.trim()) {
        const airlinePairs = this.airlineInput
          .toUpperCase()
          .match(/.{1,2}/g)
          .join(",");

        console.log(airlinePairs, "airlines formatted");

        if (this.airlineIncludeExclude === "include") {
          this.includedAirlines = [];
          this.includedAirlines.push(airlinePairs);
        } else if (this.airlineIncludeExclude === "exclude") {
          this.excludedAirlines = []; // Clear the array before adding new airlines
          this.excludedAirlines.push(airlinePairs);
        }

        console.log(
          this.includedAirlines,
          this.excludedAirlines,
          "formatted airlines"
        );

        // Clear the input field after submission
        // this.airlineInput = "";
      }
    },

    // updateAirportInput(event) {
    //   console.log(event,'updateAirportInput')
    //   let input = event.target.value.replace(/[^a-zA-Z]/g, "");
    //   let formatted = input.match(/.{1,2}/g)?.join(", ") || "";
    //   this.formattedAirportInput = formatted;
    //   console.log(this.formattedAirportInput, "this.formattedAirportInput");
    //   this.airportInput = input;
    //   this.formattedAirportInput = "";
    //   console.log(this.formattedAirportInput, "this.formattedAirportInput222");
    // },
    updateAirportInput(event) {
      console.log(event, "updateAirportInput");
      let input = event.target.value.replace(/[^a-zA-Z]/g, "");
      let formatted = input.match(/.{1,3}/g)?.join(", ") || "";

      this.formattedAirportInput = formatted.toUpperCase();
      this.airportInput = input;
    },

    // updateAirportInput1($event, $num) {
    //   console.log(event.data, "ll");
    //   let input = $event.replace(/[^a-zA-Z]/g, "");
    //   let formatted = input.match(/.{1,2}/g)?.join(", ") || "";
    //   if ($num == 1) {
    //     this.airlineIncludeExclude = formatted.toUpperCase().trim();
    //   } else {
    //     this.airlineIncludeExclude1 = formatted.toUpperCase().trim();
    //   }

    //   console.log(
    //     this.airlineIncludeExclude.trim(),
    //     "this.airlineIncludeExclude"
    //   );
    // },
    // updateAirportInput1($event,value, $num) {
    //   console.log($event, value, $num,'AirlinesData...1')
    //   const inputElement = value.target; // Get the input element
    // let cursorPosition = inputElement.selectionStart;
    //   console.log(cursorPosition,'dhghgedhgkekh')
    //   let input = $event.replace(/[^a-zA-Z]\s/g, "");
    //   let formatted = input.match(/.{1,2}/g)?.join(", ") || "";

      
    //   if ($num === 1) {
    //     this.airlineIncludeExclude = formatted.toUpperCase().trim();
    //   } else {
    //     this.airlineIncludeExclude1 = formatted.toUpperCase().trim();
    //   }
      
    // },

    // validateInput(inputValue, num,$event) {
    //   console.log(inputValue, num ,$event, "AirlinesData...2")
    //   const normalizedInput = inputValue.replace(/\s/g, "").toUpperCase();

    //   const uniqueCodes = new Set();
    //   let filteredInput = "";
    //   const codes = normalizedInput.split(",").map((code) => code.trim());

    //   codes.forEach((code) => {
    //     if (code.length > 0) {
    //       if (!uniqueCodes.has(code)) {
    //         uniqueCodes.add(code);
    //         filteredInput += (filteredInput ? ", " : "") + code;
    //       }
    //     }
    //   });

    //   if (num === 1) {
    //     this.airlineIncludeExclude = filteredInput;
    //   } else {
    //     this.airlineIncludeExclude1 = filteredInput;
    //   }
    // },
      handleAirportInput(event, num) {
      const inputElement = event.target;
      let cursorPosition = inputElement.selectionStart;

      let rawInput = inputElement.value.toUpperCase(); 

      let sanitizedInput = rawInput.replace(/[^A-Z]/g, '');

      let codes = sanitizedInput.match(/.{1,2}/g) || [];

      let uniqueCodes = [...new Set(codes)];

      let formatted = uniqueCodes.join(", ");

      let charsAdded = formatted.length - rawInput.length;

      if (num === 1) {
        this.airlineIncludeExclude = formatted;
      } else {
        this.airlineIncludeExclude1 = formatted;
      }

      this.$nextTick(() => {
        const newCursorPosition = cursorPosition + charsAdded;
        inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
      });
    },
  
    addAirport() {
      if (this.airportInput) {
        let airportPairs =
          this.airportInput.toUpperCase().match(/.{1,3}/g) || [];

        let count = airportPairs[airportPairs.length - 1].length;
        if (count == 3) {
          this.toggleDropdown("airlinesooo");
          this.airlineval1 = false;
          if (this.includeExclude === "include") {
            this.excludedAirports = [];
            airportPairs.forEach((code) => {
              if (!this.includedAirports.includes(code)) {
                this.includedAirports.push(code);
              }
            });
          } else if (this.includeExclude === "exclude") {
            this.includedAirports = [];
            airportPairs.forEach((code) => {
              if (!this.excludedAirports.includes(code)) {
                this.excludedAirports.push(code);
              }
            });
          }

          // this.airportInput = "";
          // this.formattedAirportInput = "";

          // Logging the results
          console.log("Included Airports:", this.includedAirports);
          console.log("Excluded Airports:", this.excludedAirports);
        } else {
          this.airlineval1 = true;
        }
      } else {
        this.toggleDropdown("airlinesooo");
      }
    },
    addAirport1() {
      let airlineCodes = (
        this.airex ? this.airlineIncludeExclude : this.airlineIncludeExclude1
      )
        .toUpperCase()
        .split(",")
        .map((code) => code.trim())
        .filter((code) => code.length === 2);

      if (this.airex) {
        this.excludedAirlines = [];
        this.includedAirlines = this.includedAirlines.filter((code) =>
          airlineCodes.includes(code)
        );
        airlineCodes.forEach((code) => {
          if (!this.includedAirlines.includes(code)) {
            this.includedAirlines.push(code);
          }
        });
        this.airlineIncludeExclude1 = "";
      } else {
        this.includedAirlines = [];
        this.excludedAirlines = this.excludedAirlines.filter((code) =>
          airlineCodes.includes(code)
        );
        airlineCodes.forEach((code) => {
          if (!this.excludedAirlines.includes(code)) {
            this.excludedAirlines.push(code);
          }
        });
        this.airlineIncludeExclude = "";
      }

      this.toggleDropdown("airlines");

      console.log("Included Airlines:", this.includedAirlines);
      console.log("Excluded Airlines:", this.excludedAirlines);
      // if (this.airex) {
      //   this.ailineSetUp(this.airlineIncludeExclude);
      // } else {
      //   this.ailineSetUp(this.airlineIncludeExclude1);
      // }
    },

    ailineSetUp(event) {
      let $event = event.split(" ").join("");
      console.log($event, this.airex, "$event");

      if ($event) {
        let $data = [];
        $data = $event.split(",");
        let uniqueData = [...new Set($data)];
        let count = uniqueData[uniqueData.length - 1].length;
        console.log(count, $data, $data[$data.length - 1].length, "000");
        if (this.airex && count == 2) {
          this.includedAirlines = [];
          uniqueData.forEach((airline) => {
            if (!this.includedAirlines.includes(airline)) {
              this.includedAirlines.push(airline);
            }
          });
          this.toggleDropdown("airlines");
          // console.log($event, this.airex, "$event11");
        } else if (!this.airex && count == 2) {
          this.excludedAirlines = [];
          uniqueData.forEach((airline) => {
            if (!this.excludedAirlines.includes(airline)) {
              this.excludedAirlines.push(airline);
            }
          });
          this.toggleDropdown("airlines");
          // console.log($event, this.airex, "$event22");
        } else {
          this.airlineval = true;
          // console.log($event, this.airex, "$event33");
        }
      } else {
        this.toggleDropdown("airlines");
      }
    },

    selectDays(day, index) {
      console.log(day, "days");
      this.days.forEach((c, i) => {
        if (i == index) {
          c.valdata = !c.valdata;
          this.selectedDays = c.valdata ? c.value : "";
        } else {
          c.valdata = false;
        }
      });
      // this.days[index].valdata = !this.days[index].valdata
    },

    closeModal() {
      this.modalTab = false;
      this.tab = "tab-1";
      document.documentElement.style.overflow = "scroll";
      this.baggageModal = null;
    },
    getBaggage(res, index) {
      this.fareLoader = true;
      this.modalTab = true;
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
      this.baggageModal = index;
      console.log(index);

      let fareRequest = {
        request_type: "farerules",
        shopping_response_id: res.ShoppingResponseId,
        offer_id: res.selectId,
        portal_id: this.portalId,
      };
      console.log(fareRequest, "fareRequestssssss");
      axios
        .get(this.fareRulesApi, {
          headers: {
            "Content-Type": "text/html",
          },
          params: fareRequest,
        })
        .then((response) => {
          console.log(response.data, "responsefareRulesApi");
          this.fareRulesContent = response.data;
          this.fareLoader = false;
        })
        .catch((error) => {
          console.log(error, "erroroor.....");
          this.fareLoader = false;
        });
    },

    submitCommission(data, index) {
      this.agentcommisionReq.payload.agent_id = this.agentId
        ? this.agentId
        : "";
      this.agentcommisionReq.payload.agency_id = this.agencyId
        ? this.agencyId
        : "";
      this.agentcommisionReq.payload.login_type = this.loginType
        ? this.loginType
        : "";
      this.agentcommisionReq.portal_id = this.portalId;
      this.agentcommisionReq.payload.shopping_response_id =
        data.ShoppingResponseId;
      this.agentcommisionReq.payload.offer_id = data.selectId;
      this.agentcommisionReq.payload.total_price_per_person = data.perperson;
      this.agentcommisionReq.payload.total_price_itin = data.TotalPrice;
      // this.agentcommisionReq.payload.total_price_itin = null;
      this.agentcommisionReq.payload.instant_add_type = data.instandradio;
      // this.agentcommisionReq.payload.instant_add_type = null;
      this.agentcommisionReq.payload.instant_add_value = data.instantvalue;
      this.agentcommisionReq.payload.instant_add_currency =
        this.displayCurrency;
      // this.agentcommisionReq.payload.instant_add_currency = data.CommissionDetails.agent_commission_amount_currency;

      data.calcLoader = true;
      console.log(this.agentcommisionReq, "agentcommisionReqagentcommisionReq");

      if (data.validMarkupform) {
        console.log(data, "commisiondatacommisiondata ");

        axios
          .post(this.instantApi, this.agentcommisionReq, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "agentCommissionResponse...1");
            if (response) {
              data.calcLoader = false;
              data.markupInput = false;
              data.tempInstantValue = data.instantvalue;

              this.getCommission = response.data.payload;
              console.log(this.getCommission, "agentCommissionResponse...2");

              let getInstantType = data.instandradio;
              let getTotfare = this.getCommission.total_price_itin;
              this.agentTotalFare = this.getCommission.total_price_itin;
              console.log(this.agentTotalFare, "this.agentTotalFare");
              let getagentCom =
                this.getCommission.commission_details.final_commission_amount;

              // this.getTotalfare(getTotfare);
              // this.getAgentCommission(getagentCom);

              console.log(this.rountresult, "fffffddddd...1");
              console.log(this.onewayresulted, "fffffddddd...2");
              console.log(this.multicityData, "fffffddddd...3");

              if (this.rountresult.length > 0) {
                this.rountresult[index].getInstantType = getInstantType;
                this.rountresult[index].getTotalfare = getTotfare;
                this.rountresult[index].getAgentCommission = getagentCom;
              }

              if (this.onewayresulted.length > 0) {
                this.onewayresulted[index].getInstantType = getInstantType;
                this.onewayresulted[index].getTotalfare = getTotfare;
                this.onewayresulted[index].getAgentCommission = getagentCom;
              }

              if (this.multicityData.length > 0) {
                this.multicityData[index].getInstantType = getInstantType;
                this.multicityData[index].getTotalfare = getTotfare;
                this.multicityData[index].getAgentCommission = getagentCom;
              }

              console.log(this.rountresult, "rountresultrountresult...5");
              console.log(
                this.onewayresulted,
                "onewayresultedonewayresulted...5"
              );
              console.log(this.multicityData, "multicityDatamulticityData...5");
            }
          })
          .catch((error) => {
            data.calcLoader = false;
            data.markupInput = false;
            console.log(error, "agentCommissionerror");
          });
      } else {
        data.calcLoader = false;
        return data.validMarkupform;
      }
      // }
      // });
    },

    // getTotalfare($event){
    //   console.log($event,'getTotalfaregetTotalfaregetTotalfare')
    //   return $event;
    // },

    // getAgentCommission($event){
    //   console.log($event,'getAgentCommissiongetAgentCommission')
    //   return $event;
    // },

    //     recentFromData() {
    //   let storedRecentFrom = JSON.parse(localStorage.getItem("recentFrom")) || [];
    //   if (storedRecentFrom.length > 0) {
    //     let recentEntries = storedRecentFrom.splice(0, 15);
    //     this.RecentSearchData = recentEntries;

    //     let uniqueFrom = [];
    //     let uniqueTo = [];

    //     recentEntries.forEach((entry) => {
    //       if (!uniqueFrom.some((item) => item.from === entry.from)) {
    //         uniqueFrom.push(entry);
    //       }
    //     });
    //     this.resultFrom = uniqueFrom;

    //     recentEntries.forEach((entry) => {
    //       if (!uniqueTo.some((item) => item.to === entry.to)) {
    //         uniqueTo.push(entry);
    //       }
    //     });
    //     this.resultTo = uniqueTo;
    //   } else {
    //     console.log("No recent searches found.");
    //   }
    // },

    // recentFromData() {
    //   let result1 = [];
    //   let result2 = [];
    //   // let result3 = []
    //   // localStorage.setItem("recentFrom")
    //   result1 = JSON.parse(localStorage.getItem("recentFrom"));
    //   // console.log(result1, 'result1result1')
    //   if (result1 == null) {
    //     console.log("suhajhbhu");
    //   } else if (result1.length > 0) {
    //     //  alert('pppp')
    //     result2 = result1.splice(0, 15);
    //     this.RecentSearchData = result2;
    //     console.log(this.RecentSearchData, "this.RecentSearchData");
    //     // result3 = result1.splice(0, 15)
    //     // console.log(result1, 'ppp')
    //     let result = [];
    //     let resultTo = [];

    //     result2.forEach((a) => {
    //       if (!result.some((b) => b.from.name === a.from.name)) {
    //         result.push(a);
    //       }
    //     });
    //     this.resultFrom = result;
    //     // console.log(result2,'result3result3')
    //     result2.forEach((a) => {
    //       if (!resultTo.some((b) => b.to.name === a.to.name)) {
    //         resultTo.push(a);
    //       }
    //     });
    //     // console.log(resultTo, 'resultToresultTo')

    //     this.resultTo = resultTo;
    //   } else {
    //     console.log(result1);
    //   }
    // },

    getMultiCitySegments(data) {
      // Returns an array of segments for multi-city trips
      return data.from.map((fromCity, index) => ({
        from: fromCity,
        to: data.to[index] || "",
        fromDate: data.fromDate[index] || "",
        toDate: data.toDate ? data.toDate[index] || "" : "",
      }));
    },

    handleKeyup(data, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          if (event.target.value) {
            v.disabledBtn = false;
          } else {
            v.disabledBtn = true;
          }
        } else {
          v.disabledBtn = true;
        }
      });

      this.onewayresulted.map((v, i) => {
        if (i == index) {
          if (event.target.value) {
            v.disabledBtn = false;
          } else {
            v.disabledBtn = true;
          }
        } else {
          v.disabledBtn = true;
        }
      });

      this.multicityData.map((v, i) => {
        if (i == index) {
          if (event.target.value) {
            v.disabledBtn = false;
          } else {
            v.disabledBtn = true;
          }
        } else {
          v.disabledBtn = true;
        }
      });
    },

    filterNumber: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    showMore() {
      this.readMore = !this.readMore;

      // let $data = []
      console.log(this.filterAirline, "this.filterAirline ");

      if (this.readMore) {
        this.maxItemsToShow = this.airlineList.length;
      } else {
        this.maxItemsToShow = 5;
      }
    },

    // showLess() {
    //   this.readMore = false;
    // },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      // console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(8);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(8);

      this.uniqueId = randomChars1 + randomNum + randomChars2;
      // console.log(
      //   this.uniqueId,
      //   "bookingRefIdbookingRefIdbookingRefId"
      // );
    },

    selectClasstype(item) {
      this.classType = item.value;
    },

    showDetails1(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }

      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails1(res) {
      res.hoveredName1 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails2(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName2 = true;
        } else {
          v.hoveredName2 = false;
        }
      });
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName2 = true;
          } else {
            v.hoveredName2 = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName2 = true;
            v.hoveredName3 = true;
          } else {
            v.hoveredName2 = false;
            v.hoveredName3 = false;
          }
        });
      }
    },
    hideDetails2(res) {
      res.hoveredName2 = false;
    },

    showDetails3(res, index) {
      this.rountresult.map((v, i) => {
        // console.log(v, "ppppp");
        if (i == index) {
          v.hoveredName3 = true;
        } else {
          v.hoveredName3 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName3 = true;
          } else {
            v.hoveredName3 = false;
          }
        });
      }
    },
    hideDetails3(res) {
      res.hoveredName3 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails4(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName4 = true;
        } else {
          v.hoveredName4 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName4 = true;
          } else {
            v.hoveredName4 = false;
          }
        });
      }
    },
    hideDetails4(res) {
      res.hoveredName4 = false;
    },

    activeTrip(data) {
      console.log("Selected Value red:", data);
      this.bookingRadio = data;
      // this.bookingRadio = data;
      // this.oneway.city = this.bookingRadio;
      // console.log(this.bookingRadio, "pppppppppppppppppppppppppp");

      if (this.bookingRadio == "roundone") {
        this.rount = true;
        this.Oneway = false;
        this.multicity = false;
        this.bookingRadio = "roundone";
        // this.oneway.redate = null;
        console.log(
          this.rount,
          this.Oneway,
          this.multicity,
          "red1red1red1red1"
        );
      }

      if (this.bookingRadio == "onewaytwo") {
        this.toDateError = false;
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        // this.oneway.redate = null;
        this.bookingRadio = "onewaytwo";
        console.log(
          this.rount,
          this.Oneway,
          this.multicity,
          "red1red1red1red2"
        );
      }

      if (this.bookingRadio == "multithree") {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        this.bookingRadio = "multithree";
        // this.paxhide = false
        console.log(
          this.rount,
          this.Oneway,
          this.multicity,
          "red1red1red1red3"
        );
      }
    },

    actActive() {
      // console.log(this.bookingRadio, "TRTRTRTR1");
      if (this.bookingRadio == "roundone") {
        this.Oneway = false;
        (this.rount = true), (this.multicity = false);
        this.roundModify = false;
        this.onewayModify = true;
      } else if (this.bookingRadio == "onewaytwo") {
        this.Oneway = true;
        this.rount = false;
        this.multicity = false;
        this.roundModify = true;
        this.onewayModify = false;
      } else {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    deg1() {
      // this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
    },
    add1() {
      console.log(this.aduls, "1111");
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls = this.aduls + 1;
        console.log(this.aduls, "2222");
        // this.adulted();
        this.added();
      }
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        // this.Childed1();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;

        // console.log(this.aduls1, "2222");

        // this.Childed();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        // this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;

        // console.log(this.aduls2, "3333");

        // this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );

      // console.log(this.concatenatedArray, "concatenatedArray");
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // console.log(this.classSelect, "ffffaaaallllssseeee");
      if (!this.classSelect) {
        this.$refs.countrySelect.focus();
      }
    },

    closeDropdown() {
      setTimeout(() => {
        this.$refs.mySelect.blur();
        if (this.classType) {
          this.$refs.countrySelect.blur();
          console.log("working:");
        }
      }, 50);
    },

    multiSend() {
      if (this.multi_city.itinery.length < 4) {
        this.multi_city.itinery.push({
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        });

        if (this.multi_city.itinery.length == 4) {
          console.log(
            this.multi_city,
            this.multi_city.itinery.length,
            "lengthlen.....gthlength"
          );

          this.addmore = false;
        }
        // else{
        //   this.addmore = true;
        // }
      }

      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
        this.deleteSeg = true;
      }

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    deleteSegment() {
      if (this.multi_city.itinery.length == 3) {
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = false;
        this.addmore = true;
      } else if (this.multi_city.itinery.length > 2) {
        console.log(this.multi_city.itinery.length, "lengthlengthl,..111..");
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = true;
        this.addmore = true;
      } else {
        this.deleteSeg = false;
      }
    },

    clearall1() {
      this.multi_city.itinery.splice(2, 5);

      (this.multi_city.itinery = [
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },

    getadultTotBase(data) {
      if (data.adultPriceDetails) {
        let getAdtbase =
          parseFloat(data.adultPriceDetails.adultcount) *
          parseFloat(data.adultPriceDetails.baseprice);
        console.log(getAdtbase, "getadultTotBasegetadultTotBase...2");
        return getAdtbase.toFixed(2);
      }
    },

    getchildTotBase(data) {
      if (data.childPriceDetails) {
        let getChdbase =
          parseFloat(data.childPriceDetails.childcount) *
          parseFloat(data.childPriceDetails.baseprice);
        console.log(getChdbase, "getadultTotBasegetadultTotBase...3");
        return getChdbase.toFixed(2);
      }
    },

    getInfTotBase(data) {
      if (data.infPriceDetails) {
        let getInfbase =
          parseFloat(data.infPriceDetails.infcount) *
          parseFloat(data.infPriceDetails.baseprice);
        console.log(getInfbase, "getadultTotBasegetadultTotBase...4");
        return getInfbase.toFixed(2);
      }
    },

    // getprice(res) {
    //   return (((res.TotalAmount) / this.totaladult).toFixed(2));
    // },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    // getlogo($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    // getlogo1($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },

    // All Date Section
    getDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },
    getcalaDate($event) {
      return moment($event, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // All Date Section End

    // Mobile Date Format Section

    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // Mobile Date Show End

    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getCountryName1($event) {
      this.FilterTimeDepname = $event.split("(")[0];
      return $event.split("(")[0];
    },
    getCountryName2($event) {
      this.FilterTimearrname = $event.split("(")[0];
      return $event.split("(")[0];
    },

    getAirportNameFrom($event) {
      return $event[0].Departure.AirportName;
    },

    getAirportNameTo($event) {
      return $event[0].Departure.AirportName;
    },

    getarrivaldata($event) {
      return $event[1].Departure.AirportName;
    },
    getreturndata($event) {
      return $event[1].Departure.AirportName;
    },
    getarrtime($event) {
      console.log("kokkokokokooko", $event);
      return $event[0].Arrival.Time;
    },

    totalTime($event) {
      return this.addTimes($event);
    },
    addTimes(timeArray) {
      let totalMinutes = 0;

      timeArray.forEach((time) => {
        const matches = time.match(/(-?\d+)\s*h\s*(-?\d+)\s*m/);
        if (matches) {
          let hours = parseInt(matches[1], 10);
          let minutes = parseInt(matches[2], 10);
          if (hours >= 0 && minutes >= 0) {
            totalMinutes += hours * 60 + minutes;
            // console.log(
            //   `Adding time from index ${index}: ${hours} h ${minutes} m`
            // );
          } else {
            // console.log(
            //   `Skipping negative time at index ${index}: ${hours} h ${minutes} m`
            // );
          }
        } else {
          // console.error(`Invalid time format at index ${index}: ${time}`);
        }
      });
      const totalHours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
      return `${totalHours}:${remainingMinutes.toString().padStart(2, "0")}`;
    },

    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event, '$event')

      return $event[$event.length - 1].Departure.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Departure.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Departure.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Departure.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getretairname($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },
    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },

    getdeparDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "ddd MMM DD"
      );
    },

    getdated($event) {
      console.log($event, "eventeventevent");
      return moment($event).format("MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    //  --------filter Start---------------------

    Recommend() {
      this.active = true;
      this.active1 = false;
      this.active2 = false;
      this.active3 = false;

      if (this.onewayfilterData.length > 0) {
        // alert('pppppp99')
        // console.log(this.onewayfilterData,'this.onewayfilterDatathis.onewayfilterData')
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },
    Cheapest(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = false;
        this.active2 = true;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        // this.onewayfilterData.sort((a, b) => a.perperson - b.perperson)
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },

    Shortest(data) {
      if (data == "1") {
        this.active = false;
        this.active1 = false;
        this.active2 = false;
        this.active3 = true;
      } else {
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) =>
          a.Depaturejourney.Time.localeCompare(
            b.Depaturejourney.Time,
            undefined,
            { numeric: true }
          )
        );
        this.shorCurrecy = this.onewayresulted[0].currency;
        this.shotperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort(
          (a, b) =>
            a.Depaturejourney.Time.localeCompare(
              b.Depaturejourney.Time,
              undefined,
              { numeric: true }
            ) &&
            a.Returnjourney.Time.localeCompare(
              b.Returnjourney.Time,
              undefined,
              { numeric: true }
            )
        );
        this.shorCurrecy = this.rountresult[0].currency;
        this.shotperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;

        this.multicityData.sort((a, b) => {
          const compareFlight1 = a.Flight1JourneyTime.Time.localeCompare(
            b.Flight1JourneyTime.Time,
            undefined,
            { numeric: true }
          );
          const compareFlight2 = a.Flight2JourneyTime.Time.localeCompare(
            b.Flight2JourneyTime.Time,
            undefined,
            { numeric: true }
          );

          let compareFlight3 = 0;
          if (
            a.Flight3JourneyTime &&
            a.Flight3JourneyTime.Time &&
            b.Flight3JourneyTime &&
            b.Flight3JourneyTime.Time
          ) {
            compareFlight3 = a.Flight3JourneyTime.Time.localeCompare(
              b.Flight3JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          let compareFlight4 = 0;
          if (
            a.Flight4JourneyTime &&
            a.Flight4JourneyTime.Time &&
            b.Flight4JourneyTime &&
            b.Flight4JourneyTime.Time
          ) {
            compareFlight4 = a.Flight4JourneyTime.Time.localeCompare(
              b.Flight4JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          return (
            compareFlight1 || compareFlight2 || compareFlight3 || compareFlight4
          );
        });

        this.shorCurrecy = this.multicityData[0].currency;
        this.shotperperson = this.multicityData[0].perperson;
      }
    },

    bestlayTime(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = true;
        this.active2 = false;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;

        this.multicityData.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.multicityData[0].perperson;
      }
    },

    //  --------filter end---------------------

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    flighted1(res, index) {
      this.flightdetailsData = res;

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.Flightdepature = !v.Flightdepature;
          v.tabSelect = null;
          // console.log(res,'flighted...1');
        } else {
          v.Flightdepature = false;
          v.tabSelect = null;
          // console.log(res,'flighted...2');
        }
      });
    },
    flighted2(res, index) {
      console.log(index, res, "pppppppiiithis");
      this.flightdetailsData = res;
      // console.log(this.flightdetailsData.Depature,'this.flightdetailsDatathis.flightdetailsData')

      this.onewayresulted.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },

    flighted3(res, index) {
      console.log(index, res);
      this.multicityData.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },

    closeDep(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
    },

    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightdep1(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarrival(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    selected(result, index) {
      this.priceChangeValue = result;
      this.popup = true;
      console.log(result, "Selected flight...");

      if (result.Flight1) {
        console.log(result, "Handling multi-city flight...");

        if (this.multicityData.length > 0) {
          this.multicityData.forEach((v, i) => {
            if (i === index) {
              result.TotalPrice = v.getTotalfare;
              result.CommissionDetails.final_commission_amount =
                v.getAgentCommission;
              console.log(i, v, "Updated flight details");
            }
          });
          console.log(this.multicityData, "Multi-city data updated");
        }

        let passengerDetails = {
          adult: this.multi_city.adult,
          child: this.multi_city.child,
          inf: this.multi_city.infrant,
          class: this.multi_city.class,
        };

        localStorage.setItem(
          `pass-${result.selectId}`,
          JSON.stringify(passengerDetails)
        );

        this.priceConfirm(this.priceChangeValue);
      } else {
        console.log(result, "Handling round-trip/one-way flight...");

        if (this.rountresult.length > 0) {
          this.rountresult.forEach((v, i) => {
            if (i === index) {
              result.TotalPrice = v.getTotalfare;
              result.CommissionDetails.final_commission_amount =
                v.getAgentCommission;
              console.log(i, v, "Updated flight details");
            }
          });
          console.log(this.rountresult, "Round-trip/one-way data updated");
        }

        this.priceConfirm(this.priceChangeValue);
      }
    },

    priceConfirm(result) {
      // console.log(result, this.priceChangeValue, "Price confirmation");
      this.pageLoad = true;
      console.log(this.searchloader, "searchloader");
      if (result == "1") {
        this.routerChange();
        console.log("pppppp");
      } else {
        let $pracingReq = {
          service_type: "PricingBeforeBooking",
          api_ver: "1",
          portal_id: "mann",
          payload: {
            shopping_response_id: this.priceChangeValue.ShoppingResponseId,
            offer_id: this.priceChangeValue.selectId,
          },
        };
        console.log($pracingReq, this.pricingApi, "0p0p0p0p0p");
        axios
          .post(this.pricingApi, $pracingReq, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            let $priceres = response.data;
            this.priceRes = response.data;
            // this.routerChange();
            this.priceapiData = $priceres.payload.pricingid;
            console.log(
              $priceres.payload.itin_total_fare,
              this.priceChangeValue.TotalPrice,
              this.priceapiData,
              $priceres,
              "responsefareRulesApi"
            );

            if ($priceres.response_status == "success") {
              console.log("ssssss");
              this.pageLoad = false;
              if (
                $priceres.payload.itin_total_fare ==
                this.priceChangeValue.TotalPrice
              ) {
                console.log("ssssss88");
                this.routerChange();
              } else if (
                $priceres.payload.itin_total_fare >
                this.priceChangeValue.TotalPrice
              ) {
                console.log("ssssss....1");
                this.priceText = "The fare has been  updated by the Airlines.";
                this.pricedialog = true;
                this.pageLoad = false;
              } else if (
                $priceres.payload.itin_total_fare <
                this.priceChangeValue.TotalPrice
              ) {
                console.log("ssssss.....2");
                this.priceText = "Your price has been decreased";
                this.pricedialog = true;
                this.pageLoad = false;
              }
            }
          })
          .catch((error) => {
            console.log(error, "erroroor.....");
            this.fareLoader = false;
            this.priceError = true;
          });
      }
    },
    resetBooking() {
      this.priceError = false;
    },
    routerChange() {
      let data = "";
      let event = "";

      if (this.onewayresulted.length > 0) {
        data = "pass_uid1";
        event = this.priceChangeValue.currency;
      } else if (this.rountresult.length > 0) {
        data = "pass_uid";
        event = this.priceChangeValue.currency;
        // console.log(
        //   this.priceChangeValue,
        //   event,
        //   data,
        //   `${data}:${event}-${this.priceChangeValue.selectId}`,
        //   "this.priceChangeValue"
        // );
      } else {
        data = "pass_uid";
        event = "multi";
      }
      this.priceChangeValue.pricingid = this.priceapiData;

      setTimeout(() => {
        this.dataLoader = false;

        localStorage.setItem(
          `${event}-${this.priceChangeValue.selectId}`,
          JSON.stringify(this.priceChangeValue)
        );

        this.$router.push({
          path: "/flightinfo",
          query: {
            [data]: `${event}-${this.priceChangeValue.selectId}`,
          },
        });
        // this.pageLoad = false;

        // // this.flightpage = true;
        // // this.flightInfoData = {
        // //   [data]: `${event}-${this.priceChangeValue.selectId}`,
        // };
        console.log(this.flightInfoData, "this.flightInfoData");
      }, 3000);
    },

    onewayselect(result, index) {
      console.log(result, "One-way flight selected");
      this.priceChangeValue = result;
      this.popup = true;

      if (this.onewayresulted.length > 0) {
        this.onewayresulted.forEach((v, i) => {
          if (i === index) {
            result.TotalPrice = v.getTotalfare;
            result.CommissionDetails.final_commission_amount =
              v.getAgentCommission;
            console.log(i, v, "One-way flight updated");
          }
        });
        console.log(this.onewayresulted, "One-way data updated");
      }

      localStorage.removeItem("itineryData");
      localStorage.removeItem("uniId");

      this.priceConfirm(result);
    },

    mydata() {
      try {
        this.resulted = JSON.parse(localStorage.getItem("myData"));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    // RoundTrip datePicker

    getDatepicker($data, index) {
      if (index > 0) {
        // console.log($data, index, '$data,index....1')

        return $data[index - 1].depdata;
      } else {
        // console.log($data, index, '$data,index....2')

        return `${new Date()}`;
      }
    },

    fromDateChanged(newValue) {
      // console.log(newValue,'datepickerrrrrrrr')

      // if (newValue) {
      //   // Convert to UTC or handle the date appropriately
      //   const selectedDate = new Date(newValue);
      //   const utcDate = new Date(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate());
      //   this.oneway.dedate = utcDate.toISOString().split('T')[0];
      // }

      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.clicked2 = false;
        this.fromDateError = false;
        this.oneway.redate = null;
        console.log(this.$refs.dpRef2, "dpRef2dpRef2dpRef2...1");
        if (this.$refs.dpRef2) {
          this.$refs.dpRef2.openMenu();
        }
        // console.log(this.oneway.dedate, "ddddddddd...1....eeeeeeeeeee");
      } else {
        this.clicked1 = false;
        this.fromDateError = true;

        // console.log(this.oneway.dedate, "ddddddddd...2....eeeeeeeeeee");
      }
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // console.log(this.oneway.redate, "ttttttttttt");

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
      } else {
        this.clicked2 = false;
        this.toDateError = true;
      }
    },

    triggerFunction() {
      alert("Hiii...");
      // this.triggerRadio = !this.triggerRadio

      // if (this.triggerRadio) {
      //   console.log(this.triggerRadio)
      //   this.$refs.radioSelect2.focus();
      // }
    },

    ClickOutReturn() {
      console.log(this.$refs.dpRef2, "dpRef2dpRef2dpRef2...2");

      if (!this.Oneway) {
        this.$refs.dpRef2.closeMenu();
      }

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();
      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    multiDateChanged(newValue, index) {
      // console.log(index, newValue, "didididid...");

      this.multi_city.itinery.map((v, i) => {
        if (index == i) {
          if (newValue) {
            v.multiDateError = false;
          } else {
            v.multiDateError = true;
          }
          if (v.depdata) {
            const inputElement = this.$refs.multiToAutocomplete[index + 1];
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            }
          }
        }
      });

      this.multi_city.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },

    multiClickOutside() {
      this.multi_city.itinery.clicked3 = false;
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End

    roundData() {
      this.oneway.class = this.$route.query.class;

      if (this.$route.query.from.name) {
        this.oneway.from = this.$route.query.from.name;
        this.tripData.from = this.$route.query.from.name;
        this.inputCity = this.oneway.from;
        this.selectedFrom = true;
      } else {
        this.oneway.from = this.$route.query.from;
        this.tripData.from = this.$route.query.from;
        this.inputCity = this.oneway.from;
        this.selectedFrom = true;
      }
      if (this.$route.query.to.name) {
        this.oneway.to = this.$route.query.to.name;
        this.tripData.to = this.$route.query.to.name;
        this.inputCityto = this.oneway.to;
        this.selectedTo = true;
      } else {
        this.oneway.to = this.$route.query.to;
        this.tripData.to = this.$route.query.to;
        this.inputCityto = this.oneway.to;
        this.selectedTo = true;
      }
      // this.oneway.dedate = moment(this.$route.query.dedate).format(
      //   "DD MMM-YYYY"
      // );
      // let getrouteDate = this.$route.query.dedate;
      // this.oneway.dedate = moment(getrouteDate).format("YYYY-MM-DD");

      this.oneway.dedate = new Date(this.$route.query.dedate);
      console.log(
        this.oneway.dedate,
        this.$route.query.dedate,
        "this.oneway.dedate"
      );
      this.tripData.dedate = new Date(this.$route.query.dedate);
      this.oneway.redate = new Date(this.$route.query.redate);
      this.tripData.redate = new Date(this.$route.query.redate);
      this.oneway.class = this.$route.query.class;
      this.tripData.class = this.$route.query.class;

      if (this.$route.query.adult) {
        for (let i = 0; i < this.$route.query.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.$route.query.child) {
        for (let i = 0; i < this.$route.query.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.$route.query.infrant) {
        for (let i = 0; i < this.$route.query.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];

      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });
      // console.log(this.totalpassenger, "aadjj");
      this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
      this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;

      this.aduls = parseFloat(this.$route.query.adult);
      this.tripData.aduls = parseFloat(this.$route.query.adult);

      this.aduls2 = parseFloat(this.$route.query.infrant);
      this.tripData.aduls2 = parseFloat(this.$route.query.infrant);

      this.aduls1 = parseFloat(this.$route.query.child);
      this.tripData.aduls1 = parseFloat(this.$route.query.child);
      this.totaladult = this.aduls + this.aduls1 + this.aduls2;
      this.select = this.$route.query.class;
      this.tripData.select = this.$route.query.class;
    },

    multiDataConvert() {
      console.log(this.multi_city, "this.$route.querythis.$route.query.....3");
      this.bookingRadio = this.multi_city.city;
      // this.bookingRadio = this.multi_city.city;
      if (this.multi_city.adult) {
        for (let i = 0; i < this.multi_city.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.multi_city.child) {
        for (let i = 0; i < this.multi_city.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.multi_city.infrant) {
        for (let i = 0; i < this.multi_city.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });

      let getRouterlink = JSON.parse(this.$route.query.muti_city);

      this.aduls = parseFloat(getRouterlink.adult);
      this.tripData.aduls = parseFloat(getRouterlink.adult);

      this.aduls1 = parseFloat(getRouterlink.child);
      this.tripData.aduls1 = parseFloat(getRouterlink.child);

      this.aduls2 = parseFloat(getRouterlink.infrant);
      this.tripData.aduls2 = parseFloat(getRouterlink.infrant);

      this.totaladult = this.aduls + this.aduls1 + this.aduls2;
      this.select = getRouterlink.class;
      this.tripData.select = getRouterlink.class;

      this.multi_city.itinery.forEach((s) => {
        let $result = {
          Departure: {
            AirportCode: "",
            Date: "",
          },
          Arrival: {
            AirportCode: "",
          },
        };
        let tripData = {
          from: "",
          to: "",
        };
        if (s.from) {
          let res = "";
          res = s.from.split("(");
          console.log(res);
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
          s.selectedFrom = true;
        } else {
          let res = "";
          res = s.from.name.split("(");
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
          s.selectedFrom = true;
        }

        if (s.to) {
          let res = "";
          res = s.to.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
          s.selectedTo = true;
        } else {
          let res = "";
          res = s.to.name.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
          s.selectedTo = true;
        }
        if (s.depdata) {
          $result.Departure.Date = moment(s.depdata).format('YYYY-MM-DD');
        }
        // console.log($result, '$result$result')
        this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push(
          $result
        );
        this.codeData.push(tripData);
      });

      console.log(this.codeData, "this.$route.querythis.$route.query.....2");

      console.log(this.totalpassenger, "aadjj");
      this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;

      this.MulticityReq.request.AirShoppingRQ.Preference.Currency =
        this.domainCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency =
        this.domainCurrency;
      this.MulticityReq.country = this.domainPos;
    },

    multicityresult() {
      this.multiDataConvert();

      this.multi_city.class = this.classType;
      let multiCabin_type = this.multi_city.class;

      console.log(
        multiCabin_type,
        "multiCabin_typemultiCabin_typemultiCabin_type"
      );

      if (multiCabin_type == "Economy") {
        multiCabin_type = "Y";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Business") {
        multiCabin_type = "C";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else {
        multiCabin_type = "F";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      }

      this.MulticityReq.agentid = this.agentId;
      this.MulticityReq.agencyid = this.agencyId;
      this.MulticityReq.agentemail = this.agentEmail;
      this.MulticityReq.PlatformSource = this.platformSrc;
      this.MulticityReq.login_type = this.loginType;
      this.MulticityReq.request.AirShoppingRQ.Preference.FareType =
        this.fareType;
      this.MulticityReq.sessionid = this.sessionId;
      this.MulticityReq.country = this.domainPos;
      this.MulticityReq.request.AirShoppingRQ.MetaData.PointOfSale =
        this.domainPos;
      this.MulticityReq.request.AirShoppingRQ.MetaData.PointOfSaleCurrency =
        this.domainCurrency;
      this.MulticityReq.request.AirShoppingRQ.Preference.Currency =
        this.preferCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency =
        this.metaCurrency;

      console.log(
        this.makeSearchUrl,
        this.MulticityReq,
        "MulticityReqMulticityReqMulticityReqMulticityReq"
      );

      axios
        .post(this.makeSearchUrl, this.MulticityReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          // if (response.data) {
          //   this.multicityTotalData.push(response.data);
          //   this.multicityconvert();
          // }

          if (response.data.Search_Response == "Error") {
            console.log(response, "multi_citymulti_citymulti_city.....1");

            this.$router.push("/pageError");
          } else if (response.data) {
            console.log(response, "multi_citymulti_citymulti_city.....2");

            localStorage.removeItem("recentPrefillData");

            this.multicityTotalData.push(response.data);
            this.multicityconvert();
          }
        })

        .catch((error) => {
          console.log(error);
          this.$router.push("/pageError");
        });
    },

    multicityconvert() {
      for (let i = 0; i < this.multicityTotalData.length; i++) {
        console.log(this.multicityTotalData[i], "multicityresponsedata");
        let shoppingId = this.multicityTotalData[i].ShoppingResponseId;
        let Journey = [];
        let flightsegment = [];
        let baggageList = [];
        let gds = "";
        let sourcePcc = "";
        gds = this.multicityTotalData[i].GdsType;
        sourcePcc = this.multicityTotalData[i].SourcePcc;
        Journey = this.multicityTotalData[i].DataLists.FlightList.Flight;
        console.log(Journey, "Journey");
        baggageList =
          this.multicityTotalData[i].DataLists.BaggageAllowanceList
            .BaggageAllowance;
        flightsegment =
          this.multicityTotalData[i].DataLists.FlightSegmentList.FlightSegment;
        // console.log(flightsegment, "flightsegment");
        let $data = [];
        $data = this.multicityTotalData[i].OffersGroup.AirlineOffers.Offer;

        let source1 = [];
        let source2 = [];
        let source3 = [];
        let source4 = [];
        let source5 = [];
        let Baggage1 = [];
        let Baggage2 = [];
        let Baggage3 = [];
        let Baggage4 = [];
        let Baggage5 = [];
        let farebase1 = [];
        let farebase2 = [];
        let farebase3 = [];
        let farebase4 = [];
        let farebase5 = [];

        // console.log($data,'datadatadatadatadata');
        $data.forEach((v) => {
          v.OfferItem[0][0].FareComponent.forEach((s, index) => {
            if (index == 0) {
              farebase1.push(s);
              if(v.OfferItem[0][0].BaggageAllowance[0] && v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef.length > 0){
                Baggage1.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData1.push(s.SegmentRefs.split(" "));

              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source1.push(Journey[i].Journey);
                }
              }
            }
            if (index == 1) {
              farebase2.push(s);
              if(v.OfferItem[0][0].BaggageAllowance[0] && v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef.length > 0){
                Baggage2.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData2.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source2.push(Journey[i].Journey);
                }
              }
            }
            if (index == 2) {
              farebase3.push(s);
              if(v.OfferItem[0][0].BaggageAllowance[0] && v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef.length > 0){
                Baggage3.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData3.push(s.SegmentRefs.split(" "));
              // console.log(this.offerData3, "this.offerData3this.offerData3");
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source3.push(Journey[i].Journey);
                }
              }
            }
            if (index == 3) {
              farebase4.push(s);
              if(v.OfferItem[0][0].BaggageAllowance[0] && v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef.length > 0){
                Baggage4.push(
                  v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              this.offerData4.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source4.push(Journey[i].Journey);
                }
              }
            }
            if (index == 4) {
              farebase5.push(s);
              Baggage5.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData5.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source5.push(Journey[i].Journey);
                }
              }
            }
          });
        });
        // console.log(flightsegment, "this.flightsegment");

        let BaggageData1 = [];
        if (this.offerData1.length > 0) {
          Baggage1.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData1.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData1.length; i++) {
            let seg = [];
            this.offerData1[i].forEach((f, index) => {
              let items = [];
              items = flightsegment.filter((v) => v.SegmentKey == f);
              if (items.length > 0) {
                seg.push(items[0]);
              }
              if (index == this.offerData1[i].length - 1) {
                this.multicityData.push({ Flight1: seg });
              }
            });
          }
        }

        let BaggageData2 = [];
        if (this.offerData2.length > 0) {
          Baggage2.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData2.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData2.length; i++) {
            let seg = [];
            this.offerData2[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData2[i].length - 1) {
                this.Flight2.push(seg);
              }
            });
          }
        }
        // console.log(this.Flight2,'Flight2Flight2Flight2');

        let BaggageData3 = [];
        if (this.offerData3.length > 0) {
          Baggage3.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData3.push(c);
              }
            });
          });
          // console.log(this.offerData3,'offerData3thisofferData36557')
          for (let i = 0; i < this.offerData3.length; i++) {
            let seg = [];
            this.offerData3[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }

              if (index == this.offerData3[i].length - 1) {
                this.Flight3.push(seg);
                // console.log(this.Flight3,'this.Flight3this.Flight3this.Flight3this.Flight3')
              }
            });
          }
        }

        // console.log(this.Flight3,'Flight3Flight3Flight3');

        let BaggageData4 = [];
        if (this.offerData4.length > 0) {
          Baggage4.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData4.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData4.length; i++) {
            let seg = [];
            this.offerData4[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData4[i].length - 1) {
                this.Flight4.push(seg);
              }
            });
          }
        }

        let BaggageData5 = [];
        if (this.offerData5.length > 0) {
          Baggage5.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData5.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData5.length; i++) {
            let seg = [];
            this.offerData5[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData5[i].length - 1) {
                this.Flight5.push(seg);
              }
            });
          }
        }
        for (let i = 0; i < this.airlogodata.length; i++) {
          for (let j = 0; j < this.flightsegmetkey.length; j++) {
            if (
              this.flightsegmetkey[j].MarketingCarrier &&
              (this.flightsegmetkey[j].MarketingCarrier.AirlineID ===
                this.airlogodata[i].id ||
                this.flightsegmetkey[j].MarketingCarrier.Name ===
                  this.airlogodata[i].name)
            ) {
              this.flightsegmetkey[j].MarketingCarrier.logo =
                this.airlogodata[i].logo;
            }
          }
        }

        let currency = [];
        let Baseprice = [];
        let Tax = [];
        let perperson = [];
        let ownername = [];
        let deeplinkurl = [];
        let ownerlogo = [];
        let offerId = [];
        let TotalPrice = [];
        let refund = [];
        let CommissionDetails = [];
        let filterOwnername = [];
        let combinedPricedetail = [];
        let fareType = [];

        $data.forEach((v) => {
          // console.log(v, "offeriddatataa");
          offerId.push(v.OfferID);
        });

        $data.forEach((s) => {
          if (s.ReqCurrency == "USD") {
            currency.push("US$");
          } else if (s.ReqCurrency == "CAD") {
            currency.push("CA$");
          } else {
            currency.push("US$");
          }

          ownername.push(s.OwnerName);
          fareType.push(s.FareType);
          deeplinkurl.push(s.DeeplinkUrl);

          if (!filterOwnername.includes(s.OwnerName)) {
            filterOwnername.push(s.OwnerName);
          }

          for (let i = 0; i < this.airline_logoData.length - 1; i++) {
            if (s.Owner == this.airline_logoData[i].id) {
              ownerlogo.push(this.airline_logoData[i].logo);
            }
          }

          Baseprice.push(
            parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
          );
          Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
          perperson.push(
            parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
          );
          TotalPrice.push(
            parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
          );

          if (s.commission_details) {
            CommissionDetails.push(s.commission_details);
          }

          let adultPriceDetails = {};
          let childPriceDetails = {};
          let infPriceDetails = {};

          s.OfferItem[0].forEach((e) => {
            if (e.PassengerType === "ADT" || e.PassengerType === "JCB") {
              let getAdultbase =
                e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                  .BookingCurrencyPrice;
              let getAdulttax =
                e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                  .BookingCurrencyPrice;
              let getAdultcount = e.PassengerQuantity;

              adultPriceDetails = {
                baseprice: parseFloat(getAdultbase).toFixed(2),
                taxprice: parseFloat(getAdulttax).toFixed(2),
                adultcount: getAdultcount,
                pax_type: "Adult",
              };

              // console.log(adultPriceDetails,'adultPriceDetailsadultPriceDetails...a');
            }

            if (e.PassengerType === "CNN" || e.PassengerType === "JNN") {
              let getChildbase =
                e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                  .BookingCurrencyPrice;
              let getChildtax =
                e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                  .BookingCurrencyPrice;
              let getChildcount = e.PassengerQuantity;

              childPriceDetails = {
                baseprice: parseFloat(getChildbase).toFixed(2),
                taxprice: parseFloat(getChildtax).toFixed(2),
                childcount: getChildcount,
                pax_type: "Child",
              };

              // console.log(childPriceDetails,'childPriceDetailschildPriceDetails...c');
            }

            if (e.PassengerType === "INF" || e.PassengerType === "JNF") {
              let getInfbase =
                e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                  .BookingCurrencyPrice;
              let getInftax =
                e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                  .BookingCurrencyPrice;
              let getInfcount = e.PassengerQuantity;

              infPriceDetails = {
                baseprice: parseFloat(getInfbase).toFixed(2),
                taxprice: parseFloat(getInftax).toFixed(2),
                infcount: getInfcount,
                pax_type: "Inf(lap)",
              };

              // console.log(infPriceDetails,'infPriceDetailsinfPriceDetails...i');
            }
          });

          combinedPricedetail.push({
            adultPriceDetails,
            childPriceDetails,
            infPriceDetails,
          });

          // console.log(combinedPricedetail,'combinedPricedetailcombinedPricedetail...c');

          s.OfferItem.forEach((v) => {
            if (v[0].FareDetail[0]) {
              if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                refund.push("Refundable");
              } else {
                refund.push("Non Refundable");
              }
            }
          });
        });

        for (let i = 0; i < perperson.length; i++) {
          let nume1 = TotalPrice[i];
          this.find1.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]
          );
          this.find2.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]
          );
        }

        farebase1.forEach((d) => {
          d.segment = d.SegmentRefs.split(" ");
          d.fareBasic = d.FareBasis.FareBasisCode.Code.split(" ");
        });

        farebase2.forEach((d) => {
          d.segment = d.SegmentRefs.split(" ");
          d.fareBasic = d.FareBasis.FareBasisCode.Code.split(" ");
        });

        farebase3.forEach((d) => {
          d.segment = d.SegmentRefs.split(" ");
          d.fareBasic = d.FareBasis.FareBasisCode.Code.split(" ");
        });

        farebase4.forEach((d) => {
          d.segment = d.SegmentRefs.split(" ");
          d.fareBasic = d.FareBasis.FareBasisCode.Code.split(" ");
        });

        // for (let i = 0; i < perperson.length; i++) {
        //   let nume1 = perperson[i];
        //   this.find1.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]);
        //   this.find2.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]);
        // }

        for (let i = 0; i < this.multicityData.length; i++) {
          this.multicityData[i].selectId = offerId[i];
          this.multicityData[i].currency = currency[i];
          this.multicityData[i].Baseprice = Baseprice[i];
          this.multicityData[i].TotalPrice = TotalPrice[i];
          this.multicityData[i].priceDetails = combinedPricedetail[i];
          this.multicityData[i].BaggageData1 = BaggageData1[i];
          this.multicityData[i].BaggageData2 = BaggageData2[i];
          this.multicityData[i].Taxprice = Tax[i];
          this.multicityData[i].Gds = gds;
          this.multicityData[i].sourcePcc = sourcePcc;
          this.multicityData[i].fareType = fareType[i];
          this.multicityData[i].perperson = perperson[i];
          this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].find1 = this.find1[i];
          this.multicityData[i].find2 = this.find2[i];
          this.multicityData[i].Flightdepature = false;
          this.multicityData[i].hoveredName1 = false;
          this.multicityData[i].ShoppingResponseId = shoppingId;
          this.multicityData[i].Adult = this.aduls;
          this.multicityData[i].Child = this.aduls1;
          this.multicityData[i].Inf = this.aduls2;
          // this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].ownerlogo = ownerlogo[i];
          this.multicityData[i].ownername = ownername[i];
          this.multicityData[i].deeplinkurl = deeplinkurl[i];
          this.multicityData[i].filterAirlines = filterOwnername;
          this.multicityData[i].Flight1JourneyTime = source1[i];
          this.multicityData[i].Flight2JourneyTime = source2[i];
          this.multicityData[i].Refund = refund[i];
          this.multicityData[i].cabinClass = this.multi_city.class;
          this.multicityData[i].multipleDatas1 = false;
          this.multicityData[i].multipleDatas2 = false;
          this.multicityData[i].radios1 = true;
          this.multicityData[i].radios2 = true;
          this.multicityData[i].radioData1 = false;
          this.multicityData[i].radioData2 = false;

          this.multicityData[i].CommissionDetails = CommissionDetails[i];
          this.multicityData[i].instandradio = "markup";
          this.multicityData[i].tempInstantValue = 0;
          this.multicityData[i].instantvalue = null;
          this.multicityData[i].calcLoader = false;
          this.multicityData[i].disabledBtn = true;
          this.multicityData[i].tabSelect = null;
          this.multicityData[i].getTotalfare = TotalPrice[i];
          this.multicityData[i].getAgentCommission =
            CommissionDetails[i].final_commission_amount;
          this.multicityData[i].validMarkupform = false;

          this.multicityData[i].agentid = this.agentId;
          this.multicityData[i].agencyid = this.agencyId;
          this.multicityData[i].agentemail = this.agentEmail;
          this.multicityData[i].platformSrc = this.platformSrc;
          this.multicityData[i].loginType = this.loginType;

          if (this.Flight3.length > 0) {
            this.multicityData[i].Flight3 = this.Flight3[i];
            this.multicityData[i].Flight3JourneyTime = source3[i];
            this.multicityData[i].multipleDatas3 = false;
            this.multicityData[i].radios3 = true;
            this.multicityData[i].radioData3 = false;
            this.multicityData[i].BaggageData3 = BaggageData3[i];
          }

          if (this.Flight4.length > 0) {
            this.multicityData[i].Flight4 = this.Flight4[i];
            this.multicityData[i].Flight4JourneyTime = source4[i];
            this.multicityData[i].multipleDatas4 = false;
            this.multicityData[i].radios4 = true;
            this.multicityData[i].radioData3 = false;
            this.multicityData[i].BaggageData4 = BaggageData4[i];
          }
          if (this.Flight5.length > 0) {
            this.multicityData[i].Flight5 = this.Flight5[i];
            this.multicityData[i].Flight5JourneyTime = source5[i];
            this.multicityData[i].BaggageData5 = BaggageData5[i];
          }
        }

        this.multicityData.forEach((a) => {
          // Function to handle FareBasisCode assignment
          const assignFareBasisCode = (flightSegments, farebaseData) => {
            flightSegments.forEach((segment) => {
              farebaseData.forEach((fareBase) => {
                if (fareBase.segment && fareBase.fareBasic) {
                  fareBase.segment.forEach((segmentKey, i) => {
                    if (segment.SegmentKey === segmentKey) {
                      segment.FareBasisCode = fareBase.fareBasic[i];
                    }
                  });
                }
              });
            });
          };

          // Assign FareBasisCode for each flight
          if (a.Flight1) assignFareBasisCode(a.Flight1, farebase1);
          if (a.Flight2) assignFareBasisCode(a.Flight2, farebase2);
          if (a.Flight3) assignFareBasisCode(a.Flight3, farebase3);
          if (a.Flight4) assignFareBasisCode(a.Flight4, farebase4);
        });

        // this.multicityData.forEach((a) => {
        //   a.Flight1.forEach((b) => {
        //     farebase1.forEach((d) => {
        //       if (b.SegmentKey === d.SegmentRefs) {
        //         b.FareBasisCode = d.FareBasis.FareBasisCode.Code;
        //         console.log('Flight1 match:', b.SegmentKey, b.FareBasisCode);
        //       }
        //     });
        //   });

        //   a.Flight2.forEach((b) => {
        //     farebase2.forEach((d) => {
        //       if (b.SegmentKey === d.SegmentRefs) {
        //         b.FareBasisCode = d.FareBasis.FareBasisCode.Code;
        //         console.log('Flight2 match:', b.SegmentKey, b.FareBasisCode);
        //       }
        //     });
        //   });

        //   if (a.Flight3) {
        //     a.Flight3.forEach((b) => {
        //       farebase3.forEach((d) => {
        //         if (b.SegmentKey === d.SegmentRefs) {
        //           b.FareBasisCode = d.FareBasis.FareBasisCode.Code;
        //           console.log('Flight3 match:', b.SegmentKey, b.FareBasisCode);
        //         }
        //       });
        //     });
        //   }

        //   if (a.Flight4) {
        //     a.Flight4.forEach((b) => {
        //       farebase4.forEach((d) => {
        //         if (b.SegmentKey === d.SegmentRefs) {
        //           b.FareBasisCode = d.FareBasis.FareBasisCode.Code;
        //           console.log('Flight4 match:', b.SegmentKey, b.FareBasisCode);
        //         }
        //       });
        //     });
        //   }
        // });

        this.getmultitime1();

        this.multishowData = this.multicityData;
        this.multiFilterData = this.multicityData;
        // this.multipriceData();
        this.priceRange();
        this.multicitystopFilter();
        this.Shortest();
        this.Recommend();
        this.Cheapest();
        this.bestlayTime();

        // this.multiFilterData = this.multicityData;
        this.multicityData = totalPricefilter.sortBy(this.multiFilterData, [
          "TotalAmount",
        ]);

        if (this.multicityData) {
          this.searchresult = true;
          this.searchloader = false;
          this.filterView = true;

          // document.getElementById("navbar").style.display = "block";
          // document.getElementById("food").style.display = "block";
        }
        // console.log(this.multicityData, "this.multicityDatathis.multicityData");
        console.log(
          this.multicityData,
          "multicityDatamulticityDatamulticityData...."
        );
      }
    },

    async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airLogoApi.url;
      airlogoType = this.airLogoApi.type;

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")

            this.airlogodata = this.airline_logoData;
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },

    roundedtrip() {
      for (let i = 0; i < this.localData.length; i++) {
        // console.log(this.localData[i],"this.localData[i]this.localData[i]this.localData[i]");

        if (this.localData[i]) {
          this.localData[i].forEach((v) => {
            // console.log(v.DataLists, "good");
            // let seats = [];
            let baggageList = [];
            this.Journey = v.DataLists.FlightList.Flight;

            let gds = "";
            let sourcePcc = "";
            gds = v.GdsType;
            sourcePcc = v.SourcePcc;

            let shoppingId = v.ShoppingResponseId;
            // console.log(shoppingId, "shoppingIdshoppingId");
            let Baseprice = [];
            let Tax = [];
            // let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;
            baggageList = v.DataLists.BaggageAllowanceList.BaggageAllowance;

            let offerId = [];
            // $data.forEach((v=>{
            //   offerId.push(v.OffersGroup.AirlineOffers.Offer.offerId)
            // }))
            // offerId.push(v.OffersGroup.AirlineOffers.Offer)

            let arrsplit = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let deeplinkurl = [];
            let perperson = [];
            let TotalPrice = [];
            let CommissionDetails = [];
            let filterOwnername = [];
            let combinedPricedetail = [];
            let farebase1 = [];
            let farebase2 = [];
            let fareType = [];

            $data.forEach((v) => {
              // console.log(v, i, "offeriddatataa");
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }

              // console.log(this.airline_logoData,"vlvlvlvlvvl")

              ownername.push(s.OwnerName);
              fareType.push(s.FareType);
              deeplinkurl.push(s.DeeplinkUrl);
              // console.log(deeplinkurl, "deeplinkurldeeplinkurl");

              if (!filterOwnername.includes(s.OwnerName)) {
                filterOwnername.push(s.OwnerName);
              }
              // console.log(filterOwnername,'ownernameownername');

              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                  // console.log(ownerlogo,'owmpownwonwown');
                }
              }
              // console.log(s.ReqCurrency, "currency");
              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              if (s.commission_details) {
                CommissionDetails.push(s.commission_details);
              }

              // pricedetail.push(s.OfferItem);

              let adultPriceDetails = {};
              let childPriceDetails = {};
              let infPriceDetails = {};

              s.OfferItem[0].forEach((e) => {
                if (e.PassengerType === "ADT" || e.PassengerType === "JCB") {
                  let getAdultbase =
                    e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                      .BookingCurrencyPrice;
                  let getAdulttax =
                    e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                      .BookingCurrencyPrice;
                  let getAdultcount = e.PassengerQuantity;

                  adultPriceDetails = {
                    baseprice: parseFloat(getAdultbase).toFixed(2),
                    taxprice: parseFloat(getAdulttax).toFixed(2),
                    adultcount: getAdultcount,
                    pax_type: "Adult",
                  };
                  // console.log(adultPriceDetails,'adultPriceDetailsadultPriceDetails...a');
                }

                if (e.PassengerType === "CNN" || e.PassengerType === "JNN") {
                  let getChildbase =
                    e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                      .BookingCurrencyPrice;
                  let getChildtax =
                    e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                      .BookingCurrencyPrice;
                  let getChildcount = e.PassengerQuantity;

                  childPriceDetails = {
                    baseprice: parseFloat(getChildbase).toFixed(2),
                    taxprice: parseFloat(getChildtax).toFixed(2),
                    childcount: getChildcount,
                    pax_type: "Child",
                  };
                  // console.log(childPriceDetails,'childPriceDetailschildPriceDetails...c');
                }

                if (e.PassengerType === "INF" || e.PassengerType === "JNF") {
                  let getInfbase =
                    e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                      .BookingCurrencyPrice;
                  let getInftax =
                    e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                      .BookingCurrencyPrice;
                  let getInfcount = e.PassengerQuantity;

                  infPriceDetails = {
                    baseprice: parseFloat(getInfbase).toFixed(2),
                    taxprice: parseFloat(getInftax).toFixed(2),
                    infcount: getInfcount,
                    pax_type: "Inf(lap)",
                  };
                  // console.log(infPriceDetails,'infPriceDetailsinfPriceDetails...i');
                }
              });

              combinedPricedetail.push({
                adultPriceDetails,
                childPriceDetails,
                infPriceDetails,
              });
              // combinedPricedetail.push({adultPriceDetails,childPriceDetails,infPriceDetails});
              // console.log(combinedPricedetail,'combinedPricedetailcombinedPricedetail...b');

              s.OfferItem.forEach((v) => {
                resultdata1 = v[0].FareComponent[0].SegmentRefs;
                resultdata = v[0].FareComponent[1].SegmentRefs;
                farebase1.push(v[0].FareComponent[0]);
                farebase2.push(v[0].FareComponent[1]);

                // console.log(
                //   v[0].FareDetail[0].Price.NonRefundable,
                //   "kannannaaannnanannananannanan"
                // );

                if (v[0].FareDetail[0]) {
                  if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                    refund.push("Refundable");
                  } else {
                    refund.push("Non Refundable");
                  }
                  // console.log(v[0].FareDetail[0].Price.NonRefundable, "kannannaaannnanannananannanan");
                }
              });

              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));
            });
            // console.log(arrsplit, "$data");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              // console.log(a,'thesplitvalueaaaaaaaaaaaaaa')
              this.flightsegmet.push(a.SegmentReferences.toString().split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
              // console.log(this.flightsegmetkey, "vasanthjourney");
            });

            // seats = v.DataLists.PriceClassList.PriceClass;
            // console.log(seats, "seatsseatsseats");
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length; i++) {
              for (let j = 0; j < this.flightsegmetkey.length; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier &&
                  (this.flightsegmetkey[j].MarketingCarrier.AirlineID ===
                    this.airlogodata[i].id ||
                    this.flightsegmetkey[j].MarketingCarrier.Name ===
                      this.airlogodata[i].name)
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            let depBaggage = [];
            let arrBaggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              if($data[j].OfferItem[0][0].BaggageAllowance[0] && $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef.length > 0){
                depBaggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              if($data[j].OfferItem[0][0].BaggageAllowance[1] && $data[j].OfferItem[0][0].BaggageAllowance[1].BaggageAllowanceRef.length > 0){
                arrBaggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[1].BaggageAllowanceRef
                );
              }
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              let result1 = $data[j].OfferItem[0][0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey;
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }
            let BaggageData = [];
            let BaggageData1 = [];
            depBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });
            arrBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });
            for (let i = 0; i < perperson.length; i++) {
              let nume1 = TotalPrice[i];
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            // for (let i = 0; i < perperson.length; i++) {
            //   let nume1 = perperson[i];
            //   this.find1.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]);
            //   this.find2.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]);
            // }

            console.log(this.agencyId, "agencyIdagencyIdagencyIdagencyId");

            farebase1.forEach((d) => {
              d.segment = d.SegmentRefs.split(" ");
              d.fareBasic = d.FareBasis.FareBasisCode.Code.split(" ");
            });

            farebase2.forEach((d) => {
              d.segment = d.SegmentRefs.split(" ");
              d.fareBasic = d.FareBasis.FareBasisCode.Code.split(" ");
            });

            // console.log(farebase1, this.onewayresulted, "fareBasicfareBasic");

            this.rountresult.forEach((a, j) => {
              a.Depature.forEach((b) => {
                if (farebase1[j] && farebase1[j].segment) {
                  farebase1[j].segment.forEach((d, i) => {
                    if (b.SegmentKey === d) {
                      b.FareBasisCode = farebase1[j].fareBasic[i];
                    }
                  });
                }
              });
            });

            // console.log(this.find1, "this.find1this.find1");
            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].Baseprice = Baseprice[i];
              this.rountresult[i].TotalPrice = TotalPrice[i];
              this.rountresult[i].Taxprice = Tax[i];
              this.rountresult[i].priceDetails = combinedPricedetail[i];
              // this.rountresult[i].pricedetail = pricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].depBaggage = BaggageData[i];
              this.rountresult[i].arrBaggage = BaggageData1[i];
              this.rountresult[i].Gds = gds;
              this.rountresult[i].sourcePcc = sourcePcc;
              this.rountresult[i].fareType = fareType[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].multipleDatas = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].currency = currency[i];
              this.rountresult[i].ownerlogo = ownerlogo[i];
              this.rountresult[i].ownername = ownername[i];
              this.rountresult[i].deeplinkurl = deeplinkurl[i];
              this.rountresult[i].filterAirlines = filterOwnername;
              this.rountresult[i].Returnjourney = depjourney[i];
              this.rountresult[i].Depaturejourney = Arrjourney[i];
              this.rountresult[i].perperson = perperson[i];
              this.rountresult[i].cabinClass = this.oneway.class;
              this.rountresult[i].find1 = this.find1[i];
              this.rountresult[i].find2 = this.find2[i];
              this.rountresult[i].ShoppingResponseId = shoppingId;
              this.rountresult[i].selectId = offerId[i];
              this.rountresult[i].Adult = this.aduls;
              this.rountresult[i].Child = this.aduls1;
              this.rountresult[i].Inf = this.aduls2;
              this.rountresult[i].radioData = true;
              this.rountresult[i].radiretData = true;
              this.rountresult[i].radios = false;
              this.rountresult[i].radiosret = false;
              this.rountresult[i].CommissionDetails = CommissionDetails[i];
              this.rountresult[i].instandradio = "markup";
              this.rountresult[i].tempInstantValue = 0;
              this.rountresult[i].markupInput = false;
              this.rountresult[i].instantvalue = null;
              this.rountresult[i].calcLoader = false;
              this.rountresult[i].disabledBtn = true;
              this.rountresult[i].tabSelect = null;
              this.rountresult[i].getTotalfare = TotalPrice[i];
              this.rountresult[i].getAgentCommission =
                CommissionDetails[i].final_commission_amount;
              this.rountresult[i].validMarkupform = false;
              this.rountresult[i].agentid = this.agentId;
              this.rountresult[i].agencyid = this.agencyId;
              this.rountresult[i].agentemail = this.agentEmail;
              this.rountresult[i].platformSrc = this.platformSrc;
              this.rountresult[i].loginType = this.loginType;
            }

            this.rountresult.forEach((a, j) => {
              a.Return.forEach((b) => {
                if (farebase2[j] && farebase2[j].segment) {
                  farebase2[j].segment.forEach((d, i) => {
                    if (b.SegmentKey === d) {
                      b.FareBasisCode = farebase2[j].fareBasic[i];
                    }
                  });
                }
              });
            });

            this.rountmultiData = this.rountresult;
            this.filterData = this.rountresult;
            this.rounddatafil = true;

            console.log(this.rountresult, "resulppppppppppppppppppppppppt");
            this.gettimedep();
            this.gettimeret();
            // this.singlepriceRountData();
            // this.filterData = this.rountresult;
            this.priceRange();
            // this.calculateTotalTime();
            this.Shortest();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();
            this.airlineFilter();
            this.stopFilter();

            if (this.rountresult) {
              this.searchresult = true;
              this.searchloader = false;
              this.filterView = true;

              // document.getElementById("navbar").style.display = "block";
              // document.getElementById("food").style.display = "block";
            }
            // console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            // this.filterShowing();

            // console.log(this.rountresult, 'result222222222222222222222222')
            this.filterData = this.rountresult;
            this.filterData.sort((a, b) => a.perperson - b.perperson);
            this.rountresult = this.filterData;

            this.calculateDates();
          });
        }
      }
    },
    // -------depTime start------

    singlepriceRountData() {
      let $event = [];
      let $data = this.rountresult;
      this.rountresult.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.rountresult = $event.sort((a, b) => a.perperson - b.perperson);

      this.rountresult.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (
            a.TotalPrice == b.TotalPrice &&
            a.ownername == b.ownername &&
            (a.Depaturejourney.Time !== b.Depaturejourney.Time ||
              a.Returnjourney.Time !== b.Returnjourney.Time)
          ) {
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.rountresult[i].multi = "Show more Options";
        }
      });

      this.filterData = this.rountresult;
      console.log($event, "this.onewayfilterData6666666$event");
    },
    fromInputData() {
      this.oneway.from = "";
      this.inputCity = "";
      if (!this.oneway.from || !this.inputCity) {
        this.selectedFrom = false;
        this.$refs.fromAutoComplete.focus();
      }
    },
    toInputData() {
      this.oneway.to = "";
      this.inputCityto = "";
      if (!this.oneway.to || !this.inputCityto) {
        this.selectedTo = false;
        this.$refs.toAutoComplete.focus();
      }
    },
    datachange(data) {
      console.log(data, "pppp");
      this.oneway.from = `${data.city} (${data.iata})`;
      this.inputCity = this.oneway.from;
      this.typedrop = false;
      const regex = /[-()]/;
      if (regex.test(this.oneway.from)) {
        this.selectedFrom = true;
      }
      if (!this.oneway.to) {
        this.$refs.toAutoComplete.focus();
      } else {
        this.$refs.fromAutoComplete.focus();
      }
    },
    datachangeTo(data) {
      this.oneway.to = `${data.city} (${data.iata})`;
      this.inputCityto = this.oneway.to;
      this.typedrop1 = false;
      const regex = /[-()]/;
      if (regex.test(this.oneway.to)) {
        this.selectedTo = true;
      }
      if (!this.oneway.dedate) {
        this.$refs.dpRef1.openMenu();
      } else {
        this.$refs.toAutoComplete.focus();
      }
    },
    multiFrom(index) {
      //   const regex = /[-()]/;
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.from = "";
          v.selectedFrom = false;
          this.$refs.multiFromAutocomplete[index].focus();
        }
      });
    },
    multiTo(index) {
      //   const regex = /[-()]/;
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.to = "";
          v.selectedTo = false;
          this.$refs.multiToAutocomplete[index].focus();
        }
      });
    },
    datachangeMuti(data, index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.from = `${data.city} (${data.iata})`;
          v.autocomfrom = false;
          v.selectedFrom = true;

          if (!v.to) {
            const inputElement = this.$refs.multiToAutocomplete[index];
            if (inputElement) {
              inputElement.focus();
            }
          } else if (v.from) {
            const inputElement = this.$refs.multiFromAutoComplete[index];
            if (inputElement) {
              inputElement.blur();
            }
          }
        }
      });
      // console.log(this.multi_city, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.to = `${data.city} (${data.iata})`;
          v.autocomTo = false;
          v.selectedTo = true;
          if (!v.to) {
            this.$refs.multiToAutocomplete[index].focus();
          }
          if (!v.departDate) {
            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            } else {
              const inputElement = this.$refs.multiFromAutoComplete[index];
              if (inputElement) {
                inputElement.blur();
              }
            }
          }

          if (this.multi_city.itinery.length - 1 != index) {
            this.multi_city.itinery[i + 1].from = this.multi_city.itinery[i].to;
            this.multi_city.itinery[i + 1].selectedFrom = true;
          }
        }
      });
      // console.log(this.multi_city, "this.multiDatathis.multiData");
    },

    addmoreroundData(data, index) {
      console.log(data, index, this.rountresult, "vasanrhtttt");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = false;
          v.radiosret = false;
          v.multipleDatas = !v.multipleDatas;
        } else {
          v.multipleDatas = false;
        }
      });
      let $event = [];

      this.rountmultiData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });
      console.log($event, "ppppppwww");
      let $result = [];
      $event.forEach((a) => {
        if (
          !$result.some(
            (b) =>
              b.Depaturejourney.Time == a.Depaturejourney.Time &&
              b.Returnjourney.Time == a.Returnjourney.Time
          )
        ) {
          $result.push(a);
        }
      });

      this.multiplesameData = $result;
      let $data = [];
      let $data1 = [];
      this.multiplesameData.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
        if (!$data1.some((b) => b.Returnjourney.Time == k.Returnjourney.Time)) {
          $data1.push(k);
        }
      });
      this.multiDepatureData = $data;
      this.multireturnData = $data1;
    },

    multipriceData() {
      let $event = [];
      // let $event1 = [];
      let $data = this.multicityData;
      this.multicityData.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.multicityData = $event.sort((a, b) => a.perperson - b.perperson);

      this.multicityData.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (a.TotalPrice == b.TotalPrice && a.ownername == b.ownername) {
            if (
              a.Flight1JourneyTime.Time !== b.Flight1JourneyTime.Time ||
              a.Flight2JourneyTime.Time !== b.Flight2JourneyTime.Time ||
              (a.Flight3JourneyTime &&
                a.Flight3JourneyTime.Time !== b.Flight3JourneyTime &&
                b.Flight3JourneyTime.Time) ||
              (a.Flight4JourneyTime &&
                a.Flight4JourneyTime.Time !== b.Flight4JourneyTime &&
                b.Flight4JourneyTime.Time)
            ) {
              count++;
              console.log(a, i);
            }
          }
        });
        console.log();
        if (count > 1) {
          this.multicityData[i].multi = "Show more Options";
        }
      });

      this.multiFilterData = this.multicityData;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    multiroundData(data, index) {
      console.log(data, index, this.multicityData, "vasanrhtttt");

      this.multicityData.find((v, i) => {
        if (i == index) {
          console.log(i, index, v, "lllll");

          v.multipleDatas4 = !v.multipleDatas4;
          v.multipleDatas3 = !v.multipleDatas3;
          v.multipleDatas2 = !v.multipleDatas2;
          v.multipleDatas1 = !v.multipleDatas1;
        } else {
          v.multipleDatas1 = false;
          v.multipleDatas2 = false;
          v.multipleDatas3 = false;
          v.multipleDatas4 = false;
        }
      });
      let $event = [];

      this.multishowData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });

      console.log($event, "$event$event");

      let $result = [];
      $event.forEach((a) => {
        if (
          !$result.some(
            (b) =>
              b.Flight1JourneyTime.Time == a.Flight1JourneyTime.Time &&
              b.Flight2JourneyTime.Time == a.Flight2JourneyTime.Time &&
              (b.Flight3JourneyTime && b.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "" == a.Flight3JourneyTime && a.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "") &&
              (b.Flight4JourneyTime && b.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "" == a.Flight4JourneyTime && a.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "")
          )
        ) {
          $result.push(a);
        }
      });

      this.multiplesameData = $result;
      let $data1 = [];
      let $data2 = [];
      let $data3 = [];
      let $data4 = [];
      // let $data1 = [];
      this.multiplesameData.forEach((k) => {
        if (
          !$data1.some(
            (b) =>
              b.Flight1JourneyTime.Time == k.Flight1JourneyTime.Time ||
              b.Flight2JourneyTime.Time == k.Flight2JourneyTime.Time ||
              (b.Flight3JourneyTime && b.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "" == k.Flight3JourneyTime && k.Flight3JourneyTime.Time
                ? k.Flight3JourneyTime.Time
                : "") ||
              (b.Flight4JourneyTime && b.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "" == k.Flight4JourneyTime && k.Flight4JourneyTime.Time
                ? k.Flight4JourneyTime.Time
                : "")
          )
        ) {
          $data1.push(k);
        }

        if (
          !$data2.some(
            (b) => b.Flight2JourneyTime.Time == k.Flight2JourneyTime.Time
          )
        ) {
          $data2.push(k);
        }

        if (
          !$data3.some((b) =>
            b.Flight3JourneyTime && b.Flight3JourneyTime.Time
              ? b.Flight3JourneyTime.Time
              : "" == k.Flight3JourneyTime && k.Flight3JourneyTime.Time
              ? k.Flight3JourneyTime.Time
              : ""
          )
        ) {
          $data3.push(k);
        }

        if (
          !$data4.some((b) =>
            b.Flight4JourneyTime && b.Flight4JourneyTime.Time
              ? b.Flight4JourneyTime.Time
              : "" == k.Flight4JourneyTime && k.Flight4JourneyTime.Time
              ? k.Flight4JourneyTime.Time
              : ""
          )
        ) {
          $data4.push(k);
        }
      });
      this.multiflight1 = $data1;
      this.multiflight2 = $data2;
      this.multiflight3 = $data3;
      this.multiflight4 = $data4;

      console.log(this.multiflight1, "this.multiflight1");
      console.log(this.multiflight2, "this.multiflight2");
      console.log(this.multiflight3, "this.multiflight3");
      console.log(this.multiflight4, "this.multiflight4");
    },

    radiodep(res, index) {
      console.log(res, index, "res, indexrrrrrr");
      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radioData = true;
          v.radiretData = true;

          this.multireturnData.forEach((d) => {
            d.radiosret = false;
          });
          this.multiDepatureData.forEach((d) => {
            d.radios = false;
          });
        }
      });
    },

    multione(data, multindex, index) {
      //  console.log(data, multindex,index,'data, multindex,indexuuuuufdfdjjb')

      this.multiDepatureData.find((e, j) => {
        console.log(j, multindex, index, "nanduuuuu");
        if (multindex == j) {
          console.log(e, "wwwwwwwwww");
          e.radios = true;
        } else {
          console.log(e, "wwwwwtttttwwwww");
          e.radios = false;
        }
      });

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = true;
          v.radioData = false;
          v.radiretData = false;
          this.multireturnData.forEach((c, k) => {
            if (c.Returnjourney.Time == data.Returnjourney.Time) {
              console.log(c, k, (c.radiosret = false), "wwww");
              c.radiosret = false;
              v.radiosret = true;
              k == 0 ? (v.radiretData = true) : (c.radiosret = false);

              this.multireturnData.forEach((d, j) => {
                if (k == j) {
                  d.radiosret = true;
                } else {
                  d.radiosret = false;
                }
              });
            } else {
              c.radiosret = false;
            }
          });
        }
      });

      this.flightdetailsData = data;
    },

    multiredone(data, multindex, index) {
      console.log(data, multindex, index, "data, multindex,indexrrrrrrrr");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radiosret = true;
          v.radioData = false;
          v.radiretData = false;
          this.multiDepatureData.forEach((c, k) => {
            if (c.Depaturejourney.Time == data.Depaturejourney.Time) {
              if (k == 0) {
                console.log(c, k, "wwww");
                v.radioData = true;
              } else {
                this.multiDepatureData.forEach((d, j) => {
                  if (k == j) {
                    console.log(d, "konoo");
                    d.radios = true;
                  } else {
                    d.radios = false;
                  }
                });
              }
            }
          });
        } else {
          v.radiosret = false;
        }
      });
      this.multireturnData.find((e, j) => {
        console.log(e, j, "ppppprrrrrrrrr");
        if (multindex == j) {
          e.radiosret = true;
        } else {
          e.radiosret = false;
        }
      });
      this.flightdetailsData = data;
    },

    multicityone1(data, multindex, index) {
      console.log(data, multindex, index);
      this.radioData1 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios1 = false;
        }
      });
    },

    multicityone2(data, multindex, index) {
      this.radioData2 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios2 = false;
        }
      });
    },
    multicityone3(data, multindex, index) {
      this.radioData3 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios3 = false;
        }
      });
    },
    multicityone4(data, multindex, index) {
      this.radioData4 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios4 = false;
        }
      });
    },

    TimeFilter1() {
      this.timeFilterData1 = !this.timeFilterData1;

      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData1 && this.timeFilterData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterData1) {
        // alert("pppp");
        this.TimeFilterData6();
      } else {
        this.removeFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeFilter2() {
      this.timeFilterData2 = !this.timeFilterData2;
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData3 && this.timeFilterData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterData2) {
        this.TimeFilterDataafter12();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeFilter2();
      }
    },
    TimeFilter3() {
      this.timeFilterData3 = !this.timeFilterData3;
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData3 && this.timeFilterData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterData3 && this.timeFilterData1) {
        this.timetwoFilter2();
      } else if (this.timeFilterData3) {
        this.TimeFilterDataafter18();
      } else {
        this.removeFilter3();
      }
    },

    // -----------deptime  end-------

    releseFilter() {
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData1 && this.timeFilterData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterData1) {
        this.TimeFilterData6();
      } else if (this.timeFilterData2) {
        this.TimeFilterDataafter12();
      } else if (this.timeFilterData3) {
        this.TimeFilterDataafter18();
      }
    },

    // -------arrtime start ---------

    TimeFilterarr1() {
      // alert("ppppp");
      this.timeFilterArrData1 = !this.timeFilterArrData1;

      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData2 && this.timeFilterArrData1) {
        console.log("ttttttt");
        this.timetwoFilter1();
      } else if (this.timeFilterArrData1 && this.timeFilterArrData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterArrData1) {
        this.TimeFilterData6();
      } else {
        this.removeFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeFilterarr2() {
      this.timeFilterArrData2 = !this.timeFilterArrData2;
      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData1 && this.timeFilterArrData2) {
        // console('2222')
        this.timetwoFilter1();
      } else if (this.timeFilterArrData3 && this.timeFilterArrData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterArrData2) {
        this.TimeFilterDataafter12();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeFilter2();
      }
    },
    TimeFilterarr3() {
      this.timeFilterArrData3 = !this.timeFilterArrData3;
      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData3 && this.timeFilterArrData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterArrData3 && this.timeFilterArrData1) {
        this.timetwoFilter2();
      } else if (this.timeFilterArrData3) {
        //  console.log('oopopoo')
        this.TimeFilterDataafter18();
      } else {
        this.removeFilter3();
      }
    },
    // --------arrtime end------

    TimeFilterData6() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          console.log("vasanth");
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          console.log("keerthi");
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        console.log($twoFilter, "$twoFilter$twoFilter");
        if (
          $twoFilter &&
          (this.timeFilterData1 || this.timeFilterData2 || this.timeFilterData3)
        ) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            console.log(
              v.Depature[0].Arrival.Time,
              " v.Depature[0].Arrival.Time"
            );
            $result.push(v);
            console.log("ppopopoo", $result);
          }
        } else if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            $result.push(v);

            console.log(
              "ppopopoo555",
              this.timeFilterArrData1,
              this.timeFilterArrData2,
              this.timeFilterArrData3
            );
          }
        }
      });
      if (this.filterData.length > 0) {
        console.log("ppopopoo11", $result);
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    TimeFilterDataafter12() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timeFilterData1 || this.timeFilterData2 || this.timeFilterData3)
        ) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
          }
        } else if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    TimeFilterDataafter18() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timeFilterData1 || this.timeFilterData2 || this.timeFilterData3)
        ) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            // console.log('pp')
          } else {
            $result.push(v);
          }
        } else if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  console.log('pp')
          } else {
            $result.push(v);
          }
        }
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoFilter1() {
      // alert("kee");
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
          //  console.log('keerthi',this.filterData)
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Depature.forEach((c, i) => {
          if (i === 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "18:00") {
              $result.push(v);
              console.log("oooo", departureTime);
            }
          }

          if (
            (i == v.Depature.length - 1 && this.timeFilterArrData1) ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "18:00") {
              $result.push(v);
              console.log("oooo222");
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
          console.log("keerthi", $event);
        } else {
          $event = this.rountresult;
          console.log("keerthi", $event);
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            console.log(
              v.Depature[0].Departure.Time,
              "v.Depature[0].Departure.Time "
            );
          } else {
            $result.push(v);
            console.log($result, "$result");
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            console.log("llllll");
          } else {
            $result.push(v);
            console.log("keerthi$resul6666666666666", $result);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    removeFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          console.log("vasanth");
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    removeFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
          }
          //        else {
          //        $result.push(v)
          //  }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
          //        else {
          //        $result.push(v)
          //  }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    totalTimeData() {
      let $event = [];

      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }

      if (this.filterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.rountresult = $event;
      }
      if (this.onewayfilterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.onewayresulted = $event;
      }
    },

    // -----------------return journey time filter start--------------

    TimeretFilter1() {
      this.timearrFilterData1 = !this.timearrFilterData1;

      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData1 && this.timearrFilterData2) {
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterData1 && this.timearrFilterData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterData1) {
        this.TimeFilterDataArrival6();
      } else {
        this.removeReturnFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeretFilter2() {
      this.timearrFilterData2 = !this.timearrFilterData2;
      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData1 && this.timearrFilterData2) {
        // console('2222')
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterData2 && this.timearrFilterData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterData2) {
        // alert('pppp')
        this.TimeFilterDataArrival2();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeReturnFilter2();
      }
    },
    TimeretFilter3() {
      this.timearrFilterData3 = !this.timearrFilterData3;
      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData3 && this.timearrFilterData2) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterData3 && this.timearrFilterData1) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterData3) {
        this.TimeFilterDataArriva18();
      } else {
        this.removeReturnFilter3();
      }
    },

    TimeretFilterarr1() {
      this.timearrFilterArrData1 = !this.timearrFilterArrData1;

      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData2) {
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterArrData1) {
        this.TimeFilterDataArrival6();
      } else {
        this.removeReturnFilter1();
      }
      // console.log(this.rountresult,'443')
    },
    TimeretFilterarr2() {
      this.timearrFilterArrData2 = !this.timearrFilterArrData2;
      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData2) {
        // console('2222')
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterArrData2 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterArrData2) {
        this.TimeFilterDataArrival2();
        // console('1111')
        // console.log(this.rountresult, '443')
      } else {
        this.removeReturnFilter2();
      }
    },
    TimeretFilterarr3() {
      this.timearrFilterArrData3 = !this.timearrFilterArrData3;
      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData2 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterArrData3) {
        this.TimeFilterDataArriva18();
      } else {
        this.removeReturnFilter3();
      }
    },

    TimeFilterDataArrival6() {
      // console.log("sgninngin");
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        console.log(v, "pp");
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "12:00"
          ) {
            $result.push(v);
            console.log(v, "pp1111");
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "12:00"
          ) {
            console.log($result, "$result222222");
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    TimeFilterDataArrival2() {
      // alert("pppp");
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          console.log(v, v.Return[0].Departure.Time, "pp333111");
          if (
            v.Return[0].Departure.Time > "12:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
            console.log(v, "pp111");
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          console.log(v, "pp22555222");
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "12:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
            console.log(v, "pp22222");
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    TimeFilterDataArriva18() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        console.log(v, "pp");
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            // $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoReturnFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "18:00") {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "18:00") {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoReturnFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "12:00" && departureTime < "18:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "12:00" && arrivalTime < "18:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoReturnFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "12:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "12:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    totalReturnTimeData() {
      let $event = [];

      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          //  $twoFilter=false
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          //  $twoFilter=false
        } else {
          $event = this.onewayfilterData;
        }
      }

      if (this.filterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.rountresult = $event;
      }
      if (this.onewayfilterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.onewayresulted = $event;
      }
    },

    removeReturnFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          console.log("pppppp2222");
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "12:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          console.log("pppppp222266666666666666");
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "12:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeReturnFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "12:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "12:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeReturnFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            console.log("tttv22", v.Return[0].Departure.Time);
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    airlineFilter() {
      let storeAirline = [];
      let $event = [];
      if (this.filterData.length > 0) {
        $event = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $event = this.onewayfilterData;
      }

      $event.forEach((v) => {
        if (!storeAirline.includes(v.ownername)) {
          storeAirline.push(v.ownername);
        }
      });
      storeAirline.forEach((s) => {
        let obj = {
          airline: s,
          active: true,
        };
        this.airlineList.push(obj);
      });

      this.filterAirline = this.airlineList;
    },

    selectAirline(data, index) {
      let $data = this.filterData;
      let $result = [];
      this.airlineList.map((a, i) => {
        if (i == index) {
          a.active = !a.active;
        }
        if (a.active) {
          // console.log(count,'count')
          $data.forEach((c) => {
            if (c.ownername == a.airline) {
              $result.push(c);
            }
          });
        }

        $result.sort((a, b) => a.perperson - b.perperson);
        if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
      });

      //  this.Cheapest()
      //   this.Shortest()
      // this.bestlayTime()
      //  this.Recommend()
      $result.sort((a, b) => a.perperson - b.perperson);
      this.rountresult = $result;
    },

    airlineClear() {
      let $result = [];
      let $data = [];

      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }

      this.airlineList.forEach((v, i) => {
        if (i !== 0) {
          v.active = false;
        } else {
          v.active = true;
          $data.forEach((c) => {
            if (c.ownername == v.airline) {
              $result.push(c);
            }
          });
        }
      });

      $result.sort((a, b) => a.perperson - b.perperson);
      this.rountresult = $result;
    },

    airlineSelect() {
      this.airlineList.forEach((v) => {
        v.active = true;
      });
      this.rountresult = this.filterData;
    },

    stopFilter() {
      this.filterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1 || v.Returnjourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1 || v.Returnjourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    multicitystopFilter() {
      this.multiFilterData.forEach((v) => {
        if (
          v.Flight1JourneyTime.Stops === 0 ||
          v.Flight2JourneyTime.Stops === 0 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops === 0) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops === 0)
        ) {
          this.showNonStop = true;
        } else if (
          v.Flight1JourneyTime.Stops === 1 ||
          v.Flight2JourneyTime.Stops === 1 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops === 1) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops === 1)
        ) {
          this.showOneStop = true;
        } else {
          this.showOneplusStop = true;
        }
      });
    },

    gettimedep() {
      // for (let i = 0; i < this.rountresult.length; i++) {
      //   for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
      //     const currentIndex = j;
      //     const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

      //     const time1 = new Date(
      //       `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
      //     );
      //     const time2 = new Date(
      //       `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
      //     );

      //     const diffInMilliseconds = Math.abs(time2 - time1);
      //     const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

      //     const hours = Math.floor(diffInMinutes / 60);
      //     const minutes = diffInMinutes % 60;

      //     this.rountresult[i].Depature[currentIndex].layoverTimes = `${hours
      //       .toString()
      //       .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
      //   }
      // }

      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(diffInHours + " h " + remainingMinutes + " m");
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes1 = c;
          }
        });
      });
    },
    calculateTotalTime() {
      let $data = [];

      if (this.rountresult.length > 0) {
        this.rountresult.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.rountresult.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      } else if (this.onewayresulted.length > 0) {
        this.onewayresulted.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.onewayresulted.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      }
    },
    gettimeret() {
      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(diffInHours + " h " + remainingMinutes + " m");
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes2 = c;
          }
        });
      });
    },

    async getlayTime() {
      let layoverTime = [];
      for (let i = 0; i < this.onewayresulted.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.onewayresulted[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresulted[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresulted[i].Depature[currentIndex].Arrival.Date}T${this.onewayresulted[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresulted[i].Depature[nextIndex].Departure.Date}T${this.onewayresulted[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(diffInHours + " h " + remainingMinutes + " m");
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.onewayresulted.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes = c;
          }
        });
      });
    },

    getmultitime1() {
      let layoverTime1 = [];
      let layoverTime2 = [];
      let layoverTime3 = [];
      let layoverTime4 = [];

      for (let i = 0; i < this.multicityData.length; i++) {
        let layover1 = [];
        for (let j = 0; j < this.multicityData[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight1.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight1[currentIndex].Arrival.Date}T${this.multicityData[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight1[nextIndex].Departure.Date}T${this.multicityData[i].Flight1[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layover1.push(diffInHours + " h " + remainingMinutes + " ms");
        }
        layoverTime1.push(layover1);

        this.multicityData.forEach((a, i) => {
          layoverTime1.forEach((c, j) => {
            if (i == j) {
              a.layoverTimes1 = c;
            }
          });
        });

        // console.log(this.multicityData,'multicityDatamulticityData')

        if (this.multicityData[i].Flight2) {
          let layover2 = [];
          for (let j = 0; j < this.multicityData[i].Flight2.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight2.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight2[currentIndex].Arrival.Date}T${this.multicityData[i].Flight2[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight2[nextIndex].Departure.Date}T${this.multicityData[i].Flight2[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover2.push(diffInHours + " h " + remainingMinutes + " m");
          }
          layoverTime2.push(layover2);

          this.multicityData.forEach((a, i) => {
            layoverTime2.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes2 = c;
              }
            });
          });
        }
        if (this.multicityData[i].Flight3) {
          let layover3 = [];
          for (let j = 0; j < this.multicityData[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight3.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight3[currentIndex].Arrival.Date}T${this.multicityData[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight3[nextIndex].Departure.Date}T${this.multicityData[i].Flight3[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover3.push(diffInHours + " h " + remainingMinutes + " m");
          }
          layoverTime3.push(layover3);

          this.multicityData.forEach((a, i) => {
            layoverTime3.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes3 = c;
              }
            });
          });
        }

        if (this.multicityData[i].Flight4) {
          let layover4 = [];
          for (let j = 0; j < this.multicityData[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight4.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight4[currentIndex].Arrival.Date}T${this.multicityData[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight4[nextIndex].Departure.Date}T${this.multicityData[i].Flight4[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover4.push(diffInHours + " h " + remainingMinutes + " m");
          }
          layoverTime4.push(layover4);

          this.multicityData.forEach((a, i) => {
            layoverTime4.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes4 = c;
              }
            });
          });
        }
      }
    },

    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        const totalAmount = parseFloat(this.onewayfilterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.multiFilterData.length; i++) {
        const totalAmount = parseFloat(this.multiFilterData[i].perperson);

        // console.log(totalAmount, "tatatatat,,,.....");
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min.toFixed(2);
      this.changevalue[1] = max.toFixed(2);

      this.currencyCode = this.preferCurrency;
    },

    // Filter initailly Working ------->

    onewaytrip() {
      let onewayresult = [];
      onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));
      let baggageList = [];
      if (onewayresult) {
        for (let i = 0; i < onewayresult.length; i++) {
          if (onewayresult[i]) {
            baggageList =
              onewayresult[i].DataLists.BaggageAllowanceList.BaggageAllowance;
            this.Journey = onewayresult[i].DataLists.FlightList.Flight;
            let shoppingId = onewayresult[i].ShoppingResponseId;

            let gds = "";
            let sourcePcc = "";
            gds = onewayresult[i].GdsType;
            sourcePcc = onewayresult[i].SourcePcc;

            let refund = [];
            let Baseprice = [];
            let Tax = [];
            // let pricedetail = [];
            let $data = onewayresult[i].OffersGroup.AirlineOffers.Offer;

            let $data_onewayId =
              onewayresult[i].OffersGroup.AirlineOffers.Offer;

            // console.log($data_onewayId,'onewaaayyyyyyy....1...')

            let offerId = [];
            let depsplit = [];
            let resultdata1 = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let deeplinkurl = [];
            let perperson = [];
            let TotalPrice = [];
            let CommissionDetails = [];
            let filterOwnername = [];
            let combinedPricedetail = [];
            let fareType = [];

            $data_onewayId.forEach((v) => {
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }
              ownername.push(s.OwnerName);
              fareType.push(s.FareType);
              deeplinkurl.push(s.DeeplinkUrl);
              // console.log(deeplinkurl,"deeplinkurldeeplinkurl")

              if (!filterOwnername.includes(s.OwnerName)) {
                filterOwnername.push(s.OwnerName);
              }
              // console.log(filterOwnername,'ownernameownername');

              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              if (s.commission_details) {
                CommissionDetails.push(s.commission_details);
              }

              // pricedetail.push(s.OfferItem);

              let adultPriceDetails = {};
              let childPriceDetails = {};
              let infPriceDetails = {};

              s.OfferItem[0].forEach((e) => {
                if (e.PassengerType === "ADT" || e.PassengerType === "JCB") {
                  let getAdultbase =
                    e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                      .BookingCurrencyPrice;
                  let getAdulttax =
                    e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                      .BookingCurrencyPrice;
                  let getAdultcount = e.PassengerQuantity;

                  adultPriceDetails = {
                    baseprice: parseFloat(getAdultbase).toFixed(2),
                    taxprice: parseFloat(getAdulttax).toFixed(2),
                    adultcount: getAdultcount,
                    pax_type: "Adult",
                  };
                  // console.log(adultPriceDetails,'adultPriceDetailsadultPriceDetails...a');
                }

                if (e.PassengerType === "CNN" || e.PassengerType === "JNN") {
                  let getChildbase =
                    e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                      .BookingCurrencyPrice;
                  let getChildtax =
                    e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                      .BookingCurrencyPrice;
                  let getChildcount = e.PassengerQuantity;

                  childPriceDetails = {
                    baseprice: parseFloat(getChildbase).toFixed(2),
                    taxprice: parseFloat(getChildtax).toFixed(2),
                    childcount: getChildcount,
                    pax_type: "Child",
                  };
                  // console.log(childPriceDetails,'childPriceDetailschildPriceDetails...c');
                }

                if (e.PassengerType === "INF" || e.PassengerType === "JNF") {
                  let getInfbase =
                    e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
                      .BookingCurrencyPrice;
                  let getInftax =
                    e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
                      .BookingCurrencyPrice;
                  let getInfcount = e.PassengerQuantity;

                  infPriceDetails = {
                    baseprice: parseFloat(getInfbase).toFixed(2),
                    taxprice: parseFloat(getInftax).toFixed(2),
                    infcount: getInfcount,
                    pax_type: "Inf(lap)",
                  };
                  // console.log(infPriceDetails,'infPriceDetailsinfPriceDetails...i');
                }
              });

              combinedPricedetail.push({
                adultPriceDetails,
                childPriceDetails,
                infPriceDetails,
              });

              s.OfferItem.forEach((v) => {
                if (v[0].FareDetail[0]) {
                  if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                    refund.push("Refundable");
                  } else {
                    refund.push("Non Refundable");
                  }
                }

                if (v[0].FareComponent[0].SegmentRefs) {
                  resultdata1 = v[0].FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));
            });

            // console.log(depsplit, '$data')
            this.Journey1 =
              onewayresult[i].DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
            });

            for (let i = 0; i < this.airlogodata.length; i++) {
              for (let j = 0; j < this.flightsegmetkey.length; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier &&
                  (this.flightsegmetkey[j].MarketingCarrier.AirlineID ===
                    this.airlogodata[i].id ||
                    this.flightsegmetkey[j].MarketingCarrier.Name ===
                      this.airlogodata[i].name)
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            let depjourney = [];
            let Baggage = [];
            let fareBasic = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              fareBasic.push($data[j].OfferItem[0][0].FareComponent[0]);
              
              if($data[j].OfferItem[0][0].BaggageAllowance[0] && $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef.length > 0){
                Baggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                );
              }
              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                }
              }

              depjourney.push(source);
            }
            let BaggageData = [];

            Baggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = TotalPrice[i];
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            fareBasic.forEach((d) => {
              d.segment = d.SegmentRefs.split(" ");
              d.fareBasic = d.FareBasis.FareBasisCode.Code.split(" ");
            });

            // console.log(fareBasic, this.onewayresulted, "fareBasicfareBasic");

            this.onewayresulted.forEach((a, j) => {
              a.Depature.forEach((b) => {
                if (fareBasic[j] && fareBasic[j].segment) {
                  fareBasic[j].segment.forEach((d, i) => {
                    if (b.SegmentKey === d) {
                      b.FareBasisCode = fareBasic[j].fareBasic[i];
                    }
                  });
                }
              });
            });

            // for (let i = 0; i < perperson.length; i++) {
            //   let nume1 = perperson[i];
            //   this.find1.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]);
            //   this.find2.push((Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]);
            // }

            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].Baseprice = Baseprice[i];
              this.onewayresulted[i].TotalPrice = TotalPrice[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              this.onewayresulted[i].priceDetails = combinedPricedetail[i];
              // this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].multipleDatas = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].onewaymulticheck = true;
              this.onewayresulted[i].sourcePcc = sourcePcc;
              this.onewayresulted[i].Gds = gds;
              this.onewayresulted[i].fareType = fareType[i];
              this.onewayresulted[i].BaggageData = BaggageData[i];
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].currency = currency[i];
              this.onewayresulted[i].ownerlogo = ownerlogo[i];
              this.onewayresulted[i].ownername = ownername[i];
              this.onewayresulted[i].deeplinkurl = deeplinkurl[i];
              this.onewayresulted[i].filterAirlines = filterOwnername;
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].perperson = perperson[i];
              this.onewayresulted[i].cabinClass = this.oneway.class;
              this.onewayresulted[i].find1 = this.find1[i];
              this.onewayresulted[i].find2 = this.find2[i];
              this.onewayresulted[i].ShoppingResponseId = shoppingId;
              this.onewayresulted[i].selectId = offerId[i];
              this.onewayresulted[i].Adult = this.aduls;
              this.onewayresulted[i].Child = this.aduls1;
              this.onewayresulted[i].Inf = this.aduls2;
              this.onewayresulted[i].radios = true;
              this.onewayresulted[i].radiosData = false;
              this.onewayresulted[i].agentid = this.agentId;
              this.onewayresulted[i].agencyid = this.agencyId;
              this.onewayresulted[i].agentemail = this.agentEmail;
              this.onewayresulted[i].platformSrc = this.platformSrc;
              this.onewayresulted[i].loginType = this.loginType;
              this.onewayresulted[i].CommissionDetails = CommissionDetails[i];
              this.onewayresulted[i].instandradio = "markup";
              this.onewayresulted[i].tempInstantValue = 0;
              this.onewayresulted[i].instantvalue = null;
              this.onewayresulted[i].calcLoader = false;
              this.onewayresulted[i].disabledBtn = true;
              this.onewayresulted[i].tabSelect = null;
              this.onewayresulted[i].getTotalfare = TotalPrice[i];
              this.onewayresulted[i].getAgentCommission =
                CommissionDetails[i].final_commission_amount;
              this.onewayresulted[i].validMarkupform = false;
            }
            this.onedatafil = true;
            console.log(this.onewayresulted, "oneway.....w...w..w..w");
            this.onewayfilterData = this.onewayresulted;
            this.calculateDates();
            this.getlayTime();
            // this.gettimeret();
            // this.calculateTotalTime();
            ///this.singlepriceData();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();
            this.airlineFilter();
            this.priceRange();
            this.Shortest();
            this.stopFilterOneway();

            if (this.onewayfilterData) {
              this.searchresult = true;
              this.searchloader = false;
              this.filterView = true;
              // document.getElementById("navbar").style.display = "block";
              // document.getElementById("food").style.display = "block";
            }

            this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
            console.log(
              this.onewayresulted,
              "this.onewayresultedthis.onewayresulted"
            );
          }
        }
      }
    },

    multione1(data, multindex, index) {
      // console.log(data, multindex, index, 'data, multindex,index')
      this.radiosData = 1;
      this.onewayresulted.find((c, i) => {
        if (i == index) {
          c.radios = false;
        }
      });

      this.flightdetailsData = data;
    },

    singlepriceData() {
      let $event = [];
      let $data = this.onewayresulted;
      this.onewayresulted.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.onewayresulted = $event.sort((a, b) => a.perperson - b.perperson);

      this.onewayresulted.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (
            a.TotalPrice == b.TotalPrice &&
            a.ownername == b.ownername &&
            a.Depaturejourney.Time !== b.Depaturejourney.Time
          ) {
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.onewayresulted[i].multi = "Show more Options";
        }
      });

      this.onewayfilterData = this.onewayresulted;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    addmoreData(data, index) {
      console.log(data, index, "vasanrh");
      this.radios = 1;
      this.onewayresulted.find((v, i) => {
        if (i == index) {
          v.multipleDatas = !v.multipleDatas;
          v.onewaymulticheck = !v.onewaymulticheck;
        } else {
          v.multipleDatas = false;
          v.onewaymulticheck = true;
        }
      });

      let $event = [];
      this.onewaymultiData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });
      let $data = [];
      $event.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
      });

      this.multiplesameData = $data;
    },

    stopFilterOneway() {
      this.onewayfilterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    cardassigndata($event) {
      console.log($event, "$event4444");

      if ($event.itinery) {
        console.log($event, "$event1111111");
        this.aduls = $event.adult;
        this.aduls1 = $event.child;
        this.aduls2 = $event.infrant;
        this.activeTrip($event.city);

        this.multi_city = {
          adult: $event.adult,
          child: $event.child,
          infrant: $event.infrant,
          class: $event.class,
          city: $event.city,
          itinery: $event.itinery.map((itin) => ({
            ...itin,
            selectedFrom: true,
            selectedTo: true,
            departDate: itin.depdata,
          })),
        };

        this.handleMultiCitySearch(this.multi_city);
      } else {
        this.oneway.from = $event.from;
        this.oneway.to = $event.to;
        this.inputCity = $event.from;
        this.inputCityto = $event.to;
        this.aduls = $event.adult;
        this.aduls1 = $event.child;
        this.aduls2 = $event.inf;
        this.selectedFrom = true;
        this.selectedTo = true;
        this.classType = $event.class;
        this.oneway.dedate = $event.fromDate;
        this.oneway.redate = $event.toDate;

        this.activeTrip(this.oneway.city);
        this.bookingRadio = $event.city;
        this.handleRecentSearch($event);
      }
      this.travelHistory = false;
    },
    recentFromData() {
      const storedRecentFrom =
        JSON.parse(localStorage.getItem("recentSearchData")) || [];
      if (storedRecentFrom.length > 0) {
        let today = new Date();

        const filteredResults = storedRecentFrom.filter((item) => {
          if (item.itinery && item.itinery.length > 0) {
            for (let itineraryItem of item.itinery) {
              let depDate = new Date(itineraryItem.depdata);
              if (depDate < today) {
                return false;
              }
            }
          } else {
            let fromDate = new Date(item.fromDate);
            if (fromDate < today) {
              return false;
            }
          }
          return true;
        });

        localStorage.setItem(
          "recentSearchData",
          JSON.stringify(filteredResults)
        );

        this.RecentSearchData = filteredResults.slice(0, 5);
      } else {
        console.log("No recent searches found.");
      }
    },

    handleRecentSearch(data) {
      let recentSearchObj;
      let isOneway = this.bookingRadio == "onewaytwo" ? true : false;

      if (data) {
        recentSearchObj = {
          from: data.from.name ? data.from.name : data.from,
          to: data.to.name ? data.to.name : data.to,
          fromDate: data.fromDate || "",
          toDate: isOneway ? null : data.toDate || null,
          adult: data.adult || 0,
          child: data.child || 0,
          inf: data.inf || 0,
          city: data.city || "",
          class: data.class || "",
        };
        console.log("Using provided data:", recentSearchObj);
      } else {
        recentSearchObj = {
          from: this.oneway.from.name
            ? this.oneway.from.name
            : this.oneway.from,
          to: this.oneway.to.name ? this.oneway.to.name : this.oneway.to,
          fromDate: this.oneway.dedate || "",
          toDate: isOneway ? null : this.oneway.redate || null,
          adult: this.aduls || 0,
          child: this.aduls1 || 0,
          inf: this.aduls2 || 0,
          city: this.oneway.city || "",
          class: this.classType || "",
        };
        console.log("Using current state values:", recentSearchObj);
      }

      let storedRecentFrom =
        JSON.parse(localStorage.getItem("recentSearchData")) || [];
      console.log("Stored recent searches:", storedRecentFrom);

      storedRecentFrom = storedRecentFrom.filter((search) => {
        const searchFromDate = new Date(search.fromDate).toDateString();
        const searchToDate = new Date(search.toDate).toDateString();
        const recentFromDate = new Date(
          recentSearchObj.fromDate
        ).toDateString();
        const recentToDate = new Date(recentSearchObj.toDate).toDateString();

        return !(
          search.from === recentSearchObj.from &&
          search.to === recentSearchObj.to &&
          searchFromDate === recentFromDate &&
          searchToDate === recentToDate &&
          search.adult === recentSearchObj.adult &&
          search.child === recentSearchObj.child &&
          search.inf === recentSearchObj.inf &&
          search.city === recentSearchObj.city &&
          search.class === recentSearchObj.class
        );
      });

      storedRecentFrom.unshift(recentSearchObj);
      console.log("Updated search list:", storedRecentFrom);

      // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },

    sended() {
      this.localDataValue();
      this.inputCity =
        this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      this.inputCityto =
        this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);
      this.onewayfilterData = [];
      this.rountresult = [];
      1;
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("recentPrefillData");
      console.log(this.oneway.redate, "this.oneway.redate111");
      let isreDate =
        this.oneway.redate && !isNaN(new Date(this.oneway.redate).getTime())
          ? true
          : false;
      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        isreDate
      ) {
        // console.log(this.oneway.redate, "this.oneway.redate22");
        this.oneway.city = this.bookingRadio;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;

        this.searchloader = true;

        let result1 = [];

        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY/MM/DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY/MM/DD");

        let rountdetail = {
          from: this.inputCity,
          to: this.inputCityto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
          agentid: this.agentId,
          agencyid: this.agencyId,
          agentemail: this.agentEmail,
          sessionid: this.sessionId,
          domain: this.domainName,
          pos: this.domainPos,
          currency: this.domainCurrency,
          platform_src: this.platformSrc,
          login_type: this.loginType,
          access_level: this.accessLevel,
          fare_type: this.fareType,
          advanceDetail: JSON.stringify(this.advaceData),
        };

        console.log(rountdetail, "rountdetailrountdetail");
        const resultData = this.handleRecentSearch();

        localStorage.setItem("recentSearchData", JSON.stringify(resultData));

        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedone() {
      this.localDataValue();
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("recentPrefillData");
      this.inputCity =
        this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      this.inputCityto =
        this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);

      this.onewayfilterData = [];
      this.rountresult = [];

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.bookingRadio;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;

        let result1 = [];
        // console.log(this.oneway, "onewayonewayonewayonewayoneway");
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY/MM/DD");

        let rountdetail = {
          from: this.inputCity,
          to: this.inputCityto,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
          agentid: this.agentId,
          agencyid: this.agencyId,
          agentemail: this.agentEmail,
          sessionid: this.sessionId,
          domain: this.domainName,
          pos: this.domainPos,
          currency: this.domainCurrency,
          platform_src: this.platformSrc,
          login_type: this.loginType,
          access_level: this.accessLevel,
          fare_type: this.fareType,
          advanceDetail: JSON.stringify(this.advaceData),
        };

        const resultData = this.handleRecentSearch();

        localStorage.setItem("recentSearchData", JSON.stringify(resultData));

        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });

        // this.sended1()
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sended1() {
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      this.oneway.city = this.bookingRadio;
      this.oneway.adult = this.aduls;
      this.oneway.infrant = this.aduls2;
      this.oneway.child = this.aduls1;
      // this.oneway.class = this.select;

      // this.fareType = this.$route.query.fare_type;
      // console.log(this.fareType,'typetypetypetype');

      let rountdetail = {
        from: null,
        to: null,
        dedate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infrant: this.oneway.infrant,
        city: this.oneway.city,
      };
      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);
      this.oneway.from = this.inputCity;
      this.oneway.to = this.inputCityto;
      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;

        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;

          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(
            this.oneway.redate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "ECONOMY";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "BUSINESS";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "FIRST";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        // if (cabin_type == "Economy") {
        //   cabin_type = "Y";
        //   this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
        //     cabin_type;
        // } else if (cabin_type == "Business") {
        //   cabin_type = "C";
        //   this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
        //     cabin_type;
        // } else {
        //   cabin_type = "F";
        //   this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
        //     cabin_type;
        // }

        // let fareTypeArray =  Array.isArray(this.fareType) ? this.fareType : [this.fareType];

        let $option = this.advanceDetailData;
        console.log($option, "$option");
        this.Rountdata.request.AirShoppingRQ.Preference.IncludedAirlines =
          $option.includedAirlines || "";
        this.Rountdata.request.AirShoppingRQ.Preference.ExcludedAirlines =
          $option.excludedAirlines || "";

        this.Rountdata.request.AirShoppingRQ.Preference.IncludedAirports =
          $option.includedAirports || "";
        this.Rountdata.request.AirShoppingRQ.Preference.ExcludedAirports =
          $option.excludedAirports || "";

        this.Rountdata.request.AirShoppingRQ.Preference.Penalities =
          $option.selectedPenalties || "";

        this.Rountdata.request.AirShoppingRQ.Preference.LayoverHours =
          $option.layDuration || "";

        this.Rountdata.request.AirShoppingRQ.Preference.AvoidCountry =
          $option.AvoidCountry || "";
        if ($option.selectedStops == "" || $option.selectedStops == undefined) {
          this.Rountdata.request.AirShoppingRQ.Preference.Stops = [];
          console.log("Condition passed:", $option.selectedStops);
        } else {
          console.log("Condition failed:", $option.selectedStops);
          this.Rountdata.request.AirShoppingRQ.Preference.Stops = [
            `${$option.selectedStops}`,
          ];
        }
        this.Rountdata.request.AirShoppingRQ.Preference.FareType =
          $option.selectedFareTypes ? $option.selectedFareTypes : this.fareType;
        this.Rountdata.request.AirShoppingRQ.Preference.CalendarSearchDays =
          $option.selectedDays || "";
        this.Rountdata.request.AirShoppingRQ.Preference.CalendarSearch =
          $option.selectedDays > 0 ? "true" : "";

        this.Rountdata.agentid = this.agentId;
        this.Rountdata.agencyid = this.agencyId;
        this.Rountdata.agentemail = this.agentEmail;
        this.Rountdata.sessionid = this.sessionId;
        this.Rountdata.PlatformSource = this.platformSrc;
        this.Rountdata.login_type = this.loginType;
        // this.Rountdata.request.AirShoppingRQ.Preference.FareType =
        //   this.fareType;
        this.Rountdata.country = this.domainPos;
        this.Rountdata.request.AirShoppingRQ.MetaData.PointOfSale =
          this.domainPos;
        this.Rountdata.request.AirShoppingRQ.MetaData.PointOfSaleCurrency =
          this.domainCurrency;
        this.Rountdata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.Rountdata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;

        console.log(
          this.domainCurrency,
          "domainCurrency",
          this.domainPos,
          " domainPos",
          this.preferCurrency,
          " preferCurrency",
          this.preferCurrency,
          " preferCurrency"
        );

        console.log(
          this.makeSearchUrl,
          this.Rountdata,
          "thisRountdatathisRountdatathis"
        );

        axios
          .post(this.makeSearchUrl, this.Rountdata, {
            headers: {
              "Content-Type": "application/json",
            },
          })

          .then((response) => {
            // console.log(response," this.responseData this.responseData this.responseData");

            this.responseData.push(response.data);

            console.log(
              this.responseData,
              "this.responseData this.responseData this.responseData"
            );

            let rountData = [];
            rountData = JSON.stringify(this.responseData);
            localStorage.setItem("rounttrip", rountData);

            let result = "";
            result = localStorage.getItem("rounttrip");
            this.localData.push(JSON.parse(result));

            localStorage.removeItem("recentPrefillData");
            // console.log(this.localData, " this.localData this.localData");
            if (this.localData) {
              this.roundedtrip();
            }

            // if (response.data.Errors =='Errors') {
            //   this.searchresult = false;
            //   this.searchloader = true;
            //   setTimeout(() => {
            //     this.pageError = true;
            //     this.searchresult = false;
            //   this.searchloader = false;
            //   }, 7000);
            // }

            // else {

            //   this.responseData.push(response.data.AirShoppingRS);
            //   console.log(this.responseData,'this.responseDatathis.responseData')
            //   console.log(
            //     this.responseData,
            //     " this.responseData this.responseData this.responseData"
            //   );
            //   let rountData = [];
            //   rountData = JSON.stringify(this.responseData);
            //   localStorage.setItem("rounttrip", rountData);
            //   let result = "";
            //   result = localStorage.getItem("rounttrip");
            //   this.localData.push(JSON.parse(result));
            //   console.log(this.localData, " this.localData this.localData");
            //   if (this.localData) {
            //     this.roundedtrip();
            //   }
            // }
          })

          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        // alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    sendedone1() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      let resul =
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(resul, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;

        this.oneway.city = this.bookingRadio;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        let res = "";

        if (this.oneway.from.name) {
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate, "DD MMM-YYYY").format(
          "YYYY-MM-DD"
        );
        // let rountdetail = {
        //   from: this.roundfrom,
        //   to: this.roundto,
        //   dedate: this.urldedate,
        //   redate: this.urlredate,
        //   class: this.oneway.class,
        //   child: this.oneway.child,
        //   adult: this.oneway.adult,
        //   infrant: this.oneway.infrant,
        //   city: this.oneway.city,
        // };

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "ECONOMY";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "BUSINESS";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "FIRST";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        let $option = this.advanceDetailData;

        console.log($option, "$option");
        this.onewaydata.request.AirShoppingRQ.Preference.IncludedAirlines =
          $option.includedAirlines || "";
        this.onewaydata.request.AirShoppingRQ.Preference.ExcludedAirlines =
          $option.excludedAirlines || "";

        this.onewaydata.request.AirShoppingRQ.Preference.IncludedAirlines =
          $option.includedAirports || "";
        this.onewaydata.request.AirShoppingRQ.Preference.ExcludedViaStops =
          $option.excludedAirports || "";

        this.onewaydata.request.AirShoppingRQ.Preference.Penalities =
          $option.selectedPenalties || "";

        this.onewaydata.request.AirShoppingRQ.Preference.LayoverHours =
          $option.layDuration || "";

        this.onewaydata.request.AirShoppingRQ.Preference.AvoidCountry =
          $option.AvoidCountry || "";
        if ($option.selectedStops == "" || $option.selectedStops == undefined) {
          this.onewaydata.request.AirShoppingRQ.Preference.Stops = [];
          console.log("Condition passed:", $option.selectedStops);
        } else {
          console.log("Condition failed:", $option.selectedStops);
          this.onewaydata.request.AirShoppingRQ.Preference.Stops = [
            `${$option.selectedStops}`,
          ];
        }

        this.onewaydata.request.AirShoppingRQ.Preference.FareType =
          $option.selectedFareTypes || "";
        this.onewaydata.request.AirShoppingRQ.Preference.CalendarSearchDays =
          $option.selectedDays || "";
        this.onewaydata.request.AirShoppingRQ.Preference.CalendarSearch =
          $option.selectedDays > 0 ? "true" : "";

        this.onewaydata.agentid = this.agentId;
        this.onewaydata.agencyid = this.agencyId;
        this.onewaydata.agentemail = this.agentEmail;
        this.onewaydata.sessionid = this.sessionId;
        this.onewaydata.PlatformSource = this.platformSrc;
        this.onewaydata.login_type = this.loginType;
        // this.onewaydata.request.AirShoppingRQ.Preference.FareType =
        //   this.fareType;
        this.onewaydata.country = this.domainPos;
        this.onewaydata.request.AirShoppingRQ.MetaData.PointOfSale =
          this.domainPos;
        this.onewaydata.request.AirShoppingRQ.MetaData.PointOfSaleCurrency =
          this.domainCurrency;
        this.onewaydata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.onewaydata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;

        console.log(
          this.makeSearchUrl,
          this.onewaydata,
          "this.onewaydatathis.onewaydata"
        );

        axios
          .post(this.makeSearchUrl, this.onewaydata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "responseresponseresponse");
            this.responseData.push(response.data);
            // console.log(
            //   this.responseData,
            //   "this.responseDatathis.responseData"
            // );
            let rountData = [];
            rountData = JSON.stringify(this.responseData);

            localStorage.setItem("onewaytrip", rountData);
            localStorage.removeItem("recentPrefillData");

            if (rountData) {
              this.onewaytrip();
            }

            //             if (response.data.AirShoppingRS.Errors) {
            //               this.searchresult = false;
            //               this.searchloader = true;
            //               setTimeout(() => {
            //                 this.pageError = true;
            //                 this.searchresult = false;
            //               this.searchloader = false;
            //               }, 7000);
            //             }

            // else{
            //   this.responseData.push(response.data.AirShoppingRS);
            // console.og(this.responseData,'this.responseDatathis.responseData')
            // let rountData = [];
            // rountData = JSON.stringify(this.responseData);

            // localStorage.setItem("onewaytrip", rountData);
            // this.$router.push({ path: "/search", query: rountdetail });

            // if (rountData) {
            //   this.onewaytrip();
            // }
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedMulti() {
      console.log(this.multi_city, "this.multi_city");
      if (
        this.multi_city.itinery[0].from &&
        this.multi_city.itinery[0].to &&
        this.multi_city.itinery[0].depdata &&
        this.multi_city.itinery[1].from &&
        this.multi_city.itinery[1].to &&
        this.multi_city.itinery[1].depdata &&
        (!this.multi_city.itinery[2] ||
          (this.multi_city.itinery[2].from &&
            this.multi_city.itinery[2].to &&
            this.multi_city.itinery[2].depdata)) &&
        (!this.multi_city.itinery[3] ||
          (this.multi_city.itinery[3].from &&
            this.multi_city.itinery[3].to &&
            this.multi_city.itinery[3].depdata))
      ) {
        this.multi_city.adult = this.aduls;
        this.multi_city.child = this.aduls1;
        this.multi_city.infrant = this.aduls2;
        this.multi_city.class = this.classType;

        console.log(this.multi_city, "datasdatasdatas");
        localStorage.removeItem("recentPrefillData");

        let multicityData = {
          itinery: [],
          class: "",
          child: "",
          adult: "",
          infrant: "",
          city: "multithree",
          agentid: this.agentId,
          agencyid: this.agencyId,
          agentemail: this.agentEmail,
          sessionid: this.sessionId,
          domain: this.domainName,
          pos: this.domainPos,
          currency: this.domainCurrency,
          platform_src: this.platformSrc,
          login_type: this.loginType,
          access_level: this.accessLevel,
          fare_type: this.fareType,
        };

        multicityData.class = this.multi_city.class;
        multicityData.adult = this.multi_city.adult;
        multicityData.child = this.multi_city.child;
        multicityData.infrant = this.multi_city.infrant;
        multicityData.city = this.multi_city.city;
        this.multi_city.itinery.forEach((v) => {
          console.log(v, "multy");
          let itineraryObject = {
            from: v.from.name ? v.from.name : v.from,
            to: v.to.name ? v.to.name : v.to,
            depdata: moment(v.depdata).format("YYYY-MM-DD"),
          };
          multicityData.itinery.push(itineraryObject);
        });

        const resultData = this.handleMultiCitySearch(multicityData);
        localStorage.setItem("recentSearchData", JSON.stringify(resultData));
        console.log(multicityData, "thismulticityData5555555555555");

        let muti_city = JSON.stringify(multicityData);
        console.log(muti_city, "muticitymuticity");

        this.$router.push({ path: "/search", query: { muti_city } });

        setTimeout(() => {
          location.reload();
        }, 500);
      }
    },

    handleMultiCitySearch(newMultiData) {
      let storedRecentFrom = JSON.parse(
        localStorage.getItem("recentSearchData") || "[]"
      );

      storedRecentFrom = storedRecentFrom.filter((existingItem) => {
        const existingItinery = existingItem.itinery || [];

        const isDuplicate = newMultiData.itinery.every((newItin) =>
          existingItinery.some(
            (existingItin) =>
              newItin.from === existingItin.from &&
              newItin.to === existingItin.to &&
              newItin.depdata === existingItin.depdata
          )
        );

        const isAdditionalPropsMatch =
          existingItem.adult === newMultiData.adult &&
          existingItem.child === newMultiData.child &&
          existingItem.infrant === newMultiData.infrant &&
          existingItem.class === newMultiData.class &&
          existingItem.city === newMultiData.city;

        return !(isDuplicate && isAdditionalPropsMatch);
      });

      storedRecentFrom.unshift(newMultiData);

      // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },

    sendedMulticity() {
      for (let i = 0; i < this.multi_city.itinery.length; i++) {
        if (this.multi_city.itinery[i].from) {
          this.multi_city.itinery[i].multiFromError = false;

          // console.log(this.multi_city.itinery[i].from, "mememememe.....");
        } else {
          this.multi_city.itinery[i].multiFromError = true;
        }

        if (this.multi_city.itinery[i].to) {
          this.multi_city.itinery[i].multiToError = false;
        } else {
          this.multi_city.itinery[i].multiToError = true;
        }

        if (this.multi_city.itinery[i].depdata) {
          this.multi_city.itinery[i].multiDateError = false;
        } else {
          this.multi_city.itinery[i].multiDateError = true;
        }
      }
    },

    fromClick($event) {
      // console.log($event, "p.....1");
      if ($event.from) {
        $event.from = "";
        this.fromErrorMessage = true;

        // console.log(this.fromErrorMessage, "p.....2");
      }
    },

    toClick($event) {
      // console.log($event, "p.....");
      if ($event.to) {
        $event.to = "";
        this.toErrorMessage = true;

        // console.log(this.toErrorMessage, "p.....3");
      }
    },

    multiFromClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;

            // console.log("vvvvv......4444");
            if (!v.from) {
              v.multiFromError = true;

              // console.log("vvvvv......555");
            } else {
              v.multiFromError = false;

              // console.log("vvvvv......666");
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;

            // console.log("vvvvv......111");
            if (!v.to) {
              // console.log("vvvvv......222");
              v.multiToError = true;
            } else {
              v.multiToError = false;

              // console.log("vvvvv......333");
            }
          }
        }
      });
    },

    onChangeFrom(event) {
      // console.log(event,'eventevent');
      if (event.length >= 3) {
        this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      }
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
          // this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          // this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    onChangeMultiFrom(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
          } else {
            v.multiToError = true;
          }
        }
      });

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (
        this.oneway.redate &&
        !isNaN(new Date(this.oneway.redate).getTime())
      ) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    // autocompletJson() {

    //   const getConfigData = getConfigDataFromLocalStorage();

    //   this.airlineIcaoUrl =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.url;
    //   this.airlineIcaoType =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.type;

    //   axios
    //     .get(this.airlineIcaoUrl, {
    //       headers: {
    //         "Content-Type": this.airlineIcaoType,
    //       },
    //     })
    //     .then((response) => {
    //       let airlineIcao = response.data;

    //       if (airlineIcao) {

    //         Object.keys(airlineIcao).forEach((icao) => {
    //           const airport = airlineIcao[icao];

    //           if (airport.iata !== "") {
    //             this.fromAirports.push({
    //               iata: airport.iata,
    //               icao: airport.icao,
    //               places: ` ${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //             });
    //           }
    //         });
    //       }
    //     })

    //     .catch((error) => {
    //       console.log(error, "erroroor.....");
    //     });
    // },
    async typeCity(event, $data) {
      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }
      this.city = [];
      this.city1 = [];
      // let $event = [];
      console.log(
        event,
        $data,
        this.autocompleteUrl,
        "searchsearchsearchsearch"
      );
      if ($data == 1 && event.length > 0) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 0) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 3) {
        await axios
          .get(`${this.autocompleteUrl}?airportString=${event}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          })
          .then((response) => {
            console.log(response, response.data, "responseyyyyyyy");

            if ($data == 1) {
              this.fromloader = false;
              this.city = response.data;
              this.typedrop = true;
            } else if ($data == 2) {
              this.toloader = false;
              this.city1 = response.data;
              this.typedrop1 = true;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },
    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      // let $event = [];
      console.log(event, index, "searchsearchsearchsearch...1");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });

          for (let i = 1; i < this.multi_city.itinery.length; i++) {
            this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
          }
        }

        await axios
          .get(`${this.autocompleteUrl}?airportString=${event}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          })
          .then((response) => {
            console.log(response.data, "responsedata");

            if ($num == 1) {
              this.multi_city.itinery.map((v) => {
                v.fromloader = false;
              });

              this.multidrop = response.data;
              console.log(this.multidrop, "this.multidrop");
            } else if ($num == 2) {
              this.multi_city.itinery.map((v) => {
                v.toloader = false;
              });

              this.multidrop1 = response.data;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },

    focus1() {
      setTimeout(() => {
        this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.to) {
        setTimeout(() => {
          console.log(
            this.oneway.to,
            "onewaytoonewayto",
            this.$refs.secondAutoComplete
          );

          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        }, 100);
      }
    },

    focus2() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.dedate) {
        this.$refs.dpRef1.openMenu();
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          // console.log(this.multi_city.itinery[0].to, "bfbfbfbf.....");
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    Multifocus1(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    Multifocus2(data, index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        console.log(data, "bfbfbfbf.....");
        // return data.to.name

        for (let i = 0; i < 1; i++) {
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.isIcon = false;
      } else {
        this.isIcon = true;
      }
    },
    // handleCalenderResize() {
    //   if (window.innerWidth < 400) {
    //     this.multiCalender = false;
    //   } else {
    //     this.multiCalender = true;
    //   }
    // },

    // Form Header section

    getMultifrom(data) {
      // console.log(data, 'datatatetee')

      if (data.itinery[0].from === null) {
        return null;
      } else {
        const $data = data.itinery[0]?.from?.name;
        return $data || data.itinery[0].from;
      }

      // if (data.itinery[0].from.name) {
      //   return data.itinery[0].from.name
      // }
      // else {
      //   return data.itinery[0].from
      // }
    },
    getMultiDedate1(data) {
      if (data.itinery[0].depdata) {
        // return data.itinery[0].depdata
        return moment(data.itinery[0].depdata, "DD-MMM-YYYY").format(
          "DD-MMM-YYYY"
        );
      } else {
        return "";
      }
    },

    getMultiTo(data) {
      // console.log(data, "Testing...1...1...1.");

      if (data.itinery[data.itinery.length - 1].to === null) {
        return null;
      } else {
        const $data = data.itinery[data.itinery.length - 1]?.to?.name;
        return $data || data.itinery[data.itinery.length - 1].to;
      }
    },

    getMultiDedate2(data) {
      if (data.itinery[data.itinery.length - 1].depdata) {
        // return data.itinery[data.itinery.length - 1].depdata
        return moment(
          data.itinery[data.itinery.length - 1].depdata,
          "DD-MMM-YYYY"
        ).format("DD-MMM-YYYY");
      } else {
        return "";
      }
    },

    getMultiClass(data) {
      console.log(data, "mdatatiCadata");
      if (data.class) {
        return data.class;
      } else {
        return "";
      }
    },

    //     getMultifrom(data) {
    //       if (data.length>0) {

    //   console.log(data,'wrthofjsdosjkdjk')

    //    if (data.itinery[0].from) {
    //           return data.itinery[0].from
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate1(data) {

    //          if (data.length>0) {
    //    if (data.itinery[0].depdata) {
    //           return data.itinery[0].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },

    //     getMultiTo(data) {

    //         if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].to) {
    //           return data.itinery[data.itinery.length - 1].to
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate2(data) {

    //          if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].depdata) {
    //           return data.itinery[data.itinery.length - 1].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }
    //     },

    //     getMultiClass(data) {
    //       if (data.classes) {
    //         return data.classes
    //       }
    //       else {
    //         return ''
    //       }

    //     },
    getMultiAdult(data) {
      // console.log(data, 'lkllklklklk...22..2.2.2')
      if (data.adult > 0) {
        return data.adult;
      } else {
        return "";
      }
    },
    getMultiChild(data) {
      if (data.child > 0) {
        return data.child;
      } else {
        return "";
      }
    },
    getMultiInfrant(data) {
      if (data.infrant > 0) {
        return data.infrant;
      } else {
        return "";
      }
    },

    // Form Header section End

    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      console.log(getConfigData, "getConfigDatagetConfigData");

      if (getConfigData) {
        this.makeSearchUrl =
          getConfigData.payload.portal_configuration.API_endpoints.search;
        this.advanceOptionvalue =
          getConfigData.payload.portal_configuration.advanced_search;
        this.autocompleteUrl =
          getConfigData.payload.portal_configuration.API_endpoints.autocomplete;
        this.instantApi =
          getConfigData.payload.portal_configuration.API_endpoints.instant_markup;
        this.airLogoApi =
          getConfigData.payload.portal_configuration.content_data.airlines_logo;
        this.preferCurrency =
          getConfigData.payload.portal_configuration.currency.iso_code;
        this.metaCurrency =
          getConfigData.payload.portal_configuration.currency.iso_code;
        this.portalId = getConfigData.payload.portal_configuration.portal_id;
        this.displayCurrency =
          getConfigData.payload.portal_configuration.currency.display;
        this.fareRulesApi =
          getConfigData.payload.portal_configuration.API_endpoints.farerules;
        this.pricingApi =
          getConfigData.payload.portal_configuration.API_endpoints.pricingconfirmation;
      }
    },
  },

  // AutoComplete Functions End

  watch: {
    //   'oneway.dedate': function (newVal) {
    //   console.log('Updated dedate:', newVal);
    // },
    "$route.path": {
      immediate: true,
      handler(newValue) {
        if (newValue === "/") {
          console.log(newValue, "newValuepathnewValuepath");
          //   setTimeout(() => {
          //   location.reload();
          // }, 500);
          // this.searchloader = true;
          // setTimeout(() => {
          //   this.searchloader = false;
          // }, 10000);
        }
      },
    },

    fareType(newVal) {
      if (newVal.length === 0) {
        this.$nextTick(() => {
          this.fareType.push(this.lastChecked || "PUB");
        });
      } else {
        this.lastChecked = newVal[newVal.length - 1];
      }
    },
    // selectedFareTypes(newVal){
    //   if (newVal.length === 0) {
    //     this.$nextTick(() => {
    //       this.selectedFareTypes.push(this.lastChecked || "PUB");
    //     });
    //   } else {
    //     this.lastChecked = newVal[newVal.length - 1];
    //   }
    // },

    bookingRadio(newVal) {
      this.activeTrip(newVal);
    },

    classType(newSelectValue) {
      console.log("select property changed to:", newSelectValue);

      if (newSelectValue) {
        setTimeout(() => {
          const focusDrop = this.$refs.countrySelect.focus();
          if (focusDrop) {
            this.$refs.countrySelect.focus();
          }

          this.classSelect = false;
          console.log(this.classSelect);
        }, 100);
      }
    },

    RefundableData(data) {
      if (
        (data && this.nonRefundableData) ||
        (!data && !this.nonRefundableData)
      ) {
        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = this.onewayfilterData;
        } else if (this.filterData.length > 0) {
          this.rountresult = this.filterData;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = this.multiFilterData;
        }
      } else if (data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayfilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }

        if (this.filterData.length > 0) {
          let $data = this.filterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.rountresult = $result;
        }

        if (this.multiFilterData.length > 0) {
          let $data = this.multiFilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.multicityData = $result;
        }
      } else if (!data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayresulted;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }
        if (this.filterData.length > 0) {
          let $data = this.rountresult;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multicityData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          this.multicityData = $result;
        }
      }
    },

    nonRefundableData(data) {
      if ((data && this.RefundableData) || (!data && !this.RefundableData)) {
        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = this.onewayfilterData;
        } else if (this.filterData.length > 0) {
          this.rountresult = this.filterData;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = this.multiFilterData;
        }
      } else if (data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayfilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }
        if (this.filterData.length > 0) {
          console.log("ppppp", this.filterData);
          let $data = this.filterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result555");
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multiFilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.multicityData = $result;
        }
      } else if (!data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayresulted;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }

        if (this.filterData.length > 0) {
          let $data = this.rountresult;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== " Non Refundable") {
              $result.push(v);
            }
          });
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multicityData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Non Refundable") {
              $result.push(v);
            }
          });
          this.multicityData = $result;
        }
      }
    },

    changevalue(newVal) {
      this.rountresult = [];
      this.onewayresulted = [];
      let filterData = [];
      let sortAmount = [];

      console.log("Slider value changed:", newVal);

      for (let i = 0; i < this.filterData.length; i++) {
        if (
          this.filterData[i].perperson >= newVal[0] &&
          this.filterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.filterData[i]);
        }
      }
      // this.rountresult = totalPricefilter.sortBy(sortAmount, ["perperson"]);
      // console.log(this.rountresult,'pppppppppppppppppppp')

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        if (
          this.onewayfilterData[i].perperson >= newVal[0] &&
          this.onewayfilterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.onewayfilterData[i]);
        }
      }
      // this.onewayresulted = totalPricefilter.sortBy(sortAmount, ["perperson"]);

      for (let i = 0; i < this.multiFilterData.length; i++) {
        if (
          this.multiFilterData[i].perperson >= newVal[0] &&
          this.multiFilterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.multiFilterData[i]);
        }
      }
      filterData = sortAmount;
      filterData.sort((a, b) => a.perperson - b.perperson);

      if (this.multiFilterData.length > 0) {
        this.multicityData = filterData;
      } else if (this.onewayfilterData.length > 0) {
        this.onewayresulted = filterData;
      } else if (this.filterData.length > 0) {
        this.rountresult = filterData;

        console.log(this.rountresult, "0000000");
      }

      this.Shortest();
      this.Recommend();
      this.Cheapest();
      this.bestlayTime();
    },

    NonStopFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let multifilterData = [];
      let oneway = false;
      let round = false;
      let multi = false;
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      } else if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      } else {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }

      if (newVal && this.OneStopFil && this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.OneStopFil && !this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.OneStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 3) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 3) <= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OnePlusFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1 || v.Returnjourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        //  console.log('one',roundfilterData)
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 0) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops == 0 ||
              v.Flight2JourneyTime.Stops == 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) == 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) == 0
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWayncbgdghghg");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
              //  console.log("good")
            } else {
              $result.push(v);
            }
            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 0 ||
              v.Flight2JourneyTime.Stops !== 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) !== 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) !== 0
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },

    OneStopFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let multifilterData = [];
      let multi = false;
      let oneway = false;
      let round = false;
      // console.log(this.rountresult, 'this.rountresult')
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      } else if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      } else {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }

      if (newVal && this.NonStopFil && this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.NonStopFil && !this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.NonStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 4) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 4) <= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OnePlusFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0 || v.Returnjourney.Stops !== 0) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 0 ||
              v.Flight2JourneyTime.Stops !== 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) !== 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) !== 0
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        console.log("one", roundfilterData);
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 1 || v.Returnjourney.Stops == 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops == 1 ||
              v.Flight2JourneyTime.Stops == 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) == 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) == 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (!v.Depaturejourney.Stops == 1 || !v.Returnjourney.Stops == 1) {
              //  $result.push(v)
            } else {
              $result.push(v);
            }

            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },

    OnePlusFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let oneway = false;
      let round = false;
      let multifilterData = [];
      let multi = false;
      // console.log(this.rountresult, 'this.rountresult')
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      }
      if (this.filterData.length > 0) {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }
      if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      }

      if (newVal && this.NonStopFil && this.OneStopFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.NonStopFil && !this.OneStopFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.NonStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1 || v.Returnjourney.Stops !== 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OneStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops >= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops >= 1 || v.Returnjourney.Stops >= 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops >= 1 ||
              v.Flight2JourneyTime.Stops >= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) >= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) >= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        console.log("one", roundfilterData);
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops > 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (roundfilterData.length > 0) {
          console.log(newVal, "newVal");
          console.log(
            roundfilterData,
            "roundfilterDataroundfilterDataroundfilterData"
          );
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1) {
              console.log(v.Depaturejourney.Stops, "v.Depaturejourney.Stops");
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWayFilterDataWay");
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops > 1 ||
              v.Flight2JourneyTime.Stops > 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) > 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) > 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              $result.push(v);
            }
            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 3) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 3) <= 1
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },
  },

  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },

    clearallFilter() {
      if (
        this.includedAirlines.length > 0 ||
        this.excludedAirlines.length > 0 ||
        this.selectedStops >= 0 ||
        this.AvoidCountry.length > 0 ||
        this.includedAirports.length > 0 ||
        this.excludedAirports.length > 0 ||
        this.selectedDays.length > 0 ||
        this.selectedFareTypes.length > 0 ||
        this.selectedPenalties.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    this.advanceDetailData = JSON.parse(
      this.$route.query.advanceDetail || "[]"
    );
    console.log("Parsed advanceDetail:", this.advanceDetailData);
    // this.autocompletJson();
    this.selectedFareTypes = this.advanceDetailData.selectedFareTypes
      ? this.advanceDetailData.selectedFareTypes
      : this.fareType;

    this.includedAirlines = this.advanceDetailData.includedAirlines
      ? this.advanceDetailData.includedAirlines
      : [];
    this.airlineIncludeExclude = this.includedAirlines.join();

    this.includedAirports = this.advanceDetailData.includedAirports
      ? this.advanceDetailData.includedAirports
      : [];

    this.excludedAirlines = this.advanceDetailData.excludedAirlines
      ? this.advanceDetailData.excludedAirlines
      : [];
    this.airlineIncludeExclude1 = this.excludedAirlines.join();

    this.excludedAirports = this.advanceDetailData.excludedAirports
      ? this.advanceDetailData.excludedAirports
      : [];

    this.layDuration = this.advanceDetailData.layDuration
      ? this.advanceDetailData.layDuration
      : null;

    this.selectedStops = this.advanceDetailData.selectedStops
      ? this.advanceDetailData.selectedStops
      : "";

    this.selectedDays = this.advanceDetailData.selectedDays
      ? this.advanceDetailData.selectedDays
      : "";

    this.selectedPenalties = this.advanceDetailData.selectedPenalties
      ? this.advanceDetailData.selectedPenalties
      : [];

    if (this.excludedAirlines && this.excludedAirlines.length > 0) {
      this.airex = false;
    } else if (this.includedAirlines && this.includedAirlines.length > 0) {
      this.airex = true;
    } else {
      this.airex = true;
    }
    let portalUrl = this.$route.href;
    let portalPath = this.$route.path;
    // console.log(portalUrl,'portalUrlportalUrl')

    if (portalPath === "/") {
      // console.log(portalUrl,'portalUrlportalUrl...1')
      localStorage.setItem("portalUrl", JSON.stringify(portalUrl));
    }

    let getqueryroute;
    if (!this.$route.query.muti_city) {
      if (this.$route.query.city) {
        getqueryroute = this.$route.query;

        this.bookingRadio = getqueryroute.city;
        // this.bookingRadio = getqueryroute.city;
        console.log(getqueryroute, "queryqueryquery...1");
      } else {
        this.bookingRadio = "roundone";
        // getqueryroute.city = 1 || "1" ;
        getqueryroute = this.$route.query;
        console.log(getqueryroute, this.bookingRadio, "queryqueryquery...3");
      }
    } else {
      getqueryroute = JSON.parse(this.$route.query.muti_city);

      this.bookingRadio = getqueryroute.city;
      this.bookingRadio = getqueryroute.city;
      console.log(getqueryroute, "queryqueryquery...2");
    }

    if (getqueryroute) {
      this.agentId = getqueryroute.agentid;
      this.agencyId = getqueryroute.agencyid;
      this.agentEmail = getqueryroute.agentemail;
      this.domainPos = getqueryroute.pos;
      this.domainName = getqueryroute.domain;
      this.domainCurrency = getqueryroute.currency;
      this.sessionId = getqueryroute.sessionid;
      this.platformSrc = getqueryroute.platform_src;
      this.loginType = getqueryroute.login_type;
      this.accessLevel = getqueryroute.access_level;
      this.getfareType = getqueryroute.fare_type;
    }

    // console.log(getqueryroute,'getqueryroutegetqueryroute');

    // this.fareType = [];
    // this.fareType = this.getfareType ? [this.getfareType] : (this.accessLevel > 0 ? ['PUB','NET'] : []);

    console.log(this.getfareType, "fareTypefareTypedasdasd...0");

    if (this.getfareType) {
      if (Array.isArray(this.getfareType)) {
        this.fareType = this.getfareType;
        console.log(this.fareType, "fareTypefareTypedasdasd...1");
      } else {
        this.fareType = [this.getfareType];
        console.log(this.fareType, "fareTypefareTypedasdasd...2");
      }
    } else {
      this.fareType = ["PUB", "NET"];
    }

    // else if(this.accessLevel >= 0){
    //   this.fareType =  ['PUB','NET'];
    //   console.log(this.fareType,'fareTypefareTypedasdasd...3');
    // }

    console.log(
      "agentid :",
      this.agentId,
      " agencyid :",
      this.agencyId,
      "domainPos : ",
      this.domainPos,
      " domainName : ",
      this.domainName
    );
    console.log(
      "domainCurrency : ",
      this.domainCurrency,
      " sessionId : ",
      this.sessionId,
      " platformSrc :",
      this.platformSrc,
      "loginType",
      this.loginType,
      "accessLevel",
      this.accessLevel
    );

    this.getConfig();
    this.airlineLogo_func();

    localStorage.removeItem("rounttrip");
    localStorage.removeItem("onewaytrip");

    // console.log(this.bookingRadio,'nandupillai',this.bookingRadio,'nandupillai')
    // this.autocompletJson();

    // this.bookingRadio = getqueryroute.city;
    // this.bookingRadio = getqueryroute.city;

    console.log(this.bookingRadio, "reeddd....000");

    if (
      this.$route.query.city &&
      (this.bookingRadio == "roundone" || this.bookingRadio == "onewaytwo")
    ) {
      this.roundData();
      this.mydata();

      if (this.bookingRadio == "roundone") {
        this.sended1();
        console.log(this.bookingRadio, "reeddd....111");
      }
      if (this.bookingRadio == "onewaytwo") {
        this.sendedone1();
        console.log(this.bookingRadio, "reeddd....222");
        // this.calculateDates();
      }
    } else if (
      this.bookingRadio == "multithree" &&
      this.$route.query.muti_city
    ) {
      this.roundModify = false;
      this.onewayModify = false;
      this.multiModify = true;
      this.bookingRadio = "multithree";
      console.log(this.bookingRadio, "reeddd...333");
      // this.paxhide = false
      localStorage.removeItem("rountData");
      this.multi_city = JSON.parse(this.$route.query.muti_city);
      this.calculateDatesmulti();
      // this.multi_city = this.$route.query.muti_city

      this.classType = this.multi_city.class;

      console.log(this.multi_city, "thisshowMultidatauerthisshowMultidatay");

      // this.showMulti_data = this.multi_city
      // this.formMultiData = JSON.parse(this.$route.query.muti_city);
      this.multicityresult();

      // this.filterShowing();

      console.log(this.multi_city, "this.$route.querythis.$route.query....1");
      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
      }

      if (this.multi_city.itinery.length == 4) {
        console.log(
          this.multi_city,
          this.multi_city.itinery.length,
          "lengthlen.....gthlength"
        );
        this.addmore = false;
      }
    } else {
      this.searchloader = false;
      console.log(this.$route.query.agentid, "this.$route.query.agentid");
      this.searchresult = false;
      this.bookingRadio = "roundone";
      this.activeTrip(this.bookingRadio);
      this.rount = true;
      console.log(
        this.bookingRadio,
        "reeddd....444",
        this.rount,
        this.Oneway,
        this.multicity
      );
    }

    let recentSearchData = JSON.parse(
      localStorage.getItem("recentSearchData") || "[]"
    );
    let recentPrefill = JSON.parse(localStorage.getItem("recentPrefillData"));
    if (recentPrefill == true) {
      if (recentSearchData.length > 0) {
        let data = recentSearchData[0];
        // console.log(data,'recentSearchData')

        this.aduls = data.adult;
        this.aduls1 = data.child;
        this.aduls2 = data.infrant;
        this.bookingRadio = data.city;

        if (data.itinery) {
          // console.log(data,'recentSearchData..2')
          this.multi_city = {
            adult: data.adult,
            child: data.child,
            infrant: data.infrant,
            class: data.class,
            city: data.city,
            itinery: data.itinery.map((itin) => ({
              ...itin,
              departDate: itin.depdata,
              selectedFrom: true,
              selectedTo: true,
            })),
          };
          // console.log(this.multi_city,'recentSearchData..3')
        } else {
          // this.oneway.from = data.from;
          // this.inputCity = data.from;
          // this.oneway.to = data.to;
          // this.inputCityto = data.to;
          // this.oneway.dedate = data.fromDate;
          // this.oneway.redate = data.toDate ? data.toDate : null;
          // this.oneway.city = data.city;
          // this.selectedFrom = true;
          // this.selectedTo = true;
          // this.oneway.adult = data.adult;
          // this.oneway.infrant = data.inf;
          // this.oneway.child = data.child;
          // this.oneway.class = data.class;

          this.bookingRadio = data.city;
          if (this.oneway.redate === null) {
            this.Oneway = true;
            this.bookingRadio = "onewaytwo";
            // console.log('oneway')
          }

          // console.log(this.oneway,'recentSearchData1')
        }
      }
    }

    localStorage.removeItem("recentPrefillData");

    // console.log(this.bookingRadio, "this.bookingRadio this.bookingRadio this.bookingRadio this.bookingRadio ");
    // if (this.bookingRadio == 1) {
    //   this.sended1();
    // }
    // if (this.bookingRadio == 2) {
    //   this.sendedone1();
    // }
    this.generateBookingRefId();
    this.actActive();
    this.clicked1 = true;
    this.clicked2 = true;
    window.addEventListener("resize", this.handleResize);
    // window.addEventListener("resize", this.handleCalenderResize);

    // else {
    //   this.searchloader = false;
    //   console.log(this.$route.query.agentid, "this.$route.query.agentid");
    //   //   this.searchresult = false;
    //   // this.bookingRadio = "1";
    //   console.log("ppp");
    // }

    // if (this.$route.query.city) {
    //   // this.envPortal()
    //   console.log(this.domainCurrency, "this.domainCurrency4444");
    //   console.log("agent nkeeeee;;;");
    //   //  this.agentId=this.$route.query.agentid
    //   console.log(this.$route.query.agentid, "this.$route.query.agentid");
    //   this.getConfig();
    //   this.airlineLogo_func();
    //   localStorage.removeItem("rounttrip");
    //   localStorage.removeItem("onewaytrip");

    //   this.bookingRadio = this.$route.query.city;
    //   this.bookingRadio = this.$route.query.city;

    //   // console.log(
    //   //   this.$route.query.city,
    //   //   "this.$route.query.citythis.$route.query.city"
    //   // );
    //        if(this.bookingRadio ==3){
    //          this.roundModify = false;
    //     this.onewayModify = false;
    //     this.multiModify = true;
    //     // this.bookingRadio = 3;
    //     // console.log(this.bookingRadio, "reeddd111....");
    //     // localStorage.removeItem("rountData");
    //     // this.multi_city = JSON.parse(this.$route.query.muti_city);
    //     this.multicityresult();
    //   }

    //   if (this.bookingRadio || this.bookingRadio) {
    //     this.roundData();
    //     this.mydata();
    //     // this.filterShowing();
    //   } else {
    //     this.roundModify = false;
    //     this.onewayModify = false;
    //     this.multiModify = true;
    //     this.bookingRadio = 3;
    //     console.log(this.bookingRadio, "reeddd111....");
    //     localStorage.removeItem("rountData");
    //     this.multi_city = JSON.parse(this.$route.query.muti_city);
    //     this.multicityresult();

    //     console.log(this.multi_city, "this.$route.querythis.$route.query....1");
    //     if (this.multi_city.itinery.length > 2) {
    //       this.clearall = true;
    //     }

    //     if (this.multi_city.itinery.length == 4) {
    //       console.log(
    //         this.multi_city,
    //         this.multi_city.itinery.length,
    //         "lengthlen.....gthlength"
    //       );
    //       this.addmore = false;
    //     }
    //   }

    //   if (this.bookingRadio == 1) {
    //     this.sended1();
    //   }
    //   if (this.bookingRadio == 2) {
    //     this.sendedone1();
    //   }

    //   this.generateBookingRefId();
    //   this.actActive();
    //   this.airlogodata = airLogo;
    //   this.deatailData();
    //   this.clicked1 = true;
    //   this.clicked2 = true;
    //   window.addEventListener("resize", this.handleResize);
    //   window.addEventListener("resize", this.handleCalenderResize);
    // } else {
    //   this.searchresult = false;
    //   this.searchloader = false;
    //   console.log(this.$route.query.agentid, "this.$route.query.agentid");

    //   this.bookingRadio = "1";
    //   console.log("ppp");
    // }
  },

  mounted() {
    // this.autocompletJson();
    // this.fetchItems();
    this.handleResize();
    this.recentFromData();
    document.body.addEventListener("click", this.handleOutsideClick);
    // this.handleCalenderResize();
  },
};
</script>

<style scoped>
/* @media (min-width: 1200px) {
    .v-container {
        max-width: 1300px;
    }
} */

/* Filter By Section*/

>>> .filter_icons .v-selection-control--density-default {
  --v-selection-control-size: 30px !important;
}

>>> .filter_icons .v-selection-control .v-label {
  font-size: 14px;
  font-weight: 500 !important;
}

>>> .filter_icons .v-selection-control__input .v-icon {
  font-size: 20px !important;
}

>>> .filter_icons .price_slider .v-slider-track {
  --v-slider-track-size: 3px !important;
}

>>> .filter_icons .price_slider .v-slider-thumb {
  --v-slider-thumb-size: 16px !important;
}

.disable-return {
  pointer-events: none;
  opacity: 0.3;
}

.comDetailSection .priceSection .table tr th,
td {
  padding: 2px !important;
}

>>> .comDetailSection .v-radio-group .v-selection-control__wrapper {
  width: 10px !important;
  margin-right: 6px !important;
}

>>> .comPart .v-text-field .v-field__input {
  min-height: 32px !important;
  padding: 0 12px !important;
  border: 1px solid #dee2e6;
  border-radius: 0 7px 7px 0;
}

>>> .comPart .v-input__details {
  display: block !important;
  padding: 4 !important;
}
>>> .comPart .v-messages__message {
  font-size: 10px !important;
  font-weight: 600 !important;
}

>>> .fareTypeSection .v-checkbox .v-checkbox-btn {
  width: 35px;
  min-height: 0;
}

>>> .fareTypeSection .v-selection-control__wrapper {
  height: 20px;
}

>>> .fareTypeSection .v-selection-control__wrapper i {
  font-size: 18px;
}

/* Filter By Section End*/

.bg-image {
  height: auto;
  background-size: 100% 100%;
  background-position: left;
}

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100%;
}

>>> .dp__input_icon {
  cursor: pointer;
  position: absolute;
  top: 64%;
  left: 82%;
  color: black !important;
  transform: translateY(-50%);
  color: var(--dp-icon-color);
}

.searchrecent {
  min-height: auto;
  max-height: 366px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 0;
}

.mdi-menu-down::before {
  content: "\F035D222";
  display: none !important;
}

.v-input__append > .v-icon {
  position: relative;
  right: 43px;
  top: -4px;
}

.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
  top: -10px;
}

.labeling {
  position: relative;
  bottom: 9px;
}

.hisOptions {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #faa41f;
  border-bottom-left-radius: 14px;
  cursor: pointer;
  padding: 4px 6px;
}

/* 
.v-input--density-default {
  --v-input-control-height: 38px;
  position: relative;
  top: -22px;
} */

.v-slider.v-input--horizontal {
  position: relative;
  top: 0px;
}

.cart-adult {
  padding: 15px;
  width: 500px;
  cursor: pointer;
  position: relative;
  top: -65px;
}

>>> .dp__clear_icon {
  display: black;
  position: absolute;
  top: 50%;
  /* right: 0; */
  transform: translateY(-50%);
  cursor: pointer;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.price-head .price-currency {
  font-size: 18px;
  font-weight: 600;
  color: black;
  padding-top: 17px;
  padding-right: 6px;
  /* font-size: 25px;
  font-weight: 600;
  color: black;
  padding-top: 6px;
  padding-right: 6px; */
}

.price-head .price-Amount {
  font-size: 36px;
  font-weight: 600;
  color: black;
}

.radio {
  /* background-color: #FFB80A; */
  border-radius: 5px;
  color: black !important;
  width: 120px;
  font-size: 15px !important;
  font-weight: 400px;
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400;
  height: 30px;
  width: auto;
  /* width: 150px; */
}

>>> .normal-radio .v-label {
  font-size: 14px;
  font-weight: 500 !important;
  opacity: 1 !important;
}

>>> .normal-radio .v-selection-control__wrapper {
  width: 26px !important;
}

>>> .normal-radio .v-selection-control__wrapper i {
  font-size: 18px !important;
}

.air-title {
  background-color: #ff5400;
  color: white;
  padding: 5px;
}

.cart1 {
  box-shadow: none;
  border: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  max-width: 208px;
  border-radius: 5px;
}

.cart1 p {
  text-align: center;
}

hr {
  margin: 0rem 0 !important;
}

.f-size-20 {
  font-size: 20px;
}
.f-size-18 {
  font-size: 18px;
}
.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}

.rec-num {
  font-size: 14px;
  font-weight: 600;
  color: navy;
}

.bord {
  border-right: 2px solid gray;
  position: relative;
  top: -2px;
  height: 63px;
}

.depar {
  background-color: lightgray;
  padding: 3px;
}

.res-name {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
  padding: 0px 0px 0px 12px;
}

.res-stop {
  font-size: 12px;
  font-weight: 600;
  color: grey;
}

/* .res-stop {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
  right: 12px;
  color: grey;
} */

.res-time {
  font-size: 11px;
  font-weight: 500;
}

.dot-1 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 42%;
  left: 30%;
  z-index: 1;
}

.dot-2 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 18%;
  left: 50%;
  z-index: 1;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 14px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

>>> .v-input__details {
  display: none;
}

.v-input--density-default {
  --v-input-control-height: 38px;
}

.stop {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
}

.v-slider.v-input--horizontal .v-slider-thumb {
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 3px solid #3c3cde;
  border-radius: 50px;
}

.v-slider.v-input--horizontal .v-slider-track__fill {
  height: 3px;
  background-color: #7474e0;
}

/*  */
.v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}

.v-checkbox .v-selection-control {
  min-height: var(--v-input-control-height);
  position: relative;
  top: 12px;
}

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.mdi-airplane-takeoff {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

.mdi-airplane-landing {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

.p-error {
  font-size: 12px;
  color: red;
  position: absolute;
}

.dropdown-box {
  padding: 8px 10px;
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 14px;
  color: #242424;
  font-weight: 500;
}

.p-float-label label {
  position: absolute;
  pointer-events: none;
  top: 12px !important;
  left: 0px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  /* font-size: 16px;
  color: #242424;
  font-weight:600; */
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 4px;
  padding-bottom: 4px; */
}

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

.dp__icon {
  display: block;
}

.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  justify-content: flex-start;
}

>>> .dp__input {
  font-size: 14px;
  padding: 0;
  font-weight: 500;
  border: none;
  border-radius: 0px !important;
  height: 18px;
  font-family: "Poppins", sans-serif !important;
  /* padding-left: 2px;
  padding-right: 2px; */
  /* border-bottom: 1px solid lightgrey !important; */
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  position: relative;
}

.label-top {
  position: absolute;
  top: -10px !important;
  left: -48px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 500;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label {
  position: absolute;
  top: 24px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 500;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.label-top1 {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 24px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-weight: 500;
  color: #242424;
  font-size: 14px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  left: -8px !important;
  bottom: 0px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: #242424;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute !important;
  top: 28px !important;
  left: -9px !important;
  transform: translateY(-100%);
  background-color: transparent !important;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.hoverData1 {
  position: absolute;
  left: 50%;
  top: -33px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop1 {
  position: absolute;
  left: 38.4%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.hoverDataStop2 {
  position: absolute;
  left: 62%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* cursor: pointer; */
}

/* .content:hover{
  width:100%;
  font-size:13px;
} */
.hoverData2 {
  position: absolute;
  left: 63%;
  bottom: 63px;
  border-radius: 5px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverData1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverData2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.refunt {
  color: blue;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 1px;
}

>>> .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.filter-toggle {
  display: none;
}

.v-card {
  border-radius: 10px !important;
  z-index: revert-layer;
  overflow: unset;
}

.hoveredCard:hover {
  cursor: pointer;
  /* box-shadow: 0px 0px 4px 0px black; */
  /* box-shadow: none; */
  /* border: 2px solid lightgray; */
  /* border-radius: 8px !important; */
}

.card-in {
  box-shadow: none;
}

/* .card-in:hover {
  box-shadow: 0px 0px 1px 0px black;
} */

.card-in {
  /* box-shadow: 0px 0px 1px 0px black; */
}

.time-section .v-icon {
  font-size: 12px !important;
  color: grey;
}

.time-section span {
  font-size: 9px;
  margin-left: 5px;
  font-weight: 500;
  color: grey;
}

.clear_style {
  border: 1px solid #ffa0a0;
  border-radius: 5px;
  padding: 4px 6px;
  height: 30px;
  cursor: pointer;
}

.clear_style:hover {
  background-color: #ffe9e9;
}

.add_style {
  border: 1px solid #a0ffa0;
  border-radius: 5px;
  padding: 4px;
  height: 30px;
  cursor: pointer;
}

.add_style:hover {
  background-color: #e9ffe9;
}

@media only screen and (max-width: 991px) {
  .filter-toggle {
    display: block;
  }

  .magnify-btn .v-btn {
    width: 140px !important;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
    padding: 0px 0px 0px 12px;
  }

  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */

  .time-section .v-icon {
    font-size: 12px !important;
    color: grey;
  }

  .time-section span {
    font-size: 9px !important;
    font-weight: 500 !important;
    color: grey;
  }

  .airline-logo {
    padding: 5px;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}

@media only screen and (min-width: 479px) {
  .aircode-hide {
    display: block;
  }

  .aircode-show {
    display: none;
  }

  .airlineLogo-hide {
    display: flex !important;
  }

  .airlineLogo-show {
    display: none !important;
  }

  .select-section-hide {
    display: flex !important;
  }

  .select-section-hide1 {
    display: flex !important;
  }

  .date-section-hide {
    display: block;
  }

  .date-section-show {
    display: none;
  }

  .fare-detail-mobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .hoverDataStop1 {
    left: 38%;
  }

  .hoverDataStop2 {
    left: 59%;
  }

  .airlineLogo-hide {
    display: none !important;
  }

  .airlineLogo-show {
    display: block !important;
  }

  .pax-fonts span {
    font-size: 10px;
    color: #242424;
    font-weight: 500;
  }

  .aircode-hide {
    display: none;
  }

  .aircode-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-section-hide {
    display: none !important;
  }

  .select-section-hide1 {
    display: none !important;
  }

  .res-name {
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }

  .airlineLogo-show .mbl-price-section {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .mbl-price-section .price-currency {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: black;
    padding-top: 10px;
    padding-right: 4px;
  }

  .airlineLogo-show .mbl-price-section .price-Amount {
    margin-bottom: 0px !important;
    font-size: 26px !important;
  }

  .airlineLogo-show .mbl-price-section .content {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .v-btn {
    height: 30px !important;
  }

  >>> .airlineLogo-show .v-btn .v-btn__content {
    font-size: 15px !important;
  }

  .date-section-hide {
    display: none;
  }

  .date-section-show {
    display: block;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }

  .fare-detail-mobile {
    display: block;
  }

  .fare-detail-mobile .v-card-text {
    color: rgb(51, 102, 204);
    font-weight: 500;
    padding-left: 0 !important;
    text-align: left;
    width: auto !important;
  }

  .fare-detail-mobile .v-icon {
    font-size: 24px !important;
    height: 18px !important;
    width: 24px !important;
    position: relative !important;
    right: 60px !important;
    top: 2px !important;
  }
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
}

.input-styles-1 {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
  width: 100%;
}

>>> .input-styles .v-select__selection {
  margin: 0;
}

>>> .selectType .mdi-menu-down {
  font-size: 20px;
}

>>> .selectType .v-select__selection-text {
}

.form-card {
  width: 90%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none !important;
}

.back-bg {
  margin: 20px;
  width: 500px;
  height: 500px;
  border-radius: 30px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

.round-mode,
.oneway-mode {
  padding: 10px 10px;
  overflow: unset;
  box-shadow: none;
  border: 2px solid lightgray;
  border-radius: 5px !important;
}

/* Traveller Pax Dropdown section */

>>> .input-styles .select-1 .v-field__input {
  padding-top: 0;
  min-height: 0;
}

>>> .input-styles .select-1 .v-field__append-inner {
  padding-top: 0;
}

>>> .select-2 .v-field__input {
  padding: 0px;
  min-height: 16px;
}

>>> .select-2 .v-select__selection {
  margin: 0;
}

>>> .select-2 .v-select__selection-text {
  font-size: 14px;
  font-weight: 500;
}

>>> .select-2 .v-field__input input {
  margin: 0;
}

>>> .select-2 .v-field__append-inner {
  padding: 0;
}

>>> .select-2 .v-field__append-inner i {
  height: 20px;
}

/* Traveller Pax Dropdown section End */

.p-float-label .p-inputwrapper-focus ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 600;
}

.p-float-label .p-inputwrapper-filled ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 500;
}

.p-inputtext {
  font-size: 16px !important;
  font-weight: 600px !important;
}

.pax-fonts span {
  font-size: 14px;
  color: #242424;
  font-weight: 500;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

>>> .dp__month_year_select {
  pointer-events: none;
}

>>> .dp__today {
  border: 1px solid #faa41f !important;
}

>>> .dp__date_hover:hover {
  background: #faa41f;
  color: white;
}

/* >>>.dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
  top: -10px !important;
  left: -50px !important;
  transform: none !important;
} */

#navbar {
  display: block;
}

#food {
  display: block;
}

.recment {
  border: 1px solid #3f3f3f;
  background-color: #e3e3e3;
}

.normal-rec {
  border: 1px solid rgb(163, 161, 161) !important;
}

.top_filterprice {
  font-size: 18px;
  font-weight: 500;
}

.top_filterTitle {
  font-size: 14px;
}

.mutilti_data {
  padding: 2px 4px;
  max-height: 250px;
  /* overflow: scroll;
  overflow-x: hidden; */
  border: 1px solid lightgray;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.modal-content {
  background-color: white;
  padding: 1rem 1.5rem;
  width: 45%;
  margin: auto;
  /* top: 8%; */
  border-radius: 0.5rem;
  /* position: absolute; */
}
.modal-body {
  height: 296px;
}
.close-button {
  position: absolute;
  top: 0.5rem;
  right: 2.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: gray;
}

.close-button:hover {
  color: darkgray;
}

.trigger {
  /* padding: 0.5rem 1rem; */
  /* background-color: #007bff; */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}
>>> .v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 58px;
}
>>> .v-tabs--fixed-tabs .v-slide-group__content > *:last-child,
.v-tabs--align-tabs-center .v-slide-group__content > *:last-child {
  margin-inline-end: inherit;
}
>>> .v-tabs--align-tabs-center .v-slide-group__content > *:first-child {
  margin-inline-start: 0px;
}
>>> .v-progress-circular {
  width: 24px;
}
>>> .cancellationRules .FareRule h2 {
  font-size: 1rem !important;
  font-weight: 600;
}
.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  /* max-width: 100%; */
  padding: 6px;
  border-radius: 2px;
  margin-top: 14px;
  /* margin-left: -44px; */
  z-index: 99999;
  font-size: 14px;
  font-weight: 500;
  left: 16px;
}
.inter-input {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #6a2e4d; */
  /* color: #26225e; */
  /* padding: 20px 20px; */
  /* height: 25px; */
}
.autocompete-menual-dropdown1 {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  /* max-width: 100%; */
  padding: 6px;
  border-radius: 2px;
  margin-top: 14px;
  /* margin-left: -44px; */
  z-index: 99999;
  font-size: 14px;
  font-weight: 500;
  left: 24%;
}
.vas-autocomplete input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.inter-input::placeholder {
  color: black;
  font-weight: 500;
}
>>> .datepicker input::placeholder {
  color: black !important;
  font-weight: 500 !important;
}

.custom-font {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.dropdown-menu {
  width: 300px;
}
.nor-date {
  font-size: 13px;
  border: 3px solid gold !important;
  text-align: center;
  padding: 5px 0px;
  cursor: pointer;
  height: 50px;
}
.nor-date1 {
  border: 3px solid gold !important;
}
.nor-date2 {
  border: 3px solid green !important;
}
.spl-date {
  font-size: 13px;
  border: 1px solid lightblue;
  text-align: center;
  padding: 5px 0px;
  cursor: pointer;
  height: 50px;
}
.btn-text {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.op-font {
  font-size: 13px;
  margin-left: 4px;
}

/* .advanceOpt .dropdownSection .dropdown .advanceBtn:hover, .advanceOpt .dropdownSection .dropdown .activeBtn:hover{
  background: lightgray !important;
  color: black;
} */

.advanceOpt
  .dropdownSection
  .dropdown
  :is(.advanceBtn:hover, .activeBtn:hover) {
  background: lightgray !important;
  color: black;
}

.advanceOpt .dropdownSection .dropdown .advanceBtn {
  background: none;
  color: black;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  border-radius: 0;
  padding-bottom: 4px !important;
}

.activeBtn {
  border-bottom: 2px solid #000000 !important;
  background: lightgray;
  border: none;
  border-radius: 0;
  color: black;
  padding-bottom: 4px !important;
}

.advanceOpt .dropdownSection .dropdown .donebtn {
  width: 75px !important;
  padding: 0 !important;
  background: #faa41f !important;
  border-color: #faa41f !important;
}

.disabled-icon {
  opacity: 0.5;
  pointer-events: none;
}

.disabled-label {
  color: #b1b1b1;
  pointer-events: none;
}
.flight-table {
  display: flex;
  justify-content: center;
}

.calenderSection table {
  width: 100%;
  table-layout: fixed;
}

.calenderSection .cal-se1 .priceFont {
  font-size: 10px !important;
  font-weight: 500 !important;
}
.calenderSection .cal-se1 .airIconimg {
  width: 10px !important;
}

th,
td {
  padding: 10px 4px;
  text-align: center;
  border: 1px solid lightblue;
}
.calenter-head {
  color: #551a8b;
  text-decoration: underline;
  text-decoration-color: #551a8b;
  font-size: 14px;
  font-weight: 500;
}
.disabled-cal {
  pointer-events: none;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}
.cal-se1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  cursor: pointer;
}
.cal-se2 {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.cal-h3 {
  display: flex;
  justify-content: center;
}
.calSelect1 {
  box-shadow: none;
  border: 1px solid lightblue !important;
  text-align: center;
  width: 150px;
  font-size: 14px;
}
.calSelect {
  box-shadow: none;
  border: 3px solid gold !important;
  background: #dae7f7;
  text-align: center;
  width: 150px;
  font-size: 14px;
}
.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
</style>
