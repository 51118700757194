<template>
  <v-container class="d-flex align-center justify-center" style="height: 100vh">
    <div class="p-5 Error-section">
      <div>
        <div class="mb-5">
          <v-img class="m-auto" :src="portalLogo" width="250px"></v-img>
          <!-- <v-img class="m-auto" :src="require('@/assets/sadasdsad.png')" width="250px"></v-img> -->
        </div>

        <div class="row d-flex justify-center mb-3" v-if="!recentData.itinery">
          <div class="col-3">
            <div class="re-fon">
              {{ recentData.from }}
            </div>
            <div class="re-fon">
              {{ getdated(recentData.fromDate) }}
            </div>
          </div>
          <div class="col-1 d-flex justify-center align-items-center">
            <div v-if="recentData.city == 'roundone'">
              <v-icon size="30">mdi-swap-horizontal</v-icon>
            </div>
            <div v-if="recentData.city == 'onewaytwo'">
              <v-icon>mdi-arrow-right</v-icon>
            </div>
          </div>
          <div class="col-3">
            <div class="re-fon">
              {{ recentData.to }}
            </div>
            <div class="re-fon" v-if="recentData.toDate">
              {{ getdated(recentData.toDate) }}
            </div>
          </div>
        </div>
        <div v-if="recentData.itinery" class="mb-2">
          <div
            class="row d-flex justify-center mb-1"
            v-for="(item, index) of recentData.itinery"
            :key="index"
          >
            <div class="col-3">
              <div class="re-fon">
                {{ item.from }}
              </div>
            </div>
            <div class="col-2 d-flex flex-column">
              <div class="d-flex justify-center">
                <v-icon>mdi-arrow-right</v-icon>
              </div>
              <div class="re-fon">
                {{ item.depdata }}
              </div>
            </div>
            <div class="col-3">
              <div class="re-fon">
                {{ item.to }}
              </div>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <p class="error-content-1">
            No flights offered by airlines included on our website matches you
            search criteria
          </p>
          <p class="error-content-2">
            Please check flights from other airports or change your search
            request
          </p>
        </div>

        <div class="d-flex justify-center">
          <v-btn
            @click="changeSearch()"
            color="#faa41f"
            style="text-transform: none"
            >Change your search</v-btn
          >
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import moment from "moment";

export default {
  data() {
    return {
      portalLogo: "",
      recentData: [],
    };
  },
  methods: {
    getdated($event) {
      return moment($event).format(" DD-MMM-YYYY");
    },
    changeSearch() {
      let recentSearch = localStorage.getItem("recentSearchData");
      let portalUrl = JSON.parse(localStorage.getItem("portalUrl"));

      if (recentSearch) {
        recentSearch = JSON.parse(recentSearch);
        localStorage.setItem("recentPrefillData", JSON.stringify(true));
      }

      this.$router.push(`${portalUrl}`);
    },
    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.portalLogo = getConfigData1.payload.portal_configuration.logo_path;
      }
    },
  },
  created() {
    this.getConfig();

    let portalData = JSON.parse(
      localStorage.getItem("recentSearchData") || "[]"
    );
    if (portalData.length > 0) {
      this.recentData = portalData[0];
      console.log(this.recentData, "recentData");
    }
    // this.$route.query
    // console.log(this.$route.query,'this.$route.querythis.$route.query')
  },
};
</script>

<style scoped>
.Error-section {
  background-color: #ffffffd9;
  border-radius: 20px;
}

.error-content-1 {
  font-size: 24px;
  font-family: "Roboto";
  text-align: center;
  padding: 0px 20px;
}

.error-content-2 {
  font-size: 20px;
  font-family: "Roboto";
  text-align: center;
  padding: 0px 20px;
}
.re-fon {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: "Roboto";
}
</style>
