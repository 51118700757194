<template>
  <div>
    <canvas ref="myCanvas" width="200" height="100"></canvas>
    <button @click="loadImage">Load Image</button>
  </div>

</template>


  <!-- <script type="text/javascript">
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/661e1b5f65e07e/1hnlmkj';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    })();
  </script> -->

<script>
export default {
  data() {
    return {
      imageSrc: 'path/to/your/image.jpg',
    };
  },
  mounted() {
    this.generateRandomAlphabets();
  },
  methods: {
    generateRandomAlphabets() {
      const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * alphabets.length);
        result += alphabets[randomIndex];
      }

      this.randomAlphabets = result;
      this.drawTextOnCanvas();
    },
    drawTextOnCanvas() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing

      ctx.font = "30px Arial";
      ctx.fillText(this.randomAlphabets, 10, 50);
    },
    loadImage() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = this.imageSrc;

      image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    },
  },
};
</script>

<style>
/* Add your styles here if needed */
</style>
