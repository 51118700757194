import { createRouter, createWebHistory } from "vue-router";
import pagenot from "../components/pageNot.vue";
import flightInfo from "../components/flightInfo.vue";
import confirmBook from "../components/confirmBook.vue";
import search from "../components/SearchResult.vue";
import test from "@/components/testCom.vue";
import pageError from "@/components/pageError.vue";
import dashBoard from "@/components/dashBoard.vue";
import myBooking from "@/components/myBooking.vue";
import dataloader from "@/components/dataLoder.vue";
import bookingView from "@/components/bookingView.vue";
import pageloader from "@/components/pageLoader.vue";

const routes = [
  {
    path: "/",
    component: search,
  },
  {
    path: "/pageloader",
    component: pageloader,
  },
  {
    path: "/pageNot",
    component: pagenot,
  },
  {
    path: "/dataloader",
    component: dataloader,
  },
  {
    path: "/search",
    component: search,
  },
  {
    path: "/flightInfo",
    component: flightInfo,
  },
  {
    path: "/confirmBook",
    component: confirmBook,
  },
  {
    path: "/test",
    component: test,
  },
  {
    path: "/pageError",
    component: pageError,
  },
  {
    path: "/dashboard",
    component: dashBoard,
  },
  {
    path: "/mybooking",
    component: myBooking,
  },
  {
    path: "/bookingView",
    component: bookingView,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: pagenot },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
